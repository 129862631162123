import { countries } from "country-data";
import React, { Component } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import * as actions from "../../actions";
import DeleteModal from "../../components/DeleteModal";
import Loader from "../../components/Loader";
import EditSite from "./EditSite";
import SwitchButton from "../../components/form/SwitchButton";

const ITEM = "Site";
const DELETE_API = "site/deactivate";
const MODULE_BASE_ROUTE = "site";

class SiteDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      site: this.props.siteData,
      isEditModal: false,
    };
  }
  rerouteToList = () => {
    window.location.reload();
  };

  handleConfirmDelete = (e, id) => {
    e.preventDefault();
    this.setState((prevState, props) => ({
      deleteId: id,
      confirmDelete: true,
      isRowMenu: false,
    }));
  };

  toggleState = (key) => {
    this.setState((prevState, props) => ({
      [key]: !prevState[key],
    }));
  };

  getDetails = () => {
    const { id } = this.state;
    this.setState(() => ({
      loading: true,
    }));

    this.props.get(
      {
        url: `site/${this.state.site.id}`,
        errorUrl: `/${MODULE_BASE_ROUTE}`,
        history: this.props.history,
      },
      async (response) => {
        console.log(response, "======>response");
        this.setState(() => ({
          site: response,
          responseFetched: true,
          loading: false,
        }));
      }
    );
  };

  componentDidMount() {
    this.getDetails();
  }

  reloaddetails = () => {
    this.toggleState("isEditModal");
    this.getDetails();
  };

  render() {
    const { site } = this.state;
    return (
      <div>
        <div class="RowDesign">
          <div class="col-lg-12 float-left mb-3 text-left">
            <h3 class="mb-0 HeadingData">Sites Details</h3>
          </div>
          <div class="col-lg-12  float-left">
            <ul class="ClientDetails">
              <li>
                <strong>Joik Site ID</strong>
                {site.id}
              </li>
              <li>
                <strong>Site Number</strong>
                {site.siteId}
              </li>
              <li>
                <strong>Site Status</strong>
                {site.siteStatus}
              </li>
              <li>
                <strong>Site Status Effective Date</strong>
                {site.siteStatusEffectiveDate}
              </li>
              <li>
              <strong>Joik Card Allowed</strong>
                <SwitchButton
                  checked={site.joikCardAllowed}
                  isDisabled={true}
                />
              </li>
            </ul>
          </div>

          <div class="col-lg-12 float-left mb-3 text-left mt-1">
            <h3 class="mb-0 HeadingData">Account Details</h3>
          </div>
          <div class="col-lg-12  float-left">
            <ul class="ClientDetails">
              <li>
                <strong>Account Name</strong>
                {site.account.name}
              </li>
              <li>
                <strong>Address Line 1</strong>
                {site.account.address.addressLine1}
              </li>
              <li>
                <strong>Address Line 2</strong>
                {site.account.address.addressLine1}
              </li>
              <li>
                <strong>City</strong>
                {site.account.address.city}
              </li>
              <li>
                <strong>State</strong>
                {site.account.address.state}
              </li>
              <li>
                <strong>Country</strong>
                {countries[site.account.address.countryCode].name}
              </li>
              <li>
                <strong>Postcode</strong>
                {site.account.address.postcode}
              </li>
              <li>
                <strong>Email ID</strong>
                {site.account.contactInfo.email}
              </li>
              <li>
                <strong>Phone</strong>
                {site.account.contactInfo.landlineNumber}
              </li>
            </ul>
          </div>
          {site.principalInvestigator ? (
            <div class="col-lg-12 float-left mb-3 text-left mt-1">
              <h3 class="mb-0 HeadingData">Principal Investigator Details</h3>
            </div>
          ) : (
            ""
          )}
          {site.principalInvestigator ? (
            <div class="col-lg-12  float-left">
              <ul class="ClientDetails">
                <li>
                  <strong>First Name</strong>
                  {site.principalInvestigator.firstName}
                </li>
                <li>
                  <strong>Last Name</strong>
                  {site.principalInvestigator.lastName}
                </li>
                <li>
                  <strong>Address Line 1</strong>
                  {site.principalInvestigator.address.addressLine1}
                </li>
                <li>
                  <strong>Address Line 2</strong>
                  {site.principalInvestigator.address.addressLine2}
                </li>
                <li>
                  <strong>City</strong>
                  {site.principalInvestigator.address.city}
                </li>
                <li>
                  <strong>State</strong>
                  {site.principalInvestigator.address.state}
                </li>
                <li>
                  <strong>Country</strong>
                  {
                    countries[site.principalInvestigator.address.countryCode]
                      .name
                  }
                </li>
                <li>
                  <strong>Post Code</strong>
                  {site.principalInvestigator.address.postcode}
                </li>
                <li>
                  <strong>Email ID</strong>
                  {site.principalInvestigator.contactInfo.email}
                </li>
                <li>
                  <strong>Phone</strong>
                  {site.principalInvestigator.contactInfo.landlineNumber}
                </li>
                <li>
                  <strong>Account Name</strong>
                  {site.principalInvestigator.account.name}
                </li>
              </ul>
            </div>
          ) : (
            ""
          )}

          <div class="col-lg-12 float-left mb-3 text-left mt-1">
            <h3 class="mb-0 HeadingData">Shipping Details</h3>
          </div>
          <div class="col-lg-12  float-left">
            <ul class="ClientDetails">
              <li>
                <strong>First Name</strong>
                {site.shippingContact.firstName}
              </li>
              <li>
                <strong>Last Name</strong>
                {site.shippingContact.lastName}
              </li>
              <li>
                <strong>Address Line 1</strong>
                {site.shippingContact.address.addressLine1}
              </li>
              <li>
                <strong>Address Line 2</strong>
                {site.shippingContact.address.addressLine2}
              </li>
              <li>
                <strong>City</strong>
                {site.shippingContact.address.city}
              </li>
              <li>
                <strong>State</strong>
                {site.shippingContact.address.state}
              </li>
              <li>
                <strong>Country</strong>
                {countries[site.shippingContact.address.countryCode].name}
              </li>
              <li>
                <strong>Post Code</strong>
                {site.shippingContact.address.postcode}
              </li>
              <li>
                <strong>Email ID</strong>
                {site.shippingContact.contactInfo.email}
              </li>
              <li>
                <strong>Phone</strong>
                {site.shippingContact.contactInfo.landlineNumber}
              </li>
              <li>
                <strong>Account Name</strong>
                {site.shippingContact.account.name}
              </li>
            </ul>
          </div>
          <div class="col-lg-12  float-left mt-3 mb-3 text-center AllFooterButton d-flex justify-content-center">
            <a onClick={this.props.hideDetails} class="DefaultButton">
              Back
            </a>
            <button
              onClick={() => this.toggleState("isEditModal")}
              className="DefaultButton AquaButton"
            >
              Edit
            </button>
            <a
              onClick={(e) => this.handleConfirmDelete(e, site.id)}
              class="DefaultButton"
            >
              Delete
            </a>
          </div>
        </div>

        <DeleteModal
          item={ITEM}
          modalIsOpen={this.state.confirmDelete}
          setParentState={(newState) => this.setState(newState)}
          deleteId={this.state.deleteId}
          rerouteToList={this.rerouteToList}
          api={this.props.deleteMethod}
          apiName={DELETE_API}
        />
        <EditSite
          item={ITEM}
          detail={site}
          setParentState={(newState) => this.setState(newState)}
          modalIsOpen={this.state.isEditModal}
          reloaddetails={this.reloaddetails}
          toggleModal={() => this.toggleState("isEditModal")}
        ></EditSite>

        <Loader loading={this.state.loading} />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({});

export default compose(connect(mapStateToProps, actions))(SiteDetails);
