import React, { Component} from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import * as actions from '../../actions';

class StudyNavBar extends Component {

	constructor(props) {
		super(props);
		this.state = {            
			GeneralInformationClass:"nav-link active",
            siteClass:"nav-link",
			siteContactClass:"nav-link",
			sponsorContactClass:"nav-link",
			milestoneClass:"nav-link",
			milestonePaymentClass:"nav-link",
			SubjectClass:"nav-link",			
          };	
	} 

	selectedItem=(opentab,selectMenu)=>{
		this.resetAllClass()
		this.setState((prevState, props) => ({
			[selectMenu]: "nav-link active",
		  }));
		  
		 this.props.selectTab(opentab)
		
	}	
	
	resetAllClass=()=>{
		this.setState({            
			GeneralInformationClass:"nav-link",
            siteClass:"nav-link",
			siteContactClass:"nav-link",
			sponsorContactClass:"nav-link",
			milestoneClass:"nav-link",
			milestonePaymentClass:"nav-link",
			SubjectClass:"nav-link",			
		})

	}



	
	render() { 
		return ( 

<div className="col-lg-12">
		<ul className="nav nav-tabs StudyTabsLinks" role="tablist">
			<li className="nav-item">
				<a onClick={()=>this.selectedItem('General Information','GeneralInformationClass',)} className={this.state.GeneralInformationClass} data-toggle="tab">General Information</a>
			</li>
			<li className="nav-item">
				<a onClick={()=>this.selectedItem('Sites','siteClass')} className={this.state.siteClass} data-toggle="tab">Sites</a>
			</li>
			<li className="nav-item">
				<a  onClick={()=>this.selectedItem('Site Contacts','siteContactClass')} className={this.state.siteContactClass} data-toggle="tab" >Site Contacts</a>
			</li>
			<li className="nav-item">
				<a onClick={()=>this.selectedItem('Sponsor Contacts', 'sponsorContactClass')} className={this.state.sponsorContactClass} data-toggle="tab" >Sponsor Contacts</a>
			</li>
			<li className="nav-item">
				<a onClick={()=>this.selectedItem('Milestones','milestoneClass')} className={this.state.milestoneClass} data-toggle="tab">Milestones</a>
			</li>
			<li className="nav-item">
				<a onClick={()=>this.selectedItem('Milestone Payments','milestonePaymentClass')} className={this.state.milestonePaymentClass} data-toggle="tab" >Milestone Payments</a>
			</li>
			<li className="nav-item">
				<a onClick={()=>this.selectedItem('Subjects','SubjectClass')} className={this.state.SubjectClass} data-toggle="tab">Subjects</a>
			</li>
		</ul>
	</div> );
	}
}



const mapStateToProps = state => ({
	// errorMessage: state.auth.errorMessage
  });
  
  export default compose(
	connect(mapStateToProps, actions)
  )(StudyNavBar);