import { createStore, applyMiddleware, compose } from 'redux';
import reduxThunk from 'redux-thunk';
import reducers from '../reducers';

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export default function store() {
    const store = createStore(
        reducers,
        {
            auth: {
                authenticated: localStorage.getItem('token'),
                errorMessage: '',
                user: typeof JSON.parse(localStorage.getItem('user')) == 'object' ? JSON.parse(localStorage.getItem('user')) : {}
            }
        },
        composeEnhancers(applyMiddleware(reduxThunk))
    );

    return store;
}