import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import * as actions from '../../actions';
import Modal from "react-bootstrap/Modal";
import Loader from '../../components/Loader';
import SearchField from "react-search-field";
import ReactPaginate from 'react-paginate';
import {countries} from 'country-data';



class SiteContactList extends Component {

    constructor(props) {
        super(props);

        this.state = {
            responseData: {},
            accounts: [],
            confirmDelete: false,
            deleteId: null,
            responseFetched: false,
            isRowMenu: false,
            isRowMenuIndex: null,
            loading: false,
            isAddModal: false,
            isEditModal: false,
            editData: null,
            totalRecords:0,
            itemPerPage:5,
            curentPage:1,
            pageCount:1,
            toggleAccountModal:false,
           };
        
      }

      componentDidMount() {
       
        this.getListing("",1,);       
      }

      handlePageClick = (val)=>{
        // console.log("page clicked",val);
         let pagNum = val.selected+1
         this.getListing("",pagNum)
       }
     


      getListing = (sarchStr,pageNum) => {
        this.setState(() => ({
          loading: true,
        }));
        
         let url = `contact/contact-type/Site Contact?pageNum=${pageNum}&pageSize=${this.state.itemPerPage}&sortField=id&sortOrder=Desc&word=`
        this.props.get({ url: `${url}${sarchStr}`}, async (response) => {
          console.log(response, '======>response');
          
          this.setState(() => ({
            responseData: response,
            accounts: response.entryList,
            responseFetched: true,
            loading: false,
            pageCount:response.totalPages,
          }));
        });
      }


      onSearchClick=(value)=>{
   
        this.getListing(value, this.state.curentPage)
     
            
       }

      render() {
        let {
          detail,accounts
        } = this.state;
        return (
          <>
           <Modal
            show={this.props.modalIsOpen}
            onHide={this.props.toggleModal}
            animation={false}
            className={"CustomModal width900"}
            >
            <div className="modal-header">
            <h4 className="modal-title">Assign Contact</h4>
            <button type="button" onClick={this.props.closeAssignModel} className="close" >&times;</button>
            </div>
            <div className="modal-body modalpadding">
					<div className="col-lg-12">
						<div className="row">

              <div className="TableData">
              <div className="TableTopText">
										<h3>Search Contact</h3>
										<div class="float-right SearchTableData form-group">
                                        <SearchField
                                        placeholder=""
                                        searchText=""
                                        onChange={this.onSearchClick}
                                        classNames="AllSearchTabledata"
                                        />
							           </div>

                         </div> 

              <table className="table datatable-basic">
                  <thead>
                    <tr>
                      <th>Joik Account ID</th>
                      <th>First Name</th>
                      <th>Last Name</th>
                      <th>Account Name</th>
                      <th>City</th>
                      <th>State</th>
                      <th>Country</th>
                       </tr>
                  </thead>
                  <tbody>
                    {
                      accounts && accounts.length === 0 && (
                        <tr>
                          <td colSpan="8" >
                            <center>{!this.state.responseFetched ? 'Loading...' : 'No data found'}</center>
                          </td>
                        </tr>
                      )
                    }

                    {/* {console.log(accounts && accounts.length > 0, '======>accounts')} */}
                    {
                      accounts && accounts.length > 0 && accounts.map((account, accountIndex) => {
                       return (
                          <tr key={`account_${accountIndex}`}>
                            <td><p className="AquaText pointer" onClick={() => this.props.selectedAccountInfo(account,this.props.type)}>{account.joikId}</p></td>
                            <td>{account.firstName}</td>
                            <td>{account.lastName}</td>
                            <td>{account.account.name}</td>

                            <td>{account.address.city}</td>
                            <td>{account.address.state}</td>
                            <td>{countries[account.address.countryCode].name}</td>                            
                          </tr>
                        );
                      })
                    }
                  </tbody>
                </table>

                <div className="col-lg-12">
                {this.state.pageCount<=1 ? null : 
            <ReactPaginate
                previousLabel={'<'}
                nextLabel={'>'}
                breakLabel={'...'}
                breakClassName={'break-me'}
                pageCount={this.state.pageCount}
                marginPagesDisplayed={5}
                pageRangeDisplayed={1}
                onPageChange={this.handlePageClick}
                containerClassName={'pagination'}
                subContainerClassName={'pages pagination'}
                activeClassName={'active'}
              />
                }
            </div>


              </div>

                        </div>    
                    </div>
            </div>    

         <Loader
             loading={this.state.loading}
            />
         </Modal>
          </>
        );
      }

}

const mapStateToProps = state => ({
    // errorMessage: state.auth.errorMessage
  });


export default compose(
    connect(mapStateToProps, actions)
  )(SiteContactList);


