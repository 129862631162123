import React, { Component } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { createBrowserHistory } from "history";
import { ToastContainer, toast } from "react-toastify";
import ScrollToTop from "../components/ScrollToTop";
import PublicRoute from "./PublicRoute";
import PrivateRoute from "./PrivateRoute";
import Dashboard from "../views/dashboard/Dashboard";
import Accounts from "../views/accounts/Accounts";
import AccountAdd from "../views/accounts/AccountAdd";
import AccountDetail from "../views/accounts/AccountDetail";
import ContactDetail from "../views/Contact/ContactDetails";
import AccountEdit from "../views/accounts/AccountEdit";
import Contacts from "../views/Contact/Contacts";
import Studies from "../views/Studies/Studies";
import StudyDetailsView from "../views/Studies/StudyDetailsView";
import JoikCardList from "../views/JoikCard/JoikCardList";
import PaymentList from "../views/Payment/PaymentList";
import TicketRoot from "../views/ticket/TicketRoot";
import details from "../views/Payment/Details";
import Login from "../views/Login/Login";
import ChangePassword from '../views/ChangePassword/ChangePassword'
import ForgotPassword from '../views/ForgotPassword/ForgotPassword'
import ConfirmForgotPassword from '../views/ConfirmForgotPassword/ConfirmForgotPassword'
import DocumentList from '../views/documents/documentList'
const history = createBrowserHistory();
class AppRouter extends Component {
  render() {
    return (
      <Router history={history}>
        <ScrollToTop />
        <ToastContainer />
        <Switch>
          <PublicRoute
            path={`${process.env.PUBLIC_URL}/`}
            exact
            component={Login}
          />

          <PrivateRoute
            path={`${process.env.PUBLIC_URL}/dashboard`}
            exact
            component={Dashboard}
          />

          <PrivateRoute
            path={`${process.env.PUBLIC_URL}/accounts`}
            exact
            component={Accounts}
          />
          <PrivateRoute
            path={`${process.env.PUBLIC_URL}/account-add`}
            exact
            component={AccountAdd}
          />
          <PrivateRoute
            path={`${process.env.PUBLIC_URL}/account-detail/:id`}
            exact
            component={AccountDetail}
          />
          <PrivateRoute
            path={`${process.env.PUBLIC_URL}/account-edit/:id`}
            exact
            component={AccountEdit}
          />
          <PrivateRoute
            path={`${process.env.PUBLIC_URL}/contact`}
            exact
            component={Contacts}
          />
          <PrivateRoute
            path={`${process.env.PUBLIC_URL}/contact-detail/:id`}
            exact
            component={ContactDetail}
          />
          <PrivateRoute
            path={`${process.env.PUBLIC_URL}/Studies`}
            exact
            component={Studies}
          />
          <PrivateRoute
            path={`${process.env.PUBLIC_URL}/Study-detail/:id`}
            exact
            component={StudyDetailsView}
          />
          <PrivateRoute
            path={`${process.env.PUBLIC_URL}/joikcard`}
            exact
            component={JoikCardList}
          />
          <PrivateRoute
            path={`${process.env.PUBLIC_URL}/payment`}
            exact
            component={PaymentList}
          />

          <PrivateRoute
            path={`${process.env.PUBLIC_URL}/payment-detail/:id`}
            exact
            component={details}
          />
          <PublicRoute
            path={`${process.env.PUBLIC_URL}/login`}
            exact
            component={Login}
          />
          <PublicRoute
            path={`${process.env.PUBLIC_URL}/changePassword`}
            exact
            component={ChangePassword}
          />

          <PublicRoute
            path={`${process.env.PUBLIC_URL}/forgotPassword`}
            exact
            component={ForgotPassword}
          />
          <PublicRoute
            path={`${process.env.PUBLIC_URL}/confirmForgotPassword`}
            exact
            component={ConfirmForgotPassword}
          />
          <PrivateRoute
            path={`${process.env.PUBLIC_URL}/ticket`}
            exact
            component={TicketRoot}
          />

          <PrivateRoute
            path={`${process.env.PUBLIC_URL}/documentations`}
            exact
            component={DocumentList}
          />
        </Switch>
      </Router>
    );
  }
}

export default AppRouter;
