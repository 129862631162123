import axios from "axios";
import { Formik } from "formik";
import React, { Component, Fragment } from 'react';
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { compose } from "redux";
import * as actions from "../../actions";
import Select from "../../components/form/Select";
import Text from "../../components/form/Text";
import Textarea from "../../components/form/TextArea";
import Loader from "../../components/Loader";
import { BASE_URL as SERVER_URL } from "../../utils/constants";


const MODULE_NAME = "Ticket";
const INITIAL_VALUES = {
  projectNumber: "",
  siteNumber: "",
  subjectNumber: "",
  category: "",
  commentText: "",
  ticketStatus: "",
  comments: [{}],
  file: "",
  defaultText: "",
};
class Details extends Component {
  constructor(props) {
    super(props);
    this.state = {
      detail: { props },
      responseFetched: false,
      loading: false,
      studies: [],
      site: [],
      subjects: [],
      files: "a",
      fileSize: 0,
      fileError: "",
      fileNames: [],
      ticketData: {},
      comments: [{ attachmentDetails: [{}] }],

      defaultTextBool: false,

      ticketStatusArr: [
        {
          value: "Open to Site",
          label: "Open to Site",
        },
        {
          value: "Open to Sponsor",
          label: "Open to Sponsor",
        },
        {
          value: "Closed",
          label: "Close",
        },
      ],
    };
  }
  validate = (values) => {
    let errors = {};

    if (!this.state.defaultTextBool) {
      if (!values.commentText || values.commentText.trim().length === 0) {
        errors.commentText = "Comments  is required";
      }
    }

    if (this.state.ticketData.userType == "Admin") {
      if (!values.ticketStatus || values.ticketStatus.trim().length === 0) {
        errors.ticketStatus = "Ticket Status is required";
      }
    }

    console.log(errors, "final value");
    return errors;
  };

  handleSubmit = async (values, { setSubmitting, resetForm }) => {
    this.setState(() => ({
      loading: true,
    }));

    let userObj = JSON.parse(localStorage.getItem("user"));

    var modifiedValues = {
      ticketStatus: values.ticketStatus,
      category: values.category,
      site: { id: this.props.selectedTicket.site.id },
      subjectNumber: values.subjectNumber,
      userType: "Admin",
      username: localStorage.getItem("userEmail"),
      comments: [
        {
          commentText: this.state.defaultTextBool
            ? "------ Action: Close ------"
            : values.commentText,
          username: localStorage.getItem("userEmail"),
          userFullName: userObj.given_name + " " + userObj.family_name,
          userType: "Admin",
        },
      ],
    };

    if (this.state.ticketData.userType != "Admin") {
      modifiedValues.ticketStatus = "Answered";
    }

    if (!values.subjectNumber) {
      delete modifiedValues.subjectNumber;
    }

    try {
      var formData = new FormData();

      formData.append("ticket-json", JSON.stringify(modifiedValues));
      if (this.state.fileSize > 0) {
        for (var i = 0; i < this.state.attachement.length; i++) {
          formData.append("attachment ", this.state.attachement[i]);
        }
      }

      const response = await axios({
        method: "PUT",
        url: `${SERVER_URL}/ticket/${this.props.selectedTicket.id}`,
        processData: false,
        cache: false,
        data: formData,
        headers: {
          "content-type": "multipart/form-data",
          "authorization-token": localStorage.getItem("id_token"),
        },
      });

      if (response) {
        setTimeout(() => {
          setSubmitting(false);
          toast(`${MODULE_NAME} updated successfully.`, { type: "success" });
          this.props.hideDetails();
        }, 1500);
      }
    } catch (error) {
      this.setState(() => ({
        loading: false,
      }));

      if (error.response === undefined) {
        toast(
          "An unexpected error occurred. Please try again. If the error persists, please inform the helpdesk",
          { type: "error" }
        );
      } else if (error.response.status == "422") {
        toast(error.response.data[0].message, { type: "error" });
      } else {
        toast(error["message"], { type: "error" });
      }
    }
    this.setState(() => ({
      loading: false,
    }));
  };

  onEscapeKeyDown = (e) => {
    e.preventDefault();
  };

  getTicketData = () => {
    this.getDetails();
  };

  componentDidMount() {
    this.getDetails();
  }

  getDetails = () => {
    this.setState(() => ({
      loading: true,
      fileNames: [],
    }));

    this.props.get(
      {
        url: `ticket/${this.props.selectedTicket.id}`,
      },
      async (response) => {
        this.setState({
          ticketData: response,
          comments: response.comments,
          loading: false,
          detail: {
            projectNumber: response.site.study.studyNumber,
            siteNumber: response.site.siteId,
            subjectNumber: response.subjectNumber,
            category: response.category,
            ticketStatus: response.ticketStatus,
            commentText: "",
          },
        });
      }
    );
  };

  downloadItem = (item) => {
    this.props.getFile(
      {
        url: `${SERVER_URL}/ticket/attachment/${item.id}`,
        fileName: item.name,
        fileType: item.contentType,
      },
      async (response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", item.fileName);

        // Append to html link element page
        document.body.appendChild(link);

        // Start download
        link.click();

        // Clean up and remove the link
        link.parentNode.removeChild(link);
      }
    );
  };

  render = () => {
    let { detail } = this.state;

    return (
      <>
        <div className="content RowDesign">
          <div className="row">
            {Object.keys(detail).length > 0 && (
              <Formik
                initialValues={this.state.detail}
                validate={this.validate}
                onSubmit={this.handleSubmit}
                enableReinitialize
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  setFieldValue,
                  isSubmitting,
                }) => (
                  <form
                    onSubmit={handleSubmit}
                    autoComplete="off"
                    className="float-left FormEditDesign"
                  >
                    <div className="col-md-12">
                      <div className="col-lg-12  float-left">
                        <div className="modal-body modalpadding">
                          <div className="row">
                            <div className="col-lg-12">
                              <h4>Ticket details - Joik Ticket ID {this.props.siteData.joikId}</h4>
                            </div>
                          </div>
                          <div className="col-lg-12">
                            <div className="row">
                              {/* <!--First--> */}
                              <div className="col-md-12">
                                <div className="row"></div>
                                <div className="row">
                                  <div className="col-lg-6 col-md-6">
                                    <div className="form-group required">
                                      <Text
                                        title="Project Number"
                                        name="projectNumber"
                                        placeholder="Select Project Number"
                                        id="projectNumber"
                                        errors={errors}
                                        touched={touched}
                                        values={values}
                                        handleBlur={handleBlur}
                                        disabled={true}
                                      />
                                    </div>
                                  </div>

                                  <div className="col-lg-6 col-md-6">
                                    <div className="form-group required">
                                      <Text
                                        title="Site Number"
                                        name="siteNumber"
                                        placeholder="Select Site Number"
                                        id="siteNumber"
                                        errors={errors}
                                        touched={touched}
                                        values={values}
                                        handleChange={(e) => {
                                          const value = e.target.value;
                                          setFieldValue("siteNumber", value);
                                          this.getSubjectList(value);
                                        }}
                                        handleBlur={handleBlur}
                                        disabled={true}
                                      />
                                    </div>
                                  </div>
                                </div>

                                <div className="row">
                                  <div className="col-lg-6 col-md-12">
                                    <div className="form-group">
                                      <Text
                                        title="Subject  Number"
                                        name="subjectNumber"
                                        placeholder="Subject Number"
                                        errors={errors}
                                        touched={touched}
                                        values={values}
                                        handleChange={(e) => {
                                          const value = e.target.value;
                                          setFieldValue("subjectNumber", value);
                                        }}
                                        handleBlur={handleBlur}
                                        disabled={true}
                                      />
                                    </div>
                                  </div>
                                  <div className="col-lg-6 col-md-12">
                                    <div className="form-group required">
                                      <Text
                                        title="Ticket Category"
                                        name="category"
                                        placeholder="Select payment method"
                                        id="category"
                                        errors={errors}
                                        touched={touched}
                                        values={values}
                                        handleChange={(e) => {
                                          const value = e.target.value;
                                          setFieldValue("category", value);
                                        }}
                                        handleBlur={handleBlur}
                                        disabled={true}
                                      />
                                    </div>
                                  </div>
                                </div>

                                <div class="row">
                                  <div class="col-lg-12 col-md-12">
                                    <div class="LoopParent">
                                      {this.state.comments.map((item) => (
                                        <div class="form-group LoopData">
                                          <label class="AdminName">
                                            {`${item.userType} - ${item.userFullName}`}
                                          </label>
                                          <p class="TimeDateAdmin">
                                            {new Date(
                                              item.createdDateTime
                                            ).toGMTString()}
                                          </p>
                                          <span class="text-muted MutedText">
                                            {item.commentText}
                                          </span>
                                          <ul class="loopAttaFiles">
                                            {item.attachmentDetails.map(
                                              (element) => (
                                                <li>
                                                  <a
                                                    href="javascript:void(0)"
                                                    onClick={() =>
                                                      this.downloadItem(element)
                                                    }
                                                    className="attcFilename"
                                                  >
                                                    {element.fileName} (
                                                    {element.size})
                                                  </a>
                                                </li>
                                              )
                                            )}
                                          </ul>
                                        </div>
                                      ))}
                                    </div>
                                  </div>
                                </div>
                                {this.state.detail.ticketStatus != "Closed" ? (
                                  <Fragment>
                                    <div className="row">
                                      <div className="col-lg-12 col-md-12">
                                        <div className="form-group required">
                                          {!this.state.defaultTextBool ? (
                                            <Textarea
                                              title="Description"
                                              name="commentText"
                                              placeholder="Description"
                                              errors={errors}
                                              touched={touched}
                                              values={values}
                                              handleChange={handleChange}
                                              handleBlur={handleBlur}
                                              disabled={false}
                                            />
                                          ) : (
                                            ""
                                          )}

                                          {this.state.defaultTextBool ? (
                                            <Textarea
                                              title="Description"
                                              name="defaultText"
                                              placeholder="Description"
                                              errors={errors}
                                              touched={touched}
                                              values={values}
                                              value="------ Action: Close ------"
                                              handleChange={handleChange}
                                              handleBlur={handleBlur}
                                              disabled={true}
                                            />
                                          ) : (
                                            ""
                                          )}
                                        </div>
                                      </div>
                                    </div>

                                    <div className="row">
                                      {this.state.ticketData.userType ==
                                      "Admin" ? (
                                        <div className="col-lg-12 col-md-12">
                                          <div className="form-group required">
                                            <Select
                                              title="Action"
                                              name="ticketStatus"
                                              placeholder="Select Action"
                                              options={
                                                this.state.ticketStatusArr
                                              }
                                              id="ticketStatus"
                                              errors={errors}
                                              touched={touched}
                                              values={values}
                                              handleChange={(e) => {
                                                const value = e.target.value;
                                                setFieldValue(
                                                  "ticketStatus",
                                                  value
                                                );
                                                if (value == "Closed") {
                                                  this.setState({
                                                    defaultTextBool: true,
                                                  });
                                                } else {
                                                  this.setState({
                                                    defaultTextBool: false,
                                                  });
                                                }
                                              }}
                                              handleBlur={handleBlur}
                                              disabled={
                                                this.state.ticketData
                                                  .userType != "Admin"
                                              }
                                            />
                                          </div>
                                        </div>
                                      ) : (
                                        ""
                                      )}
                                    </div>

                                    <div className="row">
                                      <div className="col-lg-12 col-md-12">
                                        <div className="form-group required">
                                          <small className="info">
                                            Multiple Files can be attached. Max
                                            file size: 5 MB
                                          </small>
                                          <div class="form-group FileuploadLabel">
                                            <div class="FileUploadDesign">
                                              <input
                                                type="file"
                                                class="form-control"
                                                placeholder=""
                                                id="fileupload"
                                                name="fileSizeLimit"
                                                errors={errors}
                                                touched={touched}
                                                handleChange={handleChange}
                                                onChange={(e) => {
                                                  this.setState({
                                                    attachement: "",
                                                  });

                                                  var allFiles = [];

                                                  this.setState(
                                                    {
                                                      attachement:
                                                        e.target.files,
                                                    },
                                                    () => {
                                                      var totalSize = 0;
                                                      for (
                                                        var i = 0;
                                                        i <
                                                        this.state.attachement
                                                          .length;
                                                        i++
                                                      ) {
                                                        console.log(
                                                          this.state
                                                            .attachement[i].size
                                                        );
                                                        totalSize +=
                                                          this.state
                                                            .attachement[i]
                                                            .size;
                                                        allFiles.push(
                                                          this.state
                                                            .attachement[i].name
                                                        );
                                                      }
                                                      totalSize =
                                                        totalSize / 1024 / 1024;
                                                      if (totalSize > 5) {
                                                        this.setState({
                                                          fileError:
                                                            "Attachement size should be less then 5 MB",
                                                          fileSize: totalSize,
                                                        });
                                                      } else {
                                                        this.setState({
                                                          fileError: "",
                                                          fileSize: totalSize,
                                                          fileNames: [
                                                            ...allFiles,
                                                          ],
                                                        });
                                                      }
                                                    }
                                                  );
                                                }}
                                                multiple
                                              />
                                              <label
                                                htmlFor="fileupload"
                                                class="DefaultButton AquaButton"
                                              >
                                                Upload File
                                              </label>
                                              <small className="error">
                                                {this.state.fileError}
                                              </small>
                                            </div>
                                            <ul class="UploadedFile">
                                              {this.state.fileNames.map(
                                                (item) => (
                                                  <li>{item}</li>
                                                )
                                              )}
                                            </ul>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </Fragment>
                                ) : (
                                  ""
                                )}
                              </div>
                            </div>
                          </div>

                          {/* </form> */}
                        </div>
                      </div>

                      <div className="col-lg-12  float-left mt-3 mb-3 text-center d-flex justify-content-center">
                        {this.state.detail.ticketStatus != "Closed" ? (
                          <Fragment>
                            <a
                              onClick={this.props.hideDetails}
                              className="btn btn-default DefaultButton"
                            >
                              Cancel
                            </a>
                            <button
                              disabled={
                                this.state.detail.ticketStatus == "Closed"
                              }
                              type="submit"
                              className="btn btn-default DefaultButton AquaButton"
                              style={{ marginLeft: "5px" }}
                            >
                              Save
                            </button>
                          </Fragment>
                        ) : (
                          <a
                            onClick={this.props.hideDetails}
                            className="btn btn-default DefaultButton AquaButton"
                          >
                            Back
                          </a>
                        )}
                      </div>
                    </div>
                  </form>
                )}
              </Formik>
            )}
          </div>
        </div>
        <Loader loading={this.state.loading} />
      </>
    );
  };
}

const mapStateToProps = state => ({
});

export default compose(
  connect(mapStateToProps, actions)
)(Details);