import { Formik } from "formik";
import React, { Component } from 'react';
import Modal from "react-bootstrap/Modal";
import DatePicker from "react-date-picker";
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { compose } from 'redux';
import * as actions from '../../actions';
import Select from "../../components/form/Select";
import Text from "../../components/form/Text";
import Loader from '../../components/Loader';
import AccountData from '../../views/Common/AccountData';
import AccountListHospital from "../Common/AccountListHospital";
import ContactData from '../Common/ContactData';
import ContactList from '../Common/ContactList';
import SwitchButton from '../../components/form/SwitchButton';



const MODULE_BASE_ROUTE = 'accounts';
const MODULE_NAME = 'Site';

const INITIAL_VALUES = {
  accountType: "",
  joikId: "",
  name: "",
  fname: "",
  lname: "",
  addressLine1: "",
  addressLine2: "",
  city: "",
  state: "",
  postcode: "",
  countryCode: "",
  email: "",
  landlineNumber: "",
  fax: "",
  siteNumber: "",
  siteStatus: "",
  siteStatusEffectiveDate: "",
  joikCardAllowed: false
};

class AddSite extends Component {
  constructor(props) {
    super(props);

    this.state = {
      connectListType: "",
      detail: INITIAL_VALUES,
      responseFetched: false,
      loading: false,
      accountSelectType: "",
      accountListModel: false,
      SponsorListModel: false,
      selectedAccountName: "",
      selectedAccountId: "",
      date: new Date(),
      shippingContactSelectedId: "",
      selectedAccountObj: {
        id: "",
        joikId: "",
        accountType: "",
        name: "",
        address: {
          addressLine1: "",
          addressLine2: "",
          city: "",
          state: "",
          postcode: "",
          countryCode: "",
        },
        contactInfo: { email: "", landlineNumber: "" },
      },
      shippingAccountObj: {
        id: "",
        joikId: "",
        accountType: "",
        account: {},
        firstName: "",
        lastName: "",
        address: {
          addressLine1: "",
          addressLine2: "",
          city: "",
          state: "",
          postcode: "",
          countryCode: "",
        },
        contactInfo: { email: "", landlineNumber: "" },
      },

      InvestigatorObject: {
        id: "",
        joikId: "",
        accountType: "",
        account: {},
        firstName: "",
        lastName: "",
        address: {
          addressLine1: "",
          addressLine2: "",
          city: "",
          state: "",
          postcode: "",
          countryCode: "",
        },
        contactInfo: { email: "", landlineNumber: "" },
      },
    };
  }

  validate = (values) => {
    let errors = {};

    if (!values.siteNumber || values.siteNumber.length === 0) {
      errors.siteNumber = "Site number is required.";
    }

    if (values.siteNumber && values.siteNumber.length < 2) {
      errors.siteNumber = "Site number should contain atleast 5-50 characters";
    }

    if (!values.siteStatus) {
      errors.siteStatus = "Site Status is required.";
    }
    return errors;
  };

  onChange = (val) => {
    var value = "";
    if (val != null) {
      value = new Date(val.getTime() - val.getTimezoneOffset() * 60000);
    }

    this.setState({
      date: value,
    });
  };



  resetObjects = () => {
    
    this.setState({
      InvestigatorObject: {
        id: "",
        joikId: "",
        accountType: "",
        account: {},
        firstName: "",
        lastName: "",
        address: {
          addressLine1: "",
          addressLine2: "",
          city: "",
          state: "",
          postcode: "",
          countryCode: "",
        },
        contactInfo: { email: "", landlineNumber: "" },
      },
      shippingAccountObj: {
        id: "",
        joikId: "",
        accountType: "",
        account: {},
        firstName: "",
        lastName: "",
        address: {
          addressLine1: "",
          addressLine2: "",
          city: "",
          state: "",
          postcode: "",
          countryCode: "",
        },
        contactInfo: { email: "", landlineNumber: "" },
      },
    });
  }

  handleSubmit = async (values, { setSubmitting, resetForm }) => {
    this.setState(() => ({
      loading: true,
    }));

    // values = { ...values };

    const modifiedValues = {
      // "id": this.state.intialDataWithIds.id,
      joikCardAllowed: values.joikCardAllowed,
      siteId: values.siteNumber,
      siteStatus: values.siteStatus,
      study: {
        id: String(window.location).split("Study-detail/")[1],
      },

      account: {
        id: this.state.selectedAccountObj.id,
      },

      shippingContact: {
        id: this.state.shippingAccountObj.id,
      },
      principalInvestigator:{
        id:this.state.InvestigatorObject.id
      },

      siteStatusEffectiveDate: this.state.date,
    };

    await this.props.apiJSON(
      { url: `site`, method: "POST", data: modifiedValues },
      () => {
        setTimeout(() => {
          setSubmitting(false);
          toast(`${MODULE_NAME} added successfully.`, { type: "success" });
          this.props.setParentState(() => ({
            isAddModal: false,
            loading: false,
          }));
          this.props.reloadList();
        }, 1500);
        console.log(this.props);
      }
    );

    setTimeout(() => {
      this.setState(() => ({
        loading: false,
      }));
    }, 2000);
  };

  componentDidMount = () => {
    console.log("component came into memory");
  };

  openAssignAccountList = (type) => {
    console.log(type);
    this.setState(() => ({
      accountSelectType: type,
      accountListModel: true,
    }));
  };

  openInvistigatorList = () => {

  }

  openSponsorList = (type) => {
    
    if (type == "Investigator") {
      this.setState(
        {
          connectListType: type,
        },
        () => {
          this.setState(() => ({
            SponsorListModel: true,
          }));
        }
      );
  
    } else {

       this.setState(
         {
           connectListType: type,
         },
         () => {
           this.setState(() => ({
             SponsorListModel: true,
           }));
         }
       );
      
    }
    
    
  };

  componentDidMount() {}

  closeAssignModel = () => {
    this.setState(() => ({
      accountListModel: false,
    }));
  };

  closeSponsorModel = () => {
    this.setState(() => ({
      SponsorListModel: false,
    }));
  };

  componentDidUpdate() {}

  selectedAccount = (account, type) => {
    this.setState(() => ({
      selectedAccountObj: account,
      selectedAccountId: account.id,
      accountListModel: false,
    }));
    this.resetObjects();
  };

  selectSponser = (account, type) => {

    if (type == "Investigator") {

     
      this.setState({
        InvestigatorObject: account,
        shippingContactSelectedId: account.id,
        SponsorListModel: false,
      });
    } else {
      this.setState({
        shippingAccountObj: account,
        shippingContactSelectedId: account.id,
        SponsorListModel: false,
      });
    }
      
  };

  resetModelData = () => {
    this.setState({
      selectedAccountId:'',
      selectedAccountObj: {
        id: "",
        joikId: "",
        accountType: "",
        name: "",
        address: {
          addressLine1: "",
          addressLine2: "",
          city: "",
          state: "",
          postcode: "",
          countryCode: "",
        },
        contactInfo: { email: "", landlineNumber: "" },
      },
      shippingAccountObj: {
        id: "",
        joikId: "",
        accountType: "",
        account: {},
        firstName: "",
        lastName: "",
        address: {
          addressLine1: "",
          addressLine2: "",
          city: "",
          state: "",
          postcode: "",
          countryCode: "",
        },
        contactInfo: { email: "", landlineNumber: "" },
      },
      date: new Date(),
    });
  };
  onEscapeKeyDown = (e) => {
    e.preventDefault();
  };

  render = () => {
    let { detail } = this.state;

    return (
      <>
        <Modal
          show={this.props.modalIsOpen}
          onHide={this.props.toggleModal}
          animation={false}
          onShow={this.resetModelData}
          className={"CustomModal width900"}
          backdrop={false}
          onEscapeKeyDown={this.onEscapeKeyDown}
        >
          <div className="content">
            <div className="row">
              {Object.keys(detail).length > 0 && (
                <Formik
                  initialValues={this.state.detail}
                  validate={this.validate}
                  onSubmit={this.handleSubmit}
                  enableReinitialize
                >
                  {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    setFieldValue,
                    isSubmitting,
                  }) => (
                    <form
                      onSubmit={handleSubmit}
                      autoComplete="off"
                      className="float-left FormEditDesign"
                    >
                      <div className="modal-header">
                        <h4 className="modal-title">Add Site</h4>
                        <button
                          type="button"
                          onClick={this.props.toggleModal}
                          className="close"
                        >
                          &times;
                        </button>
                      </div>

                      <div className="col-md-12">
                        <div className="col-lg-12  float-left">
                          <div className="modal-body modalpadding">
                            <div className="row"></div>
                            <div className="col-lg-12">
                              <div className="row">
                                {/* <!--First--> */}
                                <div className="col-md-12">
                                  <div className="row">
                                    <div class="col-lg-12">
                                      <h4>Enter Sites Details</h4>
                                    </div>
                                  </div>
                                  <div className="row">
                                    <div className="col-lg-12 col-md-12">
                                      <div className="form-group required">
                                        <Text
                                          title="Site Number"
                                          name="siteNumber"
                                          placeholder="Site Number"
                                          // id="input_name"
                                          errors={errors}
                                          touched={touched}
                                          values={values}
                                          handleChange={handleChange}
                                          handleBlur={handleBlur}
                                          type="number"
                                        />
                                      </div>
                                    </div>
                                  </div>
                                  <div className="row">
                                    <div class="col-lg-12">
                                      <SwitchButton
                                            title="Joik Card Allowed"
                                            checked={values.joikCardAllowed}
                                            handleChange={() => {
                                              setFieldValue('joikCardAllowed', !values.joikCardAllowed)
                                            }}
                                          />
                                    </div>
                                  </div>
                                  <div className="row">
                                    <div className="col-lg-12 mt-1">
                                      <h4>Assign Account</h4>
                                    </div>
                                    <div className="col-lg-12 mt-1 mb-2">
                                      <a
                                        onClick={() =>
                                          this.openAssignAccountList("")
                                        }
                                        class="DefaultButton "
                                      >
                                        Assign Account
                                      </a>
                                    </div>
                                  </div>
                                  <div className="row">
                                    <div className="col-lg-12  float-left">
                                      <AccountData
                                        deatils={this.state.selectedAccountObj}
                                      ></AccountData>
                                    </div>
                                  </div>

                                  <div className="row">
                                    <div className="col-lg-12 mt-1">
                                      <h4>Assign Principal Investigator</h4>
                                    </div>
                                    <div className="col-lg-12 mt-1 mb-2">
                                      <a
                                        onClick={() =>
                                          this.openSponsorList("Investigator")
                                        }
                                        className={
                                          this.state.selectedAccountId != ""
                                            ? "DefaultButton"
                                            : "DefaultButton dsbldbtn"
                                        }
                                      >
                                        Assign Principal Investigator
                                      </a>
                                    </div>
                                  </div>
                                  <div className="row">
                                    <div className="col-lg-12  float-left">
                                      <ContactData
                                        deatils={this.state.InvestigatorObject}
                                      ></ContactData>
                                    </div>
                                  </div>

                                  <div className="row">
                                    <div className="col-lg-12 mt-1">
                                      <h4>Shipping Contact</h4>
                                    </div>
                                    <div className="col-lg-12 mt-1 mb-2">
                                      <a
                                        onClick={() =>
                                          this.openSponsorList("contact")
                                        }
                                        className={
                                          this.state.selectedAccountId != ""
                                            ? "DefaultButton"
                                            : "DefaultButton dsbldbtn"
                                        }
                                      >
                                        Assign Shipping Contact
                                      </a>
                                    </div>

                                    <div className="row">
                                      <div className="col-lg-12  float-left">
                                        <ContactData
                                          deatils={
                                            this.state.shippingAccountObj
                                          }
                                        ></ContactData>
                                      </div>
                                    </div>
                                  </div>

                                  <div className="row mt-2">
                                    <div className="col-lg-6 col-md-12">
                                      <div className="form-group required">
                                        <Select
                                          title="Site Status"
                                          name="siteStatus"
                                          placeholder="Site Status"
                                          options={[
                                            {
                                              value: "Active",
                                              label: "Active",
                                            },
                                            {
                                              value: "Inactive",
                                              label: "Inactive",
                                            },
                                          ]}
                                          id="siteStatus"
                                          errors={errors}
                                          touched={touched}
                                          values={values}
                                          handleChange={(e) => {
                                            const value = e.target.value;
                                            // this.selectedGender(value);
                                            setFieldValue("siteStatus", value);
                                          }}
                                          handleBlur={handleBlur}
                                          disabled={false}
                                        />
                                      </div>
                                    </div>
                                    <div className="col-lg-6 col-md-12">
                                      <div className="form-group required">
                                        <label class="form-control-label">
                                          Site Status Effective Date
                                        </label>
                                        <DatePicker
                                          utcOffset={0}
                                          dateFormat="DD-MMM HH:mm"
                                          onChange={this.onChange}
                                          value={this.state.date}
                                          required={true}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            {/* </form> */}
                          </div>
                        </div>
                        <div className="col-lg-12  float-left mt-3 mb-3 text-center d-flex justify-content-center">
                          <a
                            onClick={this.props.toggleModal}
                            className="btn btn-default DefaultButton"
                          >
                            Cancel
                          </a>
                          <button
                            type="submit"
                            className={
                              this.state.selectedAccountId != "" &&
                              this.state.InvestigatorObject.id != "" && this.state.shippingAccountObj.id!=""
                                ? "btn btn-default DefaultButton AquaButton"
                                : "btn btn-default DefaultButton AquaButton dsbldbtn"
                            }
                            style={{ marginLeft: "5px" }}
                          >
                            Save
                          </button>
                        </div>
                      </div>
                    </form>
                  )}
                </Formik>
              )}
            </div>

            {/* <!-- /dashboard content --> */}
          </div>

          <AccountListHospital
            type={this.state.accountSelectType}
            modalIsOpen={this.state.accountListModel}
            closeAssignModel={this.closeAssignModel}
            selectedAccountInfo={this.selectedAccount}
          />

          <ContactList
            type={this.state.accountSelectType}
            modalIsOpen={this.state.SponsorListModel}
            closeAssignModel={this.closeSponsorModel}
            connectListType={this.state.connectListType}
            accountId={this.state.selectedAccountId}
            selectedAccountInfo={this.selectSponser}
          ></ContactList>

          <Loader loading={this.state.loading} />
        </Modal>
      </>
    );
  };
}


const mapStateToProps = state => ({
  // errorMessage: state.auth.errorMessage
});

export default compose(
  connect(mapStateToProps, actions)
)(AddSite);