import React, { Component, Fragment, useState, useMemo } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import * as actions from '../../actions';
import { Formik } from "formik";
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import Modal from "react-bootstrap/Modal";
import AccountSponsorList from "../Common/AccountSponsorList"
import Loader from '../../components/Loader';
import Select from "../../components/form/Select";
import Text from "../../components/form/Text";
import TextArea from "../../components/form/TextArea";
import CountryOperation from '../../components/CountryOperation';
import SwitchButton from '../../components/form/SwitchButton';

const MODULE_BASE_ROUTE = 'Study';
const MODULE_NAME = 'Study';

const INITIAL_VALUES = {
  joikId: "",
  shortTitle: "",
  fullTitle: "",
  studyNumber: "",
  projectNumber: "",
  projectPhase: "",
  therapeuticArea: "",
  studyStatus: "",
  milestonePaymentsAllowed: false,
  sponsor: {
    id: 1,
  }
};

class StudyEdit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      detail: INITIAL_VALUES,
      responseFetched: false,
      loading: false,
      accountListModel: false,
      selectedAccountName: "",
      selectedAccountId: "",
      selectedAccountObj: { address: {}, contactInfo: {} }
    };
  }

  validate = (values) => {
    let errors = {};

    if (!values.shortTitle || values.shortTitle.trim().length === 0) {
      errors.shortTitle = "Short Title required.";
    }

    if (values.shortTitle && values.shortTitle.trim().length < 5) {
      errors.shortTitle = "Short Title should contain atleast 5-100 characters";
    }

    // if (!values.projectNumber || values.projectNumber.trim().length === 0) {
    //   errors.projectNumber = "Project Number is required.";
    // }

    // if(values.projectNumber && values.projectNumber.trim().length > 50){
    //   errors.projectNumber = "Project Number atleast 1-50 characters";
    // }


    if (!values.projectPhase || values.projectPhase.trim().length === 0) {
      errors.projectPhase = "Project Phase is required.";
    }

    if (values.projectPhase && values.projectPhase.trim().length > 50) {
      errors.projectPhase = "project Phase atleast 1-50 characters";
    }

    if (!values.studyStatus || values.studyStatus.trim().length === 0) {
      errors.studyStatus = "Study Status required.";
    }


    return errors;
  };

  handleSubmit = async (values, { setSubmitting, resetForm }) => {

    this.setState(() => ({
      loading: true,
    }));

    // values = { ...values };

    const modifiedValues = {
      joikId: values.joikId,
      shortTitle: values.shortTitle,
      // fullTitle: values.fullTitle,
      studyNumber: values.studyNumber,
      projectPhase: values.projectPhase,
      therapeuticArea: values.therapeuticArea,
      studyStatus: values.studyStatus,
      milestonePaymentsAllowed: values.milestonePaymentsAllowed,
      sponsor: {
        id: this.state.selectedAccountId,
      }
    };

    let id = this.props.id;
    this.setState(() => ({
      loading: true,
    }));


    await this.props.apiJSON({ url: `study/${this.props.id}`, method: 'PUT', data: modifiedValues }, () => {
      setTimeout(() => {
        setSubmitting(false);
        toast(`${MODULE_NAME} updated successfully.`, { type: 'success' });
        this.props.setParentState(() => ({
          isAddModal: false,
          loading: false,
        }));
        this.props.rerouteToList();

      }, 1500);
      console.log(this.props);
    });

    // Loader stop if no response comes
    setTimeout(() => {
      this.setState(() => ({
        loading: false,
      }));
    }, 2000);
  }

  openAssignAccountList = () => {
    // const [startDate, setStartDate] = useState(new Date());
    this.setState(() => ({
      accountListModel: true,
    }));
  }

  componentDidMount() {
    // this.getDetail();
  }

  loadDetails = () => {

    this.getDetail();

  }

  getDetail = () => {

    const { id } = this.state;

    this.setState(() => ({
      loading: true,
    }));

    this.props.get({ url: `study/${this.props.id}`, errorUrl: `/${MODULE_BASE_ROUTE}`, history: this.props.history }, async (response) => {
      console.log(response, '======>response');

      this.setState(() => ({
        detail: response,
        responseFetched: true,
        loading: false,
        selectedAccountObj: response.sponsor,
        selectedAccountId: response.sponsor.id
      }))
    });
  }



  closeAssignModel = () => {
    this.setState(() => ({
      accountListModel: false,
    }));

  }




  selectedAccount = (account, type) => {


    this.setState(() => ({
      selectedAccountObj: account,
      selectedAccountId: account.id,
      accountListModel: false,

    }));
  }

  onEscapeKeyDown = (e) => {
    e.preventDefault();
  };

  render = () => {
    let {
      detail
    } = this.state;
    let startDate = new Date();
    let setStartDate = new Date();


    // console.log(this.state, '========>this.state');
    // console.log(accounts, '====================>accounts');

    return (
      <>
        <Modal
          show={this.props.modalIsOpen}
          onHide={this.props.toggleModal}
          animation={false}
          backdrop={false}
          onEscapeKeyDown={this.onEscapeKeyDown}
          className={"CustomModal width900"}
          onShow={this.loadDetails}
        >
          {/* <!-- Content area --> */}
          <div className="content">
            {/* <!-- Dashboard content --> */}
            <div className="row">
              {Object.keys(detail).length > 0 && (
                <Formik
                  initialValues={this.state.detail}
                  validate={this.validate}
                  onSubmit={this.handleSubmit}
                  enableReinitialize
                >
                  {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    setFieldValue,
                    isSubmitting,
                    /* and other goodies */
                  }) => (
                    <form
                      onSubmit={handleSubmit}
                      autoComplete="off"
                      className="float-left FormEditDesign"
                    >
                      <div className="modal-header">
                        <h4 className="modal-title">Edit Study</h4>
                        <button
                          type="button"
                          onClick={this.props.toggleModal}
                          className="close"
                        >
                          &times;
                        </button>
                      </div>

                      <div className="col-md-12">
                        <div className="col-lg-12  float-left">
                          <div className="modal-body modalpadding">
                            <div className="row">
                              <div className="col-lg-12">
                                <h4>Enter Study details</h4>
                              </div>
                            </div>
                            <div className="col-lg-12">
                              <div className="row">
                                {/* <!--First--> */}
                                <div className="col-md-12">
                                  <div className="row"></div>
                                  <div className="row">
                                    <div className="col-lg-12 col-md-12">
                                      <div className="form-group">
                                        <Text
                                          title="Joik Study ID"
                                          name="joikId"
                                          placeholder="Joik Study ID"
                                          // id="input_name"
                                          errors={errors}
                                          touched={touched}
                                          values={values}
                                          handleChange={handleChange}
                                          handleBlur={handleBlur}
                                          disabled={true}
                                        />
                                      </div>
                                    </div>
                                  </div>

                                  <div className="row">
                                    <div className="col-lg-6 col-md-12">
                                      <div className="form-group required">
                                        <Text
                                          title="Project Number"
                                          name="studyNumber"
                                          placeholder="Project Number"
                                          // id="input_name"
                                          errors={errors}
                                          touched={touched}
                                          values={values}
                                          handleChange={handleChange}
                                          handleBlur={handleBlur}
                                          disabled={false}
                                        />
                                      </div>
                                    </div>
                                    <div className="col-lg-6 col-md-12">
                                      <div className="form-group required">
                                        <Text
                                          title="Short Title"
                                          name="shortTitle"
                                          placeholder="Short Title"
                                          // id="input_name"
                                          errors={errors}
                                          touched={touched}
                                          values={values}
                                          handleChange={handleChange}
                                          handleBlur={handleBlur}
                                          disabled={false}
                                        />
                                      </div>
                                    </div>
                                  </div>

                                  {/* <div className="row">
                                    <div className="col-lg-12 col-md-12">
                                      <div className="form-group FormControlTextArea">
                                        <TextArea
                                          title="Project full title"
                                          name="fullTitle"
                                          placeholder="Project full title"
                                          rows="6"
                                          errors={errors}
                                          touched={touched}
                                          values={values}
                                          handleChange={handleChange}
                                          handleBlur={handleBlur}
                                          disabled={false}
                                        />
                                      </div>
                                    </div>
                                  </div> */}

                                  <div className="row">
                                    <div className="col-lg-6 col-md-12">
                                      <div className="form-group required">
                                        <Text
                                          title="Project Phase"
                                          name="projectPhase"
                                          placeholder="Project Phase"
                                          // id="input_name"
                                          errors={errors}
                                          touched={touched}
                                          values={values}
                                          handleChange={handleChange}
                                          handleBlur={handleBlur}
                                          disabled={false}
                                        />
                                      </div>
                                    </div>

                                    <div className="col-lg-6 col-md-12">
                                      <div className="form-group">
                                        <Text
                                          title="Project Therapeutic Area"
                                          name="therapeuticArea"
                                          placeholder="Project Therapeutic Area"
                                          // id="input_name"
                                          errors={errors}
                                          touched={touched}
                                          values={values}
                                          handleChange={handleChange}
                                          handleBlur={handleBlur}
                                          disabled={false}
                                        />
                                      </div>
                                    </div>
                                  </div>

                                  <div className="row">
                                    <div className="col-lg-6 col-md-12">
                                      <div className="form-group required">
                                        <Select
                                          title="Study Status"
                                          name="studyStatus"
                                          placeholder="Study Status"
                                          options={[
                                            {
                                              value: "Start-up",
                                              label: "Start-up",
                                            },
                                            {
                                              value: "Ongoing",
                                              label: "Ongoing",
                                            },
                                            {
                                              value: "Completed",
                                              label: "Completed",
                                            },
                                          ]}
                                          errors={errors}
                                          touched={touched}
                                          values={values}
                                          handleChange={(e) => {
                                            const value = e.target.value;
                                            setFieldValue("studyStatus", value);
                                          }}
                                          handleBlur={handleBlur}
                                          disabled={false}
                                        />
                                      </div>
                                    </div>
                                    <div className="col-lg-6 col-md-12">
                                      <div className="form-group">
                                        <SwitchButton
                                          title="Allow Milestone Payments"
                                          checked={values.milestonePaymentsAllowed}
                                          handleChange={() => {
                                            setFieldValue('milestonePaymentsAllowed', !values.milestonePaymentsAllowed)
                                          }}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                  <div className="row">
                                    <div className="col-lg-12 mt-1">
                                      <h4 onClick={this.openAssignAccountList}>
                                        Assign Sponsor
                                      </h4>
                                    </div>
                                  </div>

                                  <div className="row">
                                    <div className="col-lg-12 mt-1 mb-2">
                                      <a
                                        onClick={this.openAssignAccountList}
                                        class="DefaultButton "
                                      >
                                        Assign Sponsor
                                      </a>
                                    </div>
                                  </div>
                                  <div className="row"></div>
                                  <ul className="ClientDetails">
                                    <li>
                                      <strong>Joik Account ID</strong>
                                      {this.state.selectedAccountObj.joikId}
                                    </li>
                                    <li>
                                      <strong>Account Type</strong>
                                      {
                                        this.state.selectedAccountObj
                                          .accountType
                                      }
                                    </li>
                                    <li>
                                      <strong>Account Name</strong>
                                      {this.state.selectedAccountObj.name}
                                    </li>
                                    <li>
                                      <strong>Address Line 1</strong>
                                      {
                                        this.state.selectedAccountObj.address
                                          .addressLine1
                                      }
                                    </li>
                                    <li>
                                      <strong>Address Line 2</strong>
                                      {
                                        this.state.selectedAccountObj.address
                                          .addressLine2
                                      }
                                    </li>
                                    <li>
                                      <strong>City</strong>
                                      {
                                        this.state.selectedAccountObj.address
                                          .city
                                      }
                                    </li>
                                    <li>
                                      <strong>State</strong>
                                      {
                                        this.state.selectedAccountObj.address
                                          .state
                                      }
                                    </li>
                                    <li>
                                      <strong>Postcode</strong>
                                      {
                                        this.state.selectedAccountObj.address
                                          .postcode
                                      }
                                    </li>
                                    <li>
                                      <strong>Country</strong>
                                      {this.state.selectedAccountObj.address
                                        .countryCode != undefined ? (
                                        <CountryOperation
                                          countryCodeName={
                                            this.state.selectedAccountObj
                                              .address.countryCode
                                          }
                                        />
                                      ) : (
                                        this.state.selectedAccountObj.address
                                          .countryCode
                                      )}
                                    </li>
                                    <li>
                                      <strong>Email ID</strong>
                                      {
                                        this.state.selectedAccountObj
                                          .contactInfo.email
                                      }
                                    </li>
                                    <li>
                                      <strong>Phone</strong>
                                      {
                                        this.state.selectedAccountObj
                                          .contactInfo.landlineNumber
                                      }
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                            {/* </form> */}
                          </div>
                        </div>
                        <div className="col-lg-12  float-left mt-3 mb-3 text-center d-flex justify-content-center">
                          <Link
                            onClick={this.props.toggleModal}
                            className="btn btn-default DefaultButton"
                          >
                            Cancel
                          </Link>
                          <button
                            type="submit"
                            className="btn btn-default DefaultButton AquaButton"
                            style={{ marginLeft: "5px" }}
                          >
                            Save
                          </button>
                        </div>
                      </div>
                    </form>
                  )}
                </Formik>
              )}
            </div>

            {/* <!-- /dashboard content --> */}
          </div>

          <AccountSponsorList
            // item={ITEM}
            type="Sponsor Account"
            modalIsOpen={this.state.accountListModel}
            closeAssignModel={this.closeAssignModel}
            selectedAccountInfo={this.selectedAccount}

          // setParentState={(newState) => this.setState(newState)}
          // deleteId={this.state.deleteId}
          // getListing={this.getListing}
          // api={this.props.deleteMethod}
          // apiName={DELETE_API}
          />

          <Loader loading={this.state.loading} />
        </Modal>
      </>
    );
  }
}
// export default AccountAdd;


const mapStateToProps = state => ({
  // errorMessage: state.auth.errorMessage
});

export default compose(
  connect(mapStateToProps, actions)
)(StudyEdit);