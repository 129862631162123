/*!

=========================================================
* Header
=========================================================

*/
import React from "react";
import { Offline, Online } from "react-detect-offline";
import IdleTimer from "react-idle-timer";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { compose } from "redux";
import * as actions from "../../actions";
import { admin_client_id, admin_cognito_uri, admin_logout_uri, time_out } from '../../utils/constants';
import OutsideAlerter from '../OutsideAlerter';
import IdleTimerPopUp from "./idleTimerPopUp";



class Header extends React.Component {
  constructor(props) {
    super(props);
    this.idleTimer = null;
    this.handleOnAction = this.handleOnAction.bind(this);
    this.handleOnActive = this.handleOnActive.bind(this);
    this.handleOnIdle = this.handleOnIdle.bind(this);

    this.state = {
      isProfileModalOpen: false,
      userObj: JSON.parse(localStorage.getItem("user")),
      ileTimerPopUp: false,
      timeOut: time_out,
    };
  }

  toggleState = (e, key) => {
    console.log(e, "asdlkfjalsdfjladks;jflasdfjak;");
    if (e) e.preventDefault();

    this.setState((prevState, prevProps) => ({
      [key]: !prevState[key],
    }));
  };

  toggleSidebar = (e) => {
    console.log("==========================>here");
    e.preventDefault();
    const body = document.querySelector("body");

    console.log(body.classList, "=============>body before");
    body.classList.toggle("sidebar-xs");
    console.log(body.classList, "=============>body after");
  };

  toggleSidebarMobile = (e) => {
    console.log("==========================>here");
    e.preventDefault();
    const body = document.querySelector("body");
    console.log(body.classList, "=============>body before");
    body.classList.toggle("sidebar-mobile-main");
    console.log(body.classList, "=============>body after");
  };

  componentDidUpdate() {
    console.log(this.state, "============>this.state");
    console.log(this.props, "==========>this.props");
  }

  componentDidMount() {
    if (localStorage.getItem("user") == null) {
      //   window.location = process.env.PUBLIC_URL + "/login";
    }
  }

  logOut = () => {
    localStorage.clear();
    window.location = `${admin_cognito_uri}/logout?client_id=${admin_client_id}&logout_uri=${admin_logout_uri}`;
  };

  handleOnAction(event) {
    console.log("user did something", event);
  }

  handleOnActive(event) {
    console.log("user is active", event);
    //  console.log("time remaining", this.idleTimer.getRemainingTime());
  }

  handleOnIdle = (event) => {

    // if (this.state.ileTimerPopUp) {
    //   this.logOut()
    // } else {
    this.setState(() => ({
      ileTimerPopUp: true
    }));
    //}

    console.log("user is idle", event);
    console.log("last active", this.idleTimer.getLastActiveTime());
  };

  closeIdlePopUp = () => {
    this.setState({
      ileTimerPopUp: false,
    });
  };

  resetIdle = () => {
    this.setState(() => ({
      ileTimerPopUp: false,
      timeOut: time_out,
    }));
    this.idleTimer.start();
  };

  render() {
    return (
      <>
        <div className="navbar navbar-expand-md navbar-dark">
          <div className="navbar-brand wmin-200">
            <a className="d-inline-block">
              <img
                src={`${process.env.PUBLIC_URL}/assets/images/logo.png`}
                alt=""
              />
            </a>
            <IdleTimer
              ref={(ref) => {
                this.idleTimer = ref;
              }}
              timeout={1000 * 60 * this.state.timeOut}
              onActive={this.handleOnActive}
              onIdle={this.handleOnIdle}
              onAction={this.handleOnAction}
              debounce={250}
            />
            <div>
              <Online>Only shown when you're online</Online>
              <Offline>Only shown offline (surprise!)</Offline>
            </div>
          </div>

          <div className="d-md-none">
            <IdleTimerPopUp
              showPopUp={this.state.ileTimerPopUp}
              logOut={this.logOut}
              resetIdle={this.resetIdle}
              closeIdlePopUp={this.closeIdlePopUp}
            ></IdleTimerPopUp>
            <button
              className="navbar-toggler"
              type="button"
              data-toggle="collapse"
              data-target="#navbar-mobile"
            >
              <i className="icon-tree5"></i>
            </button>
            <button
              onClick={(e) => this.toggleSidebarMobile(e)}
              className="navbar-toggler sidebar-mobile-main-toggle"
              type="button"
            >
              <i className="icon-paragraph-justify3"></i>
            </button>
          </div>

          <div className="collapse navbar-collapse" id="navbar-mobile">
            {/* <!--Toggel Btn start--> */}
            <ul className="navbar-nav">
              <li className="nav-item">
                <Link
                  onClick={(e) => this.toggleSidebar(e)}
                  className="navbar-nav-link sidebar-control sidebar-main-toggle d-none d-md-block"
                >
                  <i className="icon-paragraph-justify3"></i>
                </Link>
              </li>
              {/* <li className="nav-item">
                <form className="NavbarSrch">
                  <div className="form-group">
                    <div className="IconWithSearch">
                      <i className="icon-search4"></i>
                      <input type="text" className="form-control" placeholder="Search" />
                    </div>
                    <button type="submit" className="DefaultButton AquaButton">Search</button>
                  </div>
                </form>
              </li> */}
            </ul>
            {/* <!--Toggel Btn end--> */}

            {/* <!--navbar profile start--> */}
            <ul className="navbar-nav  ml-md-auto mr-md-3">
              <li className="nav-item dropdown">
                {/* <Link
                  className="navbar-nav-link dropdown-toggle caret-0"
                  data-toggle="dropdown"
                >
                  <i className="icon-bubbles5"></i>
                  <span className="d-md-none ml-2">Messages</span>
                  <span className="badge badge-pill badge-mark bg-orange-400 border-orange-400 ml-auto ml-md-0"></span>
                </Link> */}

                <div className="dropdown-menu dropdown-menu-right dropdown-content wmin-md-350 DropdownDesign">
                  <div className="dropdown-content-header">
                    <span className="font-weight-semibold">Messages</span>
                    <i className="icon-compose"></i>
                  </div>

                  <div className="dropdown-content-body dropdown-scrollable">
                    <ul className="media-list">
                      <li className="media">
                        <div className="mr-3 position-relative">
                          <img
                            src="assets/images/placeholders/placeholder.jpg"
                            width="36"
                            height="36"
                            className="rounded-circle"
                            alt=""
                          />
                        </div>

                        <div className="media-body">
                          <div className="media-title">
                            <Link>
                              <span className="font-weight-semibold">
                                James Alexander
                              </span>
                              <span className="text-muted float-right font-size-sm">
                                04:58
                              </span>
                            </Link>
                          </div>

                          <span className="text-muted">
                            who knows, maybe that would be the best thing for
                            me...
                          </span>
                        </div>
                      </li>

                      <li className="media">
                        <div className="mr-3 position-relative">
                          <img
                            src="assets/images/placeholders/placeholder.jpg"
                            width="36"
                            height="36"
                            className="rounded-circle"
                            alt=""
                          />
                        </div>

                        <div className="media-body">
                          <div className="media-title">
                            <Link>
                              <span className="font-weight-semibold">
                                Margo Baker
                              </span>
                              <span className="text-muted float-right font-size-sm">
                                12:16
                              </span>
                            </Link>
                          </div>

                          <span className="text-muted">
                            That was something he was unable to do because...
                          </span>
                        </div>
                      </li>
                    </ul>
                  </div>

                  <div className="dropdown-content-footer justify-content-center p-0">
                    <Link
                      className="text-grey w-100 py-2"
                      data-popup="tooltip"
                      title="Load more"
                    >
                      <i className="icon-menu7 d-block top-0"></i>
                    </Link>
                  </div>
                </div>
              </li>
              <li className="nav-item dropdown NotificationSection">
                {/* <Link
                  className="navbar-nav-link dropdown-toggle caret-0"
                  data-toggle="dropdown"
                >
                  <i className="icon-bell2"></i>
                  <span className="d-md-none ml-2">Activity</span>
                  <span className="badge badge-pill badge-mark bg-orange-400 border-orange-400 ml-auto ml-md-0"></span>
                </Link> */}

                <div className="dropdown-menu dropdown-content wmin-md-350 DropdownDesign">
                  <div className="dropdown-content-header">
                    <span className="font-weight-semibold">
                      Latest activity
                    </span>
                  </div>

                  <div className="dropdown-content-body dropdown-scrollable">
                    <ul className="media-list">
                      <li className="media">
                        <div className="mr-3">
                          <Link className="btn bg-success-400 rounded-round btn-icon">
                            <i className="icon-mention"></i>
                          </Link>
                        </div>

                        <div className="media-body">
                          <Link>Taylor Swift</Link> mentioned you in a post
                          "Angular JS. Tips and tricks"
                          <div className="font-size-sm text-muted mt-1">
                            4 minutes ago
                          </div>
                        </div>
                      </li>

                      <li className="media">
                        <div className="mr-3">
                          <Link className="btn bg-pink-400 rounded-round btn-icon">
                            <i className="icon-paperplane"></i>
                          </Link>
                        </div>

                        <div className="media-body">
                          Special offers have been sent to subscribed users by{" "}
                          <Link>Donna Gordon</Link>
                          <div className="font-size-sm text-muted mt-1">
                            36 minutes ago
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>

                  <div className="dropdown-content-footer ">
                    <Link className="text-grey mr-auto">All activity</Link>
                    <div>
                      <Link
                        className="text-grey"
                        data-popup="tooltip"
                        title="Clear list"
                      >
                        <i className="icon-eraser2"></i>
                      </Link>
                    </div>
                  </div>
                </div>
              </li>
              <li className="nav-item dropdown dropdown-user ProfileNavbar">
                <Link
                  onClick={(e) => this.toggleState(e, "isProfileModalOpen")}
                  className="navbar-nav-link d-flex align-items-center dropdown-toggle"
                  data-toggle="dropdown"
                >
                  <img
                    src="assets/images/user_placeholder.png"
                    className="rounded-circle mr-2"
                    height="34"
                    alt=""
                  />
                  <span>
                    {this.state.userObj.given_name}{" "}
                    {this.state.userObj.family_name}
                  </span>
                </Link>

                {this.state.isProfileModalOpen && (
                  <OutsideAlerter
                    toggle={(e) => this.toggleState(e, "isProfileModalOpen")}
                  >
                    <div className="dropdown-menu dropdown-menu-right show">
                      {/* <Link className="dropdown-item">
                        <i className="icon-user-plus"></i> My profile
                      </Link> */}
                      <Link className="dropdown-item" onClick={this.logOut}>
                        <i className="icon-switch2"></i> Logout
                      </Link>
                    </div>
                  </OutsideAlerter>
                )}
              </li>
            </ul>
            {/* <!--navbar profile start--> */}
          </div>
          {/* <!-- /main navbar --> */}
        </div>
      </>
    );
  }
}

// export default Header;

const mapStateToProps = (state) => ({
  authenticated: state.auth.authenticated,
  user: state.auth.user,
});

export default compose(connect(mapStateToProps, actions))(Header);
