import React, { useState, Fragment } from 'react';
import Modal from "react-bootstrap/Modal";
import { toast } from 'react-toastify';

const DeleteAlert = (props) => {
    const onHide = () => {
        props.setParentState(() => ({
            confirmDelete: false,
            loading: false,
        }));
    }
  
     const handleDeleteItem = () => {
        onHide();
        props.setParentState(() => ({
            loading: true,
        }));

          props.api({ url: `${props.apiName}/${props.deleteId}` }, async (response) => {
            const updateState = async () => {
                props.setParentState(() => ({
                    confirmDelete: false,
                    deleteId: null
                }));
                toast(`${props.item} deleted successfully.`,{type:'success'});
            }

            await updateState();
            props.rerouteToList();
        });
         setTimeout(() => {
            props.setParentState(() => ({
                loading: false,
            }));

         }, 1000);

        
    }
    
    
    return (
         <>
             <Modal
                show={props.modalIsOpen}
                onHide={onHide}
                animation={false}
                className={"CustomModal"}
                style={{marginTop: '10%'}}
            >
                <div className="modal-content">
                    <div className="modal-header">
                        <h4 className="modal-title">Delete {props.item} </h4>
                        {/* <button type="button" className="close" data-dismiss="modal">&times;</button> */}
                    </div>
                    <div className="modal-body modalpadding text-center">
                        Are you sure you want to delete this {props.item}?
                                </div>
                    <div className="modal-footer text-center">
                        <button type="button" className="btn btn-default DefaultButton" onClick={onHide}>Cancel</button>
                        <button type="button" className="btn btn-default DefaultButton AquaButton" onClick={handleDeleteItem}>Delete</button>
                    </div>
                </div>
            </Modal>
         </>

    )
}

export default DeleteAlert;