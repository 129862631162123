import React, { Component, Fragment } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import * as actions from '../../../actions';
import SiteContactList from './SiteContactList';
import SiteContactDetails from './SiteContactDetails'



class SiteContact extends Component {

    constructor(props) {

        super(props);

        this.state = {            
            showDetails:false,
            details:{}
          };

    }    

    loadSiteDeatils=(site)=>{
     this.setState({
        showDetails:true,
        details:site,
     })
 
    
   }

   hideDetails = ()=>{

    this.setState({
      showDetails:false,
    
     })

        
 }

  loadSiteDeatils = (site) => {
      
      this.setState({
          showDetails:true,
          details:site,
 
      }) 
     
    }
   
    render() { 
        return (
            <div className="col-lg-12 mt-3">
               { this.state.showDetails== false ? <SiteContactList 
                studyId={this.props.id} 
                hideDetails = {this.hideDetails}
                loadSiteDeatils={this.loadSiteDeatils}
                ></SiteContactList>:<SiteContactDetails 
               siteData={this.state.details}
               hideDetails = {this.hideDetails}
                >
               </SiteContactDetails>}
            </div> );
    }

}


const mapStateToProps = state => ({
	// errorMessage: state.auth.errorMessage
  });
  
  export default compose(
	connect(mapStateToProps, actions)
  )(SiteContact);