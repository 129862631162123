import React, { Component, Fragment } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import * as actions from '../../../actions';
import MileStonePaymentList from './MileStonePaymentList'
import MileStonePaymentDetails from './MileStonePaymentDetails'

class MileStonePayment  extends Component {
  constructor(props) {

    super(props);
    this.state = {            
      showDetails:false,
      details:{}
    };
  }   


  hideDetails = () => {
    this.setState({
      showDetails:false,
    
     })
  }
   
  loadSiteDeatils=(site)=>{
      this.setState({
        showDetails:true,
        details:site,
     })

    }

    render() { 
        return (
          <div className="col-lg-12 mt-3">
          { this.state.showDetails== false ? <MileStonePaymentList 
           studyId={this.props.id} 
           loadSiteDeatils={this.loadSiteDeatils}
           ></MileStonePaymentList>:<MileStonePaymentDetails 
          siteData={this.state.details}
          hideDetails = {this.hideDetails}
           >
          </MileStonePaymentDetails>}
       </div> );
          
         
    }
}
 
const mapStateToProps = state => ({
	// errorMessage: state.auth.errorMessage
  });
  
  export default compose(
	connect(mapStateToProps, actions)
  )(MileStonePayment);