import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import * as actions from '../../actions';
import DeleteModal from '../../components/DeleteModal';
import Loader from '../../components/Loader';
import { BASE_URL as SERVER_URL } from '../../utils/constants';
import Edit from './Edit';

const ITEM = 'Payment';
const DELETE_API = 'payment/deactivate';
const MODULE_BASE_ROUTE = 'Payment';

class Details extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      site: {
        subject: { site: { account: {} } },
        milestone: {},
        attachmentDetails: [{}],
      },
      isEditModal: false,
      accountName: "",
    };
  }
  rerouteToList = () => {
    this.props.hideDetails();
  };

  handleConfirmDelete = (e, id) => {
    e.preventDefault();
    this.setState((prevState, props) => ({
      deleteId: id,
      confirmDelete: true,
      isRowMenu: false,
    }));
  };

  toggleState = (key) => {
    this.setState((prevState, props) => ({
      [key]: !prevState[key],
    }));
  };

  componentDidMount = () => {
    this.getDetails();
  };

  getDetails = () => {
    const { id } = this.state;
    this.setState(() => ({
      loading: true,
    }));
    this.props.get(
      {
        url: `payment/${String(window.location).split("payment-detail/")[1]}`,
        errorUrl: `/${MODULE_BASE_ROUTE}`,
        history: this.props.history,
      },
      async (response) => {
        this.setState(() => ({
          site: response,
          responseFetched: true,
          loading: false,
        }));
      }
    );
  };

  downloadItem = (item) => {
    this.props.getFile(
      {
        url: `${SERVER_URL}/payment/attachment/${item.id}`,
        fileName: item.name,
        fileType: item.contentType,
      },
      async (response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", item.fileName);

        // Append to html link element page
        document.body.appendChild(link);

        // Start download
        link.click();

        // Clean up and remove the link
        link.parentNode.removeChild(link);
      }
    );
  };

  render() {
    const { site } = this.state;

    return (
      <div>
        <div class="RowDesign">
          <div class="col-lg-12 float-left mb-3 text-left">
            <h3 class="mb-0 HeadingData">Payment Details</h3>
          </div>
          <div class="col-lg-12  float-left">
            <ul class="ClientDetails">
              <li>
                <strong>Account name</strong>
                {site.subject.site.account.name}
              </li>
            </ul>
          </div>
          <div class="col-lg-12  float-left">
            <ul class="ClientDetails">
              <li>
                <strong>First Name</strong>
                {site.subject.firstName}
              </li>
              <li>
                <strong>Last Name</strong>
                {site.subject.lastName}
              </li>
              <li>
                <strong>Subject Number</strong>
                {site.subject.subjectNumber}
              </li>
            </ul>
          </div>

          <div class="col-lg-12  float-left">
            <ul class="ClientDetails">
              <li>
                <strong>Payment Type</strong>
                {site.type}
              </li>

              <li>
                <strong>Milestone</strong>
                {site.milestone.name}
              </li>
              <li>
                <strong>Visit Date</strong>
                {site.visitDate}
              </li>
              <li>
                <strong>Payment Amount</strong>
                {Number(site.amount).toFixed(2)} {site.currency}
              </li>
              {site.type == "Reimbursement" ? (
                <li>
                  <strong>Expense type</strong>
                  {site.category}
                </li>
              ) : (
                ""
              )}
              {site.categoryDescription !== null &&
              site.type == "Reimbursement" ? (
                <li>
                  <strong>Expense description</strong>
                  {site.categoryDescription}
                </li>
              ) : (
                ""
              )}
              {site.attachmentDetails.length > 0 ? (
                <li class="attachfileviewLI">
                  <strong>Attachment(s)</strong>
                  <span>
                    {site.attachmentDetails.map((item) => (
                      <a
                        // href={`${SERVER_URL}/payment/attachment/${item.id}`}
                        // className=""
                        href="javascript:void(0)"
                        onClick={() => this.downloadItem(item)}
                      >
                        {item.fileName} ({item.size})
                      </a>
                    ))}
                  </span>
                </li>
              ) : null}

              <li>
                <strong>Payment Status</strong>
                {site.paymentStatus}
              </li>

              {site.paymentStatusComment ? (
                <li>
                  <strong>Payment Status Comment</strong>
                  {site.paymentStatusComment}
                </li>
              ) : (
                ""
              )}
            </ul>
          </div>
          <div class="col-lg-12  float-left mt-3 mb-3 text-center AllFooterButton d-flex justify-content-center">
            <a
              href={`${process.env.PUBLIC_URL}/payment`}
              class="DefaultButton AquaButton"
            >
              Back
            </a>
          </div>
        </div>

        <DeleteModal
          item={ITEM}
          modalIsOpen={this.state.confirmDelete}
          setParentState={(newState) => this.setState(newState)}
          deleteId={this.state.deleteId}
          rerouteToList={this.rerouteToList}
          api={this.props.deleteMethod}
          apiName={DELETE_API}
        />
        <Edit
          item={ITEM}
          accountName={this.state.accountName}
          id={site.id}
          detail={this.state.detail}
          setParentState={(newState) => this.setState(newState)}
          isEditModal={this.state.isEditModal}
          reloadList={this.getDetails}
          toggleModal={() => this.toggleState("isEditModal")}
        ></Edit>
        <Loader loading={this.state.loading} />
      </div>
    );
  }
}


const mapStateToProps = state => ({
});

export default compose(
  connect(mapStateToProps, actions)
)(Details);