import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import * as actions from '../../../actions';
import { Formik } from "formik";

import { toast } from 'react-toastify';
import Modal from "react-bootstrap/Modal";

import SponsorList from "../../Common/SponsorList"
import Loader from '../../../components/Loader';
import Text from "../../../components/form/Text";

const MODULE_NAME = 'Milestone';

const INITIAL_VALUES = {
  accountType: "",
  mileStoneNumber:'',
  milestoneName:"",
};

class AddMileStone extends Component {
  constructor(props) {
    super(props);
    this.state = {
      detail: INITIAL_VALUES,
      responseFetched: false,
      loading: false,
    };
    
  }

  validate = (values) => {
    let errors = {};

    if (!values.mileStoneNumber) {
      errors.mileStoneNumber = "Milestone Number is required.";

    }

    if(!values.milestoneName){
      errors.milestoneName = "Milestone is required.";
    }
     
    
    return errors;
  };

  handleSubmit = async (values, { setSubmitting, resetForm }) => {
    this.setState(() => ({
      loading: true,
    }));

    const modifiedValues = {
      // "id": this.state.intialDataWithIds.id,
      "name":values.milestoneName,
      "study": {
        "id":String(window.location).split("Study-detail/")[1],
      } ,
      "sortNumber":values.mileStoneNumber,
    };


    await this.props.apiJSON({ url: `milestone`, method: 'POST', data: modifiedValues }, () => {
      setTimeout(() => {
        setSubmitting(false);
        toast(`${MODULE_NAME} added successfully.`,{type:'success'});
        this.props.setParentState(() => ({
          isAddModal: false,
          loading: false,
        }));      
        this.props.reloadList();

      }, 1500);
      console.log(this.props);
    });
  
    setTimeout(() => {
      this.setState(() => ({
        loading: false,
      }));
    }, 2000);        
  }

  onEscapeKeyDown = (e) => {
    e.preventDefault();
  };



  render=()=>{
    let {
      detail
    } = this.state;
  

    return (
      <>
        <Modal
          show={this.props.modalIsOpen}
          onHide={this.props.toggleModal}
          animation={false}
          className={"CustomModal width900"}
          backdrop={false}
          onEscapeKeyDown={this.onEscapeKeyDown}
        >
          <div className="content">
            <div className="row">
              {Object.keys(detail).length > 0 && (
                <Formik
                  initialValues={this.state.detail}
                  validate={this.validate}
                  onSubmit={this.handleSubmit}
                  enableReinitialize
                >
                  {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    setFieldValue,
                    isSubmitting,
                  }) => (
                    <form
                      onSubmit={handleSubmit}
                      autoComplete="off"
                      className="float-left FormEditDesign"
                    >
                      <div className="modal-header">
                        <h4 className="modal-title">Add Milestone</h4>
                        <button
                          type="button"
                          onClick={this.props.toggleModal}
                          className="close"
                        >
                          &times;
                        </button>
                      </div>
                      <div className="col-md-12">
                        <div className="col-lg-12  float-left">
                          <div className="modal-body modalpadding">
                            <div className="row">
                              <div className="col-lg-12">
                                <h4>Enter Milestone Details</h4>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-lg-6 col-md-12">
                                <div className="form-group required">
                                  <Text
                                    title="Milestone Number"
                                    name="mileStoneNumber"
                                    type="number"
                                    placeholder="Milestone Number"
                                    // id="input_name"
                                    errors={errors}
                                    touched={touched}
                                    values={values}
                                    handleChange={(e) => {
                                      if (
                                        e.target.value > 0 &&
                                        (e.target.value).length<6
                                      ) {
                                         setFieldValue(
                                          "mileStoneNumber",
                                          parseInt(e.target.value)
                                        );
                                      } else {
                                        setFieldValue("sortNumber", "");
                                      }

                                      // setFieldValue('mileStoneNumber', parseInt(e.target.value))
                                    }}
                                    handleBlur={handleBlur}
                                    disabled={false}
                                   
                                  />
                                </div>
                              </div>
                              <div className="col-lg-6 col-md-12">
                                <div className="form-group required">
                                  <Text
                                    title="Milestone"
                                    name="milestoneName"
                                    placeholder="Milestone"
                                    // id="input_name"
                                    errors={errors}
                                    touched={touched}
                                    values={values}
                                    handleChange={handleChange}
                                    handleBlur={handleBlur}
                                    disabled={false}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-12  float-left mt-3 mb-3 text-center d-flex justify-content-center">
                          <a
                            onClick={this.props.toggleModal}
                            className="btn btn-default DefaultButton"
                          >
                            Cancel
                          </a>
                          <button
                            type="submit"
                            className="btn btn-default DefaultButton AquaButton"
                            style={{ marginLeft: "5px" }}
                          >
                            Save
                          </button>
                        </div>
                      </div>
                    </form>
                  )}
                </Formik>
              )}
            </div>
          </div>

          <Loader loading={this.state.loading} />
        </Modal>
      </>
    );
  }
}

const mapStateToProps = state => ({
  // errorMessage: state.auth.errorMessage
});

export default compose(
  connect(mapStateToProps, actions)
)(AddMileStone);