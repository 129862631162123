/*!

=========================================================
* TextField
=========================================================

*/
import React from "react";

// reactstrap components
import {
  FormGroup,
  Input,
} from "reactstrap";

class TextArea extends React.Component {
    constructor(props) {
        super(props);
    }
    
    render() {
      const { title, name, id, placeholder = '', errors, touched, values, handleChange, handleBlur, ...rest } = this.props;
        return (
          <>
            <label
              className="form-control-label"
              htmlFor={id}
            >
              {title}
            </label>
            <FormGroup className={`${touched[name] && !errors[name] ? 'has-success' : touched[name] && errors[name] ? 'has-danger' : ''}`} >
              <Input
                className={`${touched[name] && !errors[name] ? 'is-valid' : touched[name] && errors[name] ? 'is-invalid' : ''}`}
                id={id}
                name={name}
                value={values[name]}
                onChange={handleChange}
                onBlur={handleBlur}
                placeholder={placeholder}
                rows="4"
                type="textarea"
                {...rest}
              />
              {errors[name] && touched[name] && 
                <span className="error">{errors[name]}</span>
              }
            </FormGroup>
          </>
        );
    }
}

export default TextArea;
