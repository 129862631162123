/*!

=========================================================
* TextField
=========================================================

*/
import React from "react";

// reactstrap components
import {
  FormGroup,
  Input,
} from "reactstrap";

class Select extends React.Component {
    constructor(props) {
        super(props);
    }
    
    render() {
      const { title, options, name, id, placeholder = '', errors, touched, values, handleChange, handleBlur, ...rest } = this.props;
        return (
          <>
            <label
              className="form-control-label"
              htmlFor={id}
            >
              {title}
            </label>
            <FormGroup className={`${touched[name] && !errors[name] ? 'has-success' : touched[name] && errors[name] ? 'has-danger' : ''}`} >
              {/* <Input
                className={`${touched[name] && !errors[name] ? 'is-valid' : touched[name] && errors[name] ? 'is-invalid' : ''}`}
                id={id}
                name={name}
                value={values[name]}
                onChange={handleChange}
                onBlur={handleBlur}
                placeholder={placeholder}
                type="select"
                {...rest}
              /> */}
              <select
                className={`form-control ${touched[name] && !errors[name] ? 'is-valid' : touched[name] && errors[name] ? 'is-invalid' : ''}`}
                id={id}
                name={name}
                value={values[name]}
                onChange={handleChange}
                onBlur={handleBlur}
                style={{ display: 'block' }}
                {...rest}
              >
                <option value="" label={placeholder} />
                {
                  Array.isArray(options) && options.length > 0 && options.map((option, index) => {
                    return (
                      <option value={option.value} label={option.label} key={`option${index}`} />
                    )
                  })
                }
              </select>
              {errors[name] && touched[name] && 
                <span className="error">{errors[name]}</span>
              }
            </FormGroup>
          </>
        );
    }
}

export default Select;
