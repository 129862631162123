import { AUTH_USER, AUTH_SIGININ_UP, AUTH_SIGNOUT, AUTH_ERROR, FETCH_USER, AUTH_SIGININ_UP_COMPLETE } from '../actions/types';

const INITIAL_STATE = {
    authenticated: '',
    user: {},
    errorMessage: '',
}

const authReducer = (state = INITIAL_STATE, action) => {    
    switch (action.type) {
        case AUTH_USER:
            return {
                ...state,
                authenticated: action.payload.token,
                user: action.payload,
                errorMessage: '',
            };
        case AUTH_SIGNOUT:
            return {
                authenticated: '',
                errorMessage: '',
                user: action.payload,
                cards: [],
                senderAddresses: [],
                recipientAddresses: [],
            };
        case AUTH_SIGININ_UP:
            return {
                ...state,
                preauthenticated: action.payload,
                errorMessage: ''
            };
        case AUTH_ERROR:
            return {
                ...state,
                errorMessage: action.payload
            }
        case FETCH_USER:
            return {
                ...state,
                user: action.payload
            }
        case AUTH_SIGININ_UP_COMPLETE:
            return {
                ...state,
                authenticated: action.payload.authorization_key,
                user: action.payload
            }
        default:
            return state;
    }
}

export { authReducer as default };