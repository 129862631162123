import axios from "axios";
import { Formik } from "formik";
import React, { Component } from 'react';
import Modal from "react-bootstrap/Modal";
import { connect } from 'react-redux';
import { toast } from "react-toastify";
import { compose } from 'redux';
import * as actions from '../../actions';
import Select from "../../components/form/Select";
import Textarea from '../../components/form/TextArea';
import Loader from '../../components/Loader';
import { BASE_URL as SERVER_URL } from "../../utils/constants";

const MODULE_NAME = 'Ticket';
const INITIAL_VALUES = {
  "projectNumber": "",
  "siteNumber": "",
  "subjectNumber": "",
  "category": "",
  "commentText": "",
  "ticketStatus": "",
   "file":'',
};

class Add extends Component {
  constructor(props) {
    super(props);
    this.state = {
      detail: INITIAL_VALUES,
      responseFetched: false,
      loading: false,
      studies: [],
      site: [],
      subjects: [],
      files: "a",
      fileSize: 0,
      fileError: "",
      fileNames: [],
    };
  }
  validate = (values) => {
    let errors = {};

    if (!values.projectNumber) {
      errors.projectNumber = "Project Number is required";
    }

    if (!values.siteNumber) {
      errors.siteNumber = "Site Number is required";
    }

  

    if (!values.commentText) {
      errors.commentText = "Comments  is required";
    }

    if (!values.category) {
      errors.category = "Ticket Category is required";
    }
    
    if (!values.ticketStatus) {
       errors.ticketStatus = "Ticket Status is required";

    }
      
      console.log(errors,"final value")
      return errors;
  };

  handleSubmit = async (values, { setSubmitting, resetForm }) => {
    this.setState(() => ({
      loading: true,
    }));
   let userObj = JSON.parse(localStorage.getItem("user"));
  
    var modifiedValues = {
      ticketStatus: values.ticketStatus,
      category: values.category,
      site: { id: values.siteNumber },
      subjectNumber: values.subjectNumber,
      userType: "Admin",
      username: localStorage.getItem("userEmail"),

      comments: [
        {
          commentText: values.commentText,
          username: localStorage.getItem("userEmail"),
          userFullName: userObj.given_name+" "+userObj.family_name,
          userType: "Admin",
        },
      ],
    };

    
    if (!values.subjectNumber) {
      delete modifiedValues.subjectNumber;
    }
   
    try {
      var formData = new FormData();

      formData.append("ticket-json", JSON.stringify(modifiedValues));

      if (this.state.fileSize > 0) {
    
        for (var i = 0; i < this.state.attachement.length; i++) {
          formData.append("attachment ", this.state.attachement[i]);
        }
      }

      const response = await axios({
        method: "POST",
        url: `${SERVER_URL}/ticket`,
        processData: false,
        cache: false,
        data: formData,
        headers: {
          "content-type": "multipart/form-data",
          "authorization-token":localStorage.getItem("id_token")
        },
      });

      if (response) {
        setTimeout(() => {
          setSubmitting(false);
          toast(`${MODULE_NAME} added successfully.`, { type: "success" });
          this.props.setParentState(() => ({
            isAddModal: false,
            loading: false,
          }));
          this.props.reloadList();
        }, 1500);
      }
    } catch (error) {

       this.setState(() => ({
         loading: false,
       }));

      
     
      if (error.response === undefined) {
        toast(
          "An unexpected error occurred. Please try again. If the error persists, please inform the helpdesk",
          { type: "error" }
        );
      } else if (error.response.status == "422") {
        toast(error.response.data[0].message, { type: "error" });
      } else {
        toast(error["message"], { type: "error" });
      }
    
    };

  }

  resetModelData=()=>{
  

     this.setState({

      fileNames:[],
      fileSize: 0,
     })

  }
  
    


  onEscapeKeyDown = (e) => {
    e.preventDefault();
  };

  componentDidMount = () => {
    this.getStudyNumber();
  };

  getSiteList = (id) => {
    this.setState(() => ({
      loading: true,
    }));

    this.setState({
      site: [],
      subjects:[]
    });

    this.props.get(
      {
        url: `site/list/study/${id}`,
      },
      async (response) => {
        console.log(response, "site list");
        let arr = [];
        for (var i = 0; i < response.length; i++) {
          let obj = {};
          obj.value = response[i][1]
          obj.label = response[i][0];
          arr.push(obj);
        }
        
        this.setState(() => ({
          site: arr,
          loading: false,
        }));
      }
    );
  };

  getStudyNumber = () => {

    this.props.get(
      {
        url: `study/list/study-numbers`,
      },
      async (response) => {
        let studyArr = [];

        for (var i = 0; i < response.length; i++) {
          let obj = {};
          obj.value = response[i][1];
          obj.label = response[i][0];
          studyArr.push(obj);
        }

        this.setState(() => ({
          studies: studyArr,
        }));
      }
    );
  };

  getSubjectList = (id) => {


    this.setState({
      subjects: [],
    });

    this.props.get(
      {
        url: `subject/list/subject-numbers/site/${id}`,
      },
      async (response) => {
        let arr = [];

        for (var i = 0; i < response.length; i++) {
          let obj = {};
          obj.value = response[i][0];
          obj.label = response[i][0];
          arr.push(obj);
        }

        this.setState(() => ({
          subjects: arr,
        }));
      }
    );
    

  }

  render = () => {
    let { detail } = this.state;

    return (
      <>
        <Modal
          show={this.props.modalIsOpen}
          onHide={this.props.toggleModal}
          animation={false}
          onShow={this.resetModelData}
          className={"CustomModal width900"}
          backdrop={false}
          onEscapeKeyDown={this.onEscapeKeyDown}
        >
          <div className="content">
            <div className="row">
              {Object.keys(detail).length > 0 && (
                <Formik
                  initialValues={this.state.detail}
                  validate={this.validate}
                  onSubmit={this.handleSubmit}
                  enableReinitialize
                >
                  {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    setFieldValue,
                    isSubmitting,
                  }) => (
                    <form
                      onSubmit={handleSubmit}
                      autoComplete="off"
                      className="float-left FormEditDesign"
                    >
                      <div className="modal-header">
                        <h4 className="modal-title">Add Ticket</h4>
                        <button
                          type="button"
                          onClick={this.props.toggleModal}
                          className="close"
                        >
                          &times;
                        </button>
                      </div>
                      <div className="col-md-12">
                        <div className="col-lg-12  float-left">
                          <div className="modal-body modalpadding">
                            <div className="row">
                              <div className="col-lg-12">
                                <h4>Enter Ticket details</h4>
                              </div>
                            </div>
                            <div className="col-lg-12">
                              <div className="row">
                                {/* <!--First--> */}
                                <div className="col-md-12">
                                  <div className="row"></div>
                                  <div className="row">
                                    <div className="col-lg-6 col-md-6">
                                      <div className="form-group required">
                                        <Select
                                          title="Project Number"
                                          name="projectNumber"
                                          placeholder="Select Project Number"
                                          options={this.state.studies}
                                          id="projectNumber"
                                          errors={errors}
                                          touched={touched}
                                          values={values}
                                          handleChange={(e) => {
                                            const value = e.target.value;
                                            if (value !== "") {
                                              this.getSiteList(value);
                                              setFieldValue(
                                                "projectNumber",
                                                value
                                              );
                                            }
                                          }}
                                          handleBlur={handleBlur}
                                          disabled={false}
                                        />
                                      </div>
                                    </div>

                                    <div className="col-lg-6 col-md-6">
                                      <div className="form-group required">
                                        <Select
                                          title="Site Number"
                                          name="siteNumber"
                                          placeholder="Select Site Number"
                                          options={this.state.site}
                                          id="siteNumber"
                                          errors={errors}
                                          touched={touched}
                                          values={values}
                                          handleChange={(e) => {
                                            const value = e.target.value;
                                            if (value !== "") {
                                              const value = e.target.value;
                                              setFieldValue(
                                                "siteNumber",
                                                value
                                              );
                                              this.getSubjectList(value);
                                            }
                                          }}
                                          handleBlur={handleBlur}
                                          disabled={false}
                                        />
                                      </div>
                                    </div>
                                  </div>

                                  <div className="row">
                                    <div className="col-lg-6 col-md-12">
                                      <div className="form-group">
                                        <Select
                                          title="Subject  Number"
                                          name="subjectNumber"
                                          placeholder="Subject Number"
                                          options={this.state.subjects}
                                          errors={errors}
                                          touched={touched}
                                          values={values}
                                          handleChange={(e) => {
                                            const value = e.target.value;
                                            if (value !== "") {
                                              setFieldValue(
                                                "subjectNumber",
                                                value
                                              );
                                            }
                                          }}
                                          handleBlur={handleBlur}
                                          disabled={false}
                                        />
                                      </div>
                                    </div>
                                    <div className="col-lg-6 col-md-12">
                                      <div className="form-group required">
                                        <Select
                                          title="Ticket Category"
                                          name="category"
                                          placeholder="Select Ticket Category"
                                          options={[
                                            {
                                              value: "Subject",
                                              label: "Subject",
                                            },
                                            {
                                              value: "Joik Card",
                                              label: "Joik Card",
                                            },
                                            {
                                              value: "Payment",
                                              label: "Payment",
                                            },
                                          ]}
                                          id="category"
                                          errors={errors}
                                          touched={touched}
                                          values={values}
                                          handleChange={(e) => {
                                            const value = e.target.value;
                                            setFieldValue("category", value);
                                          }}
                                          handleBlur={handleBlur}
                                          disabled={false}
                                        />
                                      </div>
                                    </div>
                                  </div>

                                  <div className="row">
                                    <div className="col-lg-12 col-md-12">
                                      <div className="form-group required">
                                        <Textarea
                                          title="Description"
                                          name="commentText"
                                          placeholder="Description"
                                          errors={errors}
                                          touched={touched}
                                          values={values}
                                          handleChange={handleChange}
                                          handleBlur={handleBlur}
                                          disabled={false}
                                        />
                                      </div>
                                    </div>
                                  </div>

                                  <div className="row">
                                    <div className="col-lg-12 col-md-12">
                                      <div className="form-group required">
                                        <Select
                                          title="Action"
                                          name="ticketStatus"
                                          placeholder="Select Action"
                                          options={[
                                            {
                                              value: "Open to Site",
                                              label: "Open to Site",
                                            },
                                            {
                                              value: "Open to Sponsor",
                                              label: "Open to Sponsor",
                                            },
                                          ]}
                                          id="ticketStatus"
                                          errors={errors}
                                          touched={touched}
                                          values={values}
                                          handleChange={(e) => {
                                            const value = e.target.value;
                                            this.setState({
                                              paymentType: e.target.value,
                                            });
                                            setFieldValue(
                                              "ticketStatus",
                                              value
                                            );
                                          }}
                                          handleBlur={handleBlur}
                                          disabled={false}
                                        />
                                      </div>
                                    </div>
                                  </div>

                                  <div className="row">
                                    <div className="col-lg-12 col-md-12">
                                      <div className="form-group required">
                                        <small className="info">
                                          Multiple Files can be attached. Max
                                          file size: 5 MB
                                        </small>
                                        <div class="form-group FileuploadLabel">
                                          <div class="FileUploadDesign">
                                            <input
                                              type="file"
                                              class="form-control"
                                              placeholder=""
                                              id="fileupload"
                                              name="fileSizeLimit"
                                              errors={errors}
                                              touched={touched}
                                              handleChange={handleChange}
                                              onChange={(e) => {
                                                this.setState({
                                                  attachement: "",
                                                });

                                                var allFiles = [];

                                                this.setState(
                                                  {
                                                    attachement: e.target.files,
                                                  },
                                                  () => {
                                                    var totalSize = 0;
                                                    for (
                                                      var i = 0;
                                                      i <
                                                      this.state.attachement
                                                        .length;
                                                      i++
                                                    ) {
                                                      console.log(
                                                        this.state.attachement[
                                                          i
                                                        ].size
                                                      );
                                                      totalSize += this.state
                                                        .attachement[i].size;
                                                      allFiles.push(
                                                        this.state.attachement[
                                                          i
                                                        ].name
                                                      );
                                                    }
                                                    totalSize =
                                                      totalSize / 1024 / 1024;
                                                    if (totalSize > 5) {
                                                      this.setState({
                                                        fileError:
                                                          "Attachement size should be less than 5 MB",
                                                        fileSize: totalSize,
                                                      });
                                                    } else {
                                                      this.setState({
                                                        fileError: "",
                                                        fileSize: totalSize,
                                                        fileNames: [
                                                          ...allFiles,
                                                        ],
                                                      });
                                                    }
                                                  }
                                                );
                                              }}
                                              multiple
                                            />
                                            <label
                                              htmlFor="fileupload"
                                              class="DefaultButton AquaButton"
                                            >
                                              Upload File
                                            </label>
                                            <small className="error">
                                              {this.state.fileError}
                                            </small>
                                          </div>
                                          <ul class="UploadedFile">
                                            {this.state.fileNames.map(
                                              (item) => (
                                                <li>{item}</li>
                                              )
                                            )}
                                          </ul>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                            {/* </form> */}
                          </div>
                        </div>
                        <div className="col-lg-12  float-left mt-3 mb-3 text-center d-flex justify-content-center">
                          <a
                            onClick={this.props.toggleModal}
                            className="btn btn-default DefaultButton"
                          >
                            Cancel
                          </a>
                          <button
                            type="submit"
                            className="btn btn-default DefaultButton AquaButton"
                            style={{ marginLeft: "5px" }}
                          >
                            Save
                          </button>
                        </div>
                      </div>
                    </form>
                  )}
                </Formik>
              )}
            </div>
          </div>
          <Loader loading={this.state.loading} />
        </Modal>
      </>
    );
  };
}


const mapStateToProps = state => ({
  // errorMessage: state.auth.errorMessage
});

export default compose(
  connect(mapStateToProps, actions)
)(Add);