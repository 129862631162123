import { countries } from 'country-data';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import * as actions from '../../actions';

class ContactData extends Component {


    constructor(props) {
        super(props);
    
      }
   
    render() { 

      const {deatils} = this.props;

      console.log(deatils,"in site contact edit")


                           return (
                             <Fragment>
                               {deatils.id != "" ?
                                 <ul class="ClientDetails">
                                   <li>
                                     <strong>Account Name</strong>
                                     {deatils.account.name}
                                   </li>
                                   <li>
                                     <strong>First Name</strong>
                                     {deatils.firstName}
                                   </li>
                                   <li>
                                     <strong>Last Name</strong>
                                     {deatils.lastName}
                                   </li>
                                   <li>
                                     <strong>Address Line 1</strong>
                                     {deatils.address.addressLine1}
                                   </li>
                                   <li>
                                     <strong>Address Line 2</strong>
                                     {deatils.address.addressLine2}
                                   </li>
                                   <li>
                                     <strong>City</strong>
                                     {deatils.address.city}
                                   </li>
                                   <li>
                                     <strong>State</strong>
                                     {deatils.address.state}
                                   </li>
                                   <li>
                                     <strong>Country</strong>
                                     {deatils.address.countryCode != ""
                                       ? countries[deatils.address.countryCode]
                                         .name
                                       : ""}
                                   </li>
                                   <li>
                                     <strong>Postcode</strong>
                                     {deatils.address.postcode}
                                   </li>
                                   <li>
                                     <strong>Email ID</strong>
                                     {deatils.contactInfo.email}
                                   </li>
                                   <li>
                                     <strong>Phone</strong>
                                     {deatils?.contactInfo?.mobileNumberCountryCode + deatils?.contactInfo?.mobileNumber}
                                     {/* {deatils.contactInfo.landlineNumber} */}
                                   </li>
                                 </ul>:null}
                             </Fragment>
                           );
    }
}
 


const mapStateToProps = state => ({
    // errorMessage: state.auth.errorMessage
  });
  
  export default compose(
    connect(mapStateToProps, actions)
  )(ContactData);