import React, { Component, Fragment } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import * as actions from '../../actions';
import DeleteModal from '../../components/DeleteModal';
import { Link } from 'react-router-dom';// import OutsideAlerter from '../../components/OutsideAlerter';
import Loader from '../../components/Loader';
import StudyEdit from '../Studies/StudyEdit';
import { countries } from 'country-data';
import SwitchButton from '../../components/form/SwitchButton';

const ITEM = 'Study';
const DELETE_API = 'study/deactivate';
const MODULE_BASE_ROUTE = 'Studies';

class StudyDetails extends Component {
	constructor(props) {
		super(props);

		const id = props.id;
		if (!id) {

		}

		this.state = {

			id,
			detail: {
				sponsor: {
					address: { countryCode: "" },
					contactInfo: {}
				},
			},
			responseFetched: false,
			loading: false,
			isEditModal: false,
			selectedTab: "General Information"

		};
	}

	getDetail = () => {
		const { id } = this.state;
		this.setState(() => ({
			loading: true,
		}));
		this.props.get({ url: `study/${this.props.id}`, errorUrl: `/${MODULE_BASE_ROUTE}`, history: this.props.history }, async (response) => {

			this.setState(() => ({
				detail: response,
				responseFetched: true,
				loading: false,
			}))
			this.props.updatHeader(response.shortTitle)
		});
	}

	rerouteToList = () => {
		this.setState({
			isEditModal: false,
		})
		this.getDetail()
	}

	afterDelete = () => {
		console.log('after delete')
		window.location.href = process.env.PUBLIC_URL + "/Studies"
		window.reload()
	}

	onBack = () => {
		window.location.href = process.env.PUBLIC_URL + "/Studies"
	}
	
	selectTab = (item) => {

		this.setState({
			selectedTab: "Milestone Payments"

		})




	}


	toggleState = (key) => {
		this.setState((prevState, props) => ({
			[key]: !prevState[key],
		}));
		console.log('inside toggle state');
	}

	handleConfirmDelete = (e, id) => {
		e.preventDefault();
		this.setState((prevState, props) => ({
			deleteId: id,
			confirmDelete: true,
			isRowMenu: false,
		}));

	}

	// state = {}
	render() {
		let {
			detail
		} = this.state;
		const { id } = this.state;

		return (

			<Fragment>


				<div id="GeneralInformation" class="container tab-pane active">
					<div class="row">

						{/* <Milestone
										studyNumber={this.props.id}
										
										></Milestone> */}

						<div class="col-lg-12  float-left">
							<ul class="ClientDetails ">
								<li class=""><strong>Joik Study ID</strong>{detail.joikId}</li>
								<li><strong>Project Number</strong>{detail.studyNumber}</li>
								<li><strong>Project Short Title</strong>{detail.shortTitle}</li>
								{/* <li class=""><strong>Project Full Title</strong>{detail.fullTitle}</li>						 */}
								<li><strong>Project Phase</strong>{detail.projectPhase}</li>
								<li><strong>Project Therapeutic Area</strong>{detail.therapeuticArea}</li>
								<li><strong>Study Status</strong>{detail.studyStatus}</li>
								<li><strong>Milestone Payments Allowed</strong>
									{/* {detail.milestonePaymentsAllowed} */}
									<SwitchButton
										checked={detail.milestonePaymentsAllowed}
										isDisabled={true}
									/>
								</li>
							</ul>
						</div>

						<div class="col-lg-12 float-left mb-3 text-left mt-2">
							<h3 class="mb-0 HeadingData">Sponsor Details</h3>
						</div>

						<div class="col-lg-12  float-left">
							<ul class="ClientDetails ">
								<li><strong>Joik Sponsor Contact ID</strong>{detail.joikId}</li>
								<li><strong>Account Name</strong>{detail.sponsor.name}</li>
								<li><strong>Address Line 1</strong>{detail.sponsor.address.addressLine1}</li>
								<li><strong>Address Line 2</strong>{detail.sponsor.address.addressLine2}</li>
								<li><strong>City</strong>{detail.sponsor.address.city}</li>
								<li><strong>State</strong>{detail.sponsor.address.state}</li>
								<li><strong>Postcode</strong>{detail.sponsor.address.postcode}</li>
								<li><strong>Country</strong>{countries[detail.sponsor.address.countryCode].name}</li>
								<li><strong>Email ID</strong>{detail.sponsor.contactInfo.email}</li>
								<li><strong>Phone</strong>{detail.sponsor.contactInfo.mobileNumberCountryCode + detail.sponsor.contactInfo.landlineNumber}</li>
							</ul>
						</div>
						<div class="col-lg-12  float-left mt-3 mb-3 text-center AllFooterButton d-flex justify-content-center">
							<Link to={`${process.env.PUBLIC_URL}/Studies`} className="DefaultButton">Back</Link>
							<button onClick={() => this.toggleState('isEditModal')} className="DefaultButton AquaButton">Edit</button>
							<button onClick={e => this.handleConfirmDelete(e, detail.id)} className="DefaultButton">Delete</button>
						</div>

					</div>
				</div>


				<StudyEdit
					id={id}
					item={ITEM}
					rerouteToList={this.rerouteToList}
					editData={this.state.editData}
					setParentState={(newState) => this.setState(newState)}
					modalIsOpen={this.state.isEditModal}
					toggleModal={() => this.toggleState('isEditModal')}
				/>
				<DeleteModal
					item={ITEM}
					modalIsOpen={this.state.confirmDelete}
					setParentState={(newState) => this.setState(newState)}
					deleteId={this.state.deleteId}
					rerouteToList={this.afterDelete}
					api={this.props.deleteMethod}
					apiName={DELETE_API}
				/>
				<Loader
					loading={this.state.loading}
				/>
			</Fragment>



		)
	}
	componentDidMount() {
		this.getDetail();
	}

}


const mapStateToProps = state => ({
	// errorMessage: state.auth.errorMessage
});


export default compose(
	connect(mapStateToProps, actions)
)(StudyDetails);