import { Formik } from "formik";
import React, { Component, Fragment } from 'react';
import Modal from "react-bootstrap/Modal";
import DatePicker from "react-date-picker";
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { Input } from "reactstrap";
import { compose } from 'redux';
import * as actions from '../../../actions';
import Select from "../../../components/form/Select";
import Text from "../../../components/form/Text";
import Loader from '../../../components/Loader';

const MODULE_BASE_ROUTE = 'Studies';
const MODULE_NAME = 'Subject';

const INITIAL_VALUES = {
  "firstName": "",
  "lastName": "",
  "email": "",
  "landlineNumber": "",
  "mobileNumber": "",
  "addressLine1": "",
  "addressLine2": "",
  "state": "",
  "city": "",
  "postcode": "",
  "countryCode": "UL",
  "subjectNumber": "",
  "dateOfBirth": "",
  "subjectStatus": "",
  "subjectStatusEffectiveDate": "",
  "site":{"id": ""},
  "paymentMethod": "",
  "beneficiaryName":"",
  "bankName":"",
  "swiftCode":"",
  "ibanNumber": "",
  "gender":"Male"
  
};

class Edit extends Component {
  constructor(props) {
    super(props);

    this.state = {
      detail: {
        address: { addressLine1: "" },
      },
      responseFetched: false,
      loading: false,
      response: {},
      paymentType: "Card",
      siteId: "",
      date: '',
      dob: '',
      version: [{ version: "", consentDate: "" }],
    };
  }

  validate = (values) => {
    let errors = {};

    if (!values.subjectNumber) {
      errors.subjectNumber = "Subject number isrequired";
    }

    if (!values.firstName) {
      errors.firstName = "First name required";
    }

    if (values.firstName && values.firstName.trim().length < 2) {
      errors.firstName = "first name should contains atleast 2-50 characters";
    }

    if (!values.lastName) {
      errors.lastName = "Last name required";
    }

    if (!values.mobileNumber) {
      errors.mobileNumber = "Mobile number is required";
    }

    if (!values.email) {
      errors.email = "Email is required";
    }

    if (values.lastName && values.lastName.trim().length < 2) {
      errors.lastName = "Last name should contains atleast 2-50 characters";
    }

    if (!values.addressLine1) {
      errors.addressLine1 = "Address line1 required";
    }

    if (values.addressLine1 && values.addressLine1.trim().length < 5) {
      errors.addressLine1 =
        "Address line1 should contains atleast 5-100 characters";
    }

    if (!values.city) {
      errors.city = "City is required";
    }

    if (values.city && values.city.trim().length < 2) {
      errors.city = "City is should contains atleast 2-50 characters";
    }

    if (!values.postcode) {
      errors.postcode = "Postcode is required";
    }

    if (values.postcode && values.postcode.trim().length < 3) {
      errors.postcode = "postcode should contains atleast 3-10 characters";
    }

    if (!values.countryCode) {
      errors.countryCode = "Country is required";
    }

    // if (!values.dateOfBirth) {
    //   errors.dateOfBirth = "Date of Birth is required";
    // }

    if (!values.subjectStatus) {
      errors.subjectStatus = "Subject Status is required";
    }

    if (!values.gender) {
      errors.gender = "Gender  is required";
    }

    // if(!values.subjectStatusEffectiveDate){
    //   errors.subjectStatusEffectiveDate = "Subject Status Effective date is required";
    // }

    if (!values.paymentMethod) {
      errors.paymentMethod = "Payment method is required";
    }

    if (values.paymentMethod === "Bank") {
      if (!values.beneficiaryName) {
        errors.beneficiaryName = "Beneficiary name is required";
      }

      if (values.beneficiaryName && values.beneficiaryName.trim().length > 50) {
        errors.beneficiaryName =
          "Beneficiary name should be atleast 1-50 characters";
      }

      if (!values.bankName) {
        errors.bankName = "Bank name is required";
      }

      if (values.bankName && values.bankName.trim().length > 50) {
        errors.bankName = "Bank name should be atleast 1-50 characters";
      }

      if (!values.swiftCode) {
        errors.swiftCode = "Swift code is required";
      }

      if (values.swiftCode && values.swiftCode.trim().length > 20) {
        errors.swiftCode = "Bank name should be atleast 1-20 characters";
      }

      if (!values.ibanNumber) {
        errors.ibanNumber = "IBAN number is required";
      }

      if (values.ibanNumber && values.ibanNumber.trim().length > 35) {
        errors.ibanNumber = "IBAN number should be atleast 1-35 characters";
      }
    }

    return errors;
  };

  onChange = (val) => {
    var value = "";

    if (val != null) {
      value = new Date(val.getTime() - val.getTimezoneOffset() * 60000);
    }

    this.setState({
      date: value,
    });
  };

  onChangeDob = (val) => {
    var value = "";

    if (val != null) {
      value = new Date(val.getTime() - val.getTimezoneOffset() * 60000);
    }

    this.setState({
      dob: value,
    });
  };

  removeVersionField = (index) => {
    let version = [...this.state.version];

    version.splice(index, 1);

    this.setState({
      version,
    });
  };

  getDetails = () => {
    const { id } = this.state;
    this.setState(() => ({
      loading: true,
    }));
    this.props.get(
      {
        url: `subject/${this.props.subjectId}`,
        errorUrl: `/${MODULE_BASE_ROUTE}`,
        history: this.props.history,
      },
      async (response) => {

        response.consentFormInfos.forEach((element) => {
          element.consentDate = new Date(element.consentDate);
        });
        
        this.setState(() => ({
          response: response,
          date: response.subjectStatusEffectiveDate,
          dob:new Date(response.dateOfBirth),
          siteId: response.site.id,
          version:
            response.consentFormInfos.length == 0
              ? this.state.version
              : response.consentFormInfos,
          date: new Date(response.subjectStatusEffectiveDate),
          detail: {
            firstName: response.firstName,
            gender: response.gender,
            lastName: response.lastName,
            email: response.contactInfo.email,
            landlineNumber: response.contactInfo.landlineNumber,
            mobileNumber: response.contactInfo.mobileNumber,
            addressLine1: response.address.addressLine1,
            addressLine2: response.address.addressLine2,
            state: response.address.state,
            city: response.address.city,
            postcode: response.address.postcode,
            countryCode: response.address.countryCode,
            subjectNumber: response.subjectNumber,
            dateOfBirth: response.dateOfBirth,
            subjectStatus: response.subjectStatus,
            subjectStatusEffectiveDate: response.subjectStatusEffectiveDate,
            beneficiaryName:
              response.paymentMethod == "Card"
                ? null
                : response.bankDetails.beneficiaryName,
            bankName:
              response.paymentMethod == "Card"
                ? null
                : response.bankDetails.bankName,
            swiftCode:
              response.paymentMethod == "Card"
                ? null
                : response.bankDetails.swiftCode,
            ibanNumber:
              response.paymentMethod == "Card"
                ? null
                : response.bankDetails.ibanNumber,
            paymentMethod: response.paymentMethod,
          },
          paymentType: response.paymentMethod,
          responseFetched: true,
          loading: false,
        }));
      }
    );
  };

  addVersionField = () => {
    let version = [...this.state.version];

    version.push({ version: "", consentDate: "" });

    this.setState({
      version,
    });
  };

  changeVersionText = (evn) => {
    let version = [...this.state.version];
    version[evn.target.name].version = evn.target.value;
    this.setState({
      version,
    });
  };

  handleSubmit = async (values, { setSubmitting, resetForm }) => {
    this.setState(() => ({
      loading: true,
    }));

    var modifiedValues = {
      firstName: values.firstName,
      lastName: values.lastName,
      gender: values.gender,
      consentFormInfos: this.state.version,
      contactInfo: {
        id: this.state.response.contactInfo.id,
        email: values.email,
        landlineNumber: null,
        mobileNumber: values.mobileNumber,
      },
      address: {
        id: this.state.response.address.id,
        addressLine1: values.addressLine1,
        addressLine2: values.addressLine2,
        state: values.state,
        city: values.city,
        postcode: values.postcode,
        countryCode: values.countryCode,
      },

      subjectNumber: values.subjectNumber,
      dateOfBirth: this.state.dob,
      subjectStatus: values.subjectStatus,
      subjectStatusEffectiveDate: values.subjectStatusEffectiveDate,
      site: { id: this.state.siteId },
      paymentMethod: values.paymentMethod,
      bankDetails: {
        beneficiaryName: values.beneficiaryName,
        bankName: values.bankName,
        swiftCode: values.swiftCode,
        ibanNumber: values.ibanNumber,
      },

      consentFormInfos: this.state.version,
    };

    if (values.paymentMethod == "Card") {
      delete modifiedValues.bankDetails;
    }

    await this.props.apiJSON(
      {
        url: `subject/${this.props.subjectId}`,
        method: "PUT",
        data: modifiedValues,
      },
      () => {
        setTimeout(() => {
          setSubmitting(false);
          toast(`${MODULE_NAME} updated successfully.`, { type: "success" });
          this.props.setParentState(() => ({
            isAddModal: false,
            loading: false,
          }));
          this.props.reloadList();
        }, 1500);
        console.log(this.props);
      }
    );

    setTimeout(() => {
      this.setState(() => ({
        loading: false,
      }));
    }, 2000);
  };

  onChangeConcentDate = (val, index) => {
    var value = "";

    if (val != null) {
      value = new Date(val.getTime() - val.getTimezoneOffset() * 60000);
      let version = [...this.state.version];
      version[index].consentDate = value;

      this.setState({
        version,
      });
    }

    // this.setState({
    //   consentDate: value,
    // });
  };

  onEscapeKeyDown = (e) => {
    e.preventDefault();
  };

  render = () => {
    let { detail } = this.state;

    return (
      <>
        <Modal
          show={this.props.isEditModal}
          onHide={this.props.toggleModal}
          animation={false}
          onShow={this.getDetails}
          className={"CustomModal width900"}
          backdrop={false}
          onEscapeKeyDown={this.onEscapeKeyDown}
        >
          <div className="content">
            <div className="row">
              {Object.keys(detail).length > 0 && (
                <Formik
                  initialValues={this.state.detail}
                  validate={this.validate}
                  onSubmit={this.handleSubmit}
                  enableReinitialize
                >
                  {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    setFieldValue,
                    isSubmitting,
                  }) => (
                    <form
                      onSubmit={handleSubmit}
                      autoComplete="off"
                      className="float-left FormEditDesign"
                    >
                      <div className="modal-header">
                        <h4 className="modal-title">Edit Subject</h4>
                        <button
                          type="button"
                          onClick={this.props.toggleModal}
                          className="close"
                        >
                          &times;
                        </button>
                      </div>
                      <div className="col-md-12">
                        <div className="col-lg-12  float-left">
                          <div className="modal-body modalpadding">
                            <div className="row">
                              <div className="col-lg-12">
                                <h4>Enter Subject details</h4>
                              </div>
                            </div>
                            {/* <form className="float-left FormEditDesign"> */}
                            <div className="col-lg-12">
                              <div className="row">
                                {/* <!--First--> */}
                                <div className="col-md-12">
                                  <div className="row"></div>
                                  <div className="row">
                                    <div className="col-lg-6 col-md-6">
                                      <div className="form-group required">
                                        <Text
                                          title="Account Name"
                                          name="accountName"
                                          placeholder="Subject id"
                                          // id="input_name"
                                          errors={errors}
                                          touched={touched}
                                          value={this.props.accountName}
                                          handleChange={handleChange}
                                          handleBlur={handleBlur}
                                          disabled={true}
                                        />
                                      </div>
                                    </div>

                                    <div className="col-lg-6 col-md-6">
                                      <div className="form-group required">
                                        <Text
                                          title="Subject id"
                                          name="subjectNumber"
                                          placeholder="Subject id"
                                          // id="input_name"
                                          errors={errors}
                                          touched={touched}
                                          values={values}
                                          handleChange={handleChange}
                                          handleBlur={handleBlur}
                                          disabled={false}
                                          type="number"
                                        />
                                      </div>
                                    </div>
                                  </div>
                                  <div className="row">
                                    <div className="col-lg-6 col-md-12">
                                      <div className="form-group required">
                                        <Text
                                          title="First Name"
                                          name="firstName"
                                          placeholder="First Name"
                                          // id="input_name"
                                          errors={errors}
                                          touched={touched}
                                          values={values}
                                          handleChange={handleChange}
                                          handleBlur={handleBlur}
                                          disabled={false}
                                          maxlength="50"
                                        />
                                      </div>
                                    </div>

                                    <div className="col-lg-6 col-md-12">
                                      <div className="form-group required">
                                        <Text
                                          title="Last Name"
                                          name="lastName"
                                          placeholder="Last Name"
                                          // id="input_name"
                                          errors={errors}
                                          touched={touched}
                                          values={values}
                                          handleChange={handleChange}
                                          handleBlur={handleBlur}
                                          disabled={false}
                                          maxlength="50"
                                        />
                                      </div>
                                    </div>
                                  </div>
                                  <div className="row">
                                    <div className="col-lg-6 col-md-12">
                                      <div className="form-group required">
                                        <Text
                                          title="Address Line 1"
                                          name="addressLine1"
                                          placeholder="Address Line 1"
                                          // id="input_name"
                                          errors={errors}
                                          touched={touched}
                                          values={values}
                                          handleChange={handleChange}
                                          handleBlur={handleBlur}
                                          disabled={false}
                                          maxlength="100"
                                        />
                                      </div>
                                    </div>

                                    <div className="col-lg-6 col-md-12">
                                      <div className="form-group">
                                        <Text
                                          title="Address Line 2"
                                          name="addressLine2"
                                          placeholder="Address Line 2"
                                          // id="input_name"
                                          errors={errors}
                                          touched={touched}
                                          values={values}
                                          handleChange={handleChange}
                                          handleBlur={handleBlur}
                                          disabled={false}
                                          maxlength="50"
                                        />
                                      </div>
                                    </div>
                                  </div>
                                  <div className="row">
                                    <div className="col-lg-6 col-md-12">
                                      <div className="form-group required">
                                        <Text
                                          title="City"
                                          name="city"
                                          placeholder="City"
                                          // id="input_name"
                                          errors={errors}
                                          touched={touched}
                                          values={values}
                                          handleChange={handleChange}
                                          handleBlur={handleBlur}
                                          disabled={false}
                                          maxlength="50"
                                        />
                                      </div>
                                    </div>

                                    <div className="col-lg-6 col-md-12">
                                      <div className="form-group ">
                                        <Text
                                          title="State"
                                          name="state"
                                          placeholder="State"
                                          // id="input_name"
                                          errors={errors}
                                          touched={touched}
                                          values={values}
                                          handleChange={handleChange}
                                          handleBlur={handleBlur}
                                          disabled={false}
                                          maxlength="50"
                                        />
                                      </div>
                                    </div>
                                  </div>
                                  <div className="row">
                                    <div className="col-lg-6 col-md-12">
                                      <div className="form-group required">
                                        <Text
                                          title="Postcode"
                                          name="postcode"
                                          placeholder="Postcode"
                                          // id="input_name"
                                          errors={errors}
                                          touched={touched}
                                          values={values}
                                          handleChange={handleChange}
                                          handleBlur={handleBlur}
                                          disabled={false}
                                          maxlength="10"
                                        />
                                      </div>
                                    </div>

                                    <div className="col-lg-6 col-md-12">
                                      <div className="form-group required">
                                        <Select
                                          title="Country"
                                          name="countryCode"
                                          placeholder="Select Country"
                                          options={[
                                            {
                                              label: "Afghanistan",
                                              value: "AF",
                                            },
                                            {
                                              label: "Åland Islands",
                                              value: "AX",
                                            },
                                            { label: "Albania", value: "AL" },
                                            { label: "Algeria", value: "DZ" },
                                            {
                                              label: "American Samoa",
                                              value: "AS",
                                            },
                                            { label: "AndorrA", value: "AD" },
                                            { label: "Angola", value: "AO" },
                                            { label: "Anguilla", value: "AI" },
                                            {
                                              label: "Antarctica",
                                              value: "AQ",
                                            },
                                            {
                                              label: "Antigua and Barbuda",
                                              value: "AG",
                                            },
                                            { label: "Argentina", value: "AR" },
                                            { label: "Armenia", value: "AM" },
                                            { label: "Aruba", value: "AW" },
                                            { label: "Australia", value: "AU" },
                                            { label: "Austria", value: "AT" },
                                            {
                                              label: "Azerbaijan",
                                              value: "AZ",
                                            },
                                            { label: "Bahamas", value: "BS" },
                                            { label: "Bahrain", value: "BH" },
                                            {
                                              label: "Bangladesh",
                                              value: "BD",
                                            },
                                            { label: "Barbados", value: "BB" },
                                            { label: "Belarus", value: "BY" },
                                            { label: "Belgium", value: "BE" },
                                            { label: "Belize", value: "BZ" },
                                            { label: "Benin", value: "BJ" },
                                            { label: "Bermuda", value: "BM" },
                                            { label: "Bhutan", value: "BT" },
                                            { label: "Bolivia", value: "BO" },
                                            {
                                              label: "Bosnia and Herzegovina",
                                              value: "BA",
                                            },
                                            { label: "Botswana", value: "BW" },
                                            {
                                              label: "Bouvet Island",
                                              value: "BV",
                                            },
                                            { label: "Brazil", value: "BR" },
                                            {
                                              label:
                                                "British Indian Ocean Territory",
                                              value: "IO",
                                            },
                                            {
                                              label: "Brunei Darussalam",
                                              value: "BN",
                                            },
                                            { label: "Bulgaria", value: "BG" },
                                            {
                                              label: "Burkina Faso",
                                              value: "BF",
                                            },
                                            { label: "Burundi", value: "BI" },
                                            { label: "Cambodia", value: "KH" },
                                            { label: "Cameroon", value: "CM" },
                                            { label: "Canada", value: "CA" },
                                            {
                                              label: "Cape Verde",
                                              value: "CV",
                                            },
                                            {
                                              label: "Cayman Islands",
                                              value: "KY",
                                            },
                                            {
                                              label: "Central African Republic",
                                              value: "CF",
                                            },
                                            { label: "Chad", value: "TD" },
                                            { label: "Chile", value: "CL" },
                                            { label: "China", value: "CN" },
                                            {
                                              label: "Christmas Island",
                                              value: "CX",
                                            },
                                            {
                                              label: "Cocos (Keeling) Islands",
                                              value: "CC",
                                            },
                                            { label: "Colombia", value: "CO" },
                                            { label: "Comoros", value: "KM" },
                                            { label: "Congo", value: "CG" },
                                            {
                                              label:
                                                "Congo, The Democratic Republic of the",
                                              value: "CD",
                                            },
                                            {
                                              label: "Cook Islands",
                                              value: "CK",
                                            },
                                            {
                                              label: "Costa Rica",
                                              value: "CR",
                                            },
                                            {
                                              label: "Cote D Ivoire",
                                              value: "CI",
                                            },
                                            { label: "Croatia", value: "HR" },
                                            { label: "Cuba", value: "CU" },
                                            { label: "Cyprus", value: "CY" },
                                            {
                                              label: "Czech Republic",
                                              value: "CZ",
                                            },
                                            { label: "Denmark", value: "DK" },
                                            { label: "Djibouti", value: "DJ" },
                                            { label: "Dominica", value: "DM" },
                                            {
                                              label: "Dominican Republic",
                                              value: "DO",
                                            },
                                            { label: "Ecuador", value: "EC" },
                                            { label: "Egypt", value: "EG" },
                                            {
                                              label: "El Salvador",
                                              value: "SV",
                                            },
                                            {
                                              label: "Equatorial Guinea",
                                              value: "GQ",
                                            },
                                            { label: "Eritrea", value: "ER" },
                                            { label: "Estonia", value: "EE" },
                                            { label: "Ethiopia", value: "ET" },
                                            {
                                              label:
                                                "Falkland Islands (Malvinas)",
                                              value: "FK",
                                            },
                                            {
                                              label: "Faroe Islands",
                                              value: "FO",
                                            },
                                            { label: "Fiji", value: "FJ" },
                                            { label: "Finland", value: "FI" },
                                            { label: "France", value: "FR" },
                                            {
                                              label: "French Guiana",
                                              value: "GF",
                                            },
                                            {
                                              label: "French Polynesia",
                                              value: "PF",
                                            },
                                            {
                                              label:
                                                "French Southern Territories",
                                              value: "TF",
                                            },
                                            { label: "Gabon", value: "GA" },
                                            { label: "Gambia", value: "GM" },
                                            { label: "Georgia", value: "GE" },
                                            { label: "Germany", value: "DE" },
                                            { label: "Ghana", value: "GH" },
                                            { label: "Gibraltar", value: "GI" },
                                            { label: "Greece", value: "GR" },
                                            { label: "Greenland", value: "GL" },
                                            { label: "Grenada", value: "GD" },
                                            {
                                              label: "Guadeloupe",
                                              value: "GP",
                                            },
                                            { label: "Guam", value: "GU" },
                                            { label: "Guatemala", value: "GT" },
                                            { label: "Guernsey", value: "GG" },
                                            { label: "Guinea", value: "GN" },
                                            {
                                              label: "Guinea-Bissau",
                                              value: "GW",
                                            },
                                            { label: "Guyana", value: "GY" },
                                            { label: "Haiti", value: "HT" },
                                            {
                                              label:
                                                "Heard Island and Mcdonald Islands",
                                              value: "HM",
                                            },
                                            {
                                              label:
                                                "Holy See (Vatican City State)",
                                              value: "VA",
                                            },
                                            { label: "Honduras", value: "HN" },
                                            { label: "Hong Kong", value: "HK" },
                                            { label: "Hungary", value: "HU" },
                                            { label: "Iceland", value: "IS" },
                                            { label: "India", value: "IN" },
                                            { label: "Indonesia", value: "ID" },
                                            {
                                              label:
                                                "Iran, Islamic Republic Of",
                                              value: "IR",
                                            },
                                            { label: "Iraq", value: "IQ" },
                                            { label: "Ireland", value: "IE" },
                                            {
                                              label: "Isle of Man",
                                              value: "IM",
                                            },
                                            { label: "Israel", value: "IL" },
                                            { label: "Italy", value: "IT" },
                                            { label: "Jamaica", value: "JM" },
                                            { label: "Japan", value: "JP" },
                                            { label: "Jersey", value: "JE" },
                                            { label: "Jordan", value: "JO" },
                                            {
                                              label: "Kazakhstan",
                                              value: "KZ",
                                            },
                                            { label: "Kenya", value: "KE" },
                                            { label: "Kiribati", value: "KI" },
                                            {
                                              label:
                                                "Korea, Democratic People S Republic of",
                                              value: "KP",
                                            },
                                            {
                                              label: "Korea, Republic of",
                                              value: "KR",
                                            },
                                            { label: "Kuwait", value: "KW" },
                                            {
                                              label: "Kyrgyzstan",
                                              value: "KG",
                                            },
                                            {
                                              label:
                                                "Lao People S Democratic Republic",
                                              value: "LA",
                                            },
                                            { label: "Latvia", value: "LV" },
                                            { label: "Lebanon", value: "LB" },
                                            { label: "Lesotho", value: "LS" },
                                            { label: "Liberia", value: "LR" },
                                            {
                                              label: "Libyan Arab Jamahiriya",
                                              value: "LY",
                                            },
                                            {
                                              label: "Liechtenstein",
                                              value: "LI",
                                            },
                                            { label: "Lithuania", value: "LT" },
                                            {
                                              label: "Luxembourg",
                                              value: "LU",
                                            },
                                            { label: "Macao", value: "MO" },
                                            {
                                              label:
                                                "Macedonia, The Former Yugoslav Republic of",
                                              value: "MK",
                                            },
                                            {
                                              label: "Madagascar",
                                              value: "MG",
                                            },
                                            { label: "Malawi", value: "MW" },
                                            { label: "Malaysia", value: "MY" },
                                            { label: "Maldives", value: "MV" },
                                            { label: "Mali", value: "ML" },
                                            { label: "Malta", value: "MT" },
                                            {
                                              label: "Marshall Islands",
                                              value: "MH",
                                            },
                                            {
                                              label: "Martinique",
                                              value: "MQ",
                                            },
                                            {
                                              label: "Mauritania",
                                              value: "MR",
                                            },
                                            { label: "Mauritius", value: "MU" },
                                            { label: "Mayotte", value: "YT" },
                                            { label: "Mexico", value: "MX" },
                                            {
                                              label:
                                                "Micronesia, Federated States of",
                                              value: "FM",
                                            },
                                            {
                                              label: "Moldova, Republic of",
                                              value: "MD",
                                            },
                                            { label: "Monaco", value: "MC" },
                                            { label: "Mongolia", value: "MN" },
                                            {
                                              label: "Montserrat",
                                              value: "MS",
                                            },
                                            { label: "Morocco", value: "MA" },
                                            {
                                              label: "Mozambique",
                                              value: "MZ",
                                            },
                                            { label: "Myanmar", value: "MM" },
                                            { label: "Namibia", value: "NA" },
                                            { label: "Nauru", value: "NR" },
                                            { label: "Nepal", value: "NP" },
                                            {
                                              label: "Netherlands",
                                              value: "NL",
                                            },
                                            {
                                              label: "Netherlands Antilles",
                                              value: "AN",
                                            },
                                            {
                                              label: "New Caledonia",
                                              value: "NC",
                                            },
                                            {
                                              label: "New Zealand",
                                              value: "NZ",
                                            },
                                            { label: "Nicaragua", value: "NI" },
                                            { label: "Niger", value: "NE" },
                                            { label: "Nigeria", value: "NG" },
                                            { label: "Niue", value: "NU" },
                                            {
                                              label: "Norfolk Island",
                                              value: "NF",
                                            },
                                            {
                                              label: "Northern Mariana Islands",
                                              value: "MP",
                                            },
                                            { label: "Norway", value: "NO" },
                                            { label: "Oman", value: "OM" },
                                            { label: "Pakistan", value: "PK" },
                                            { label: "Palau", value: "PW" },
                                            {
                                              label:
                                                "Palestinian Territory, Occupied",
                                              value: "PS",
                                            },
                                            { label: "Panama", value: "PA" },
                                            {
                                              label: "Papua New Guinea",
                                              value: "PG",
                                            },
                                            { label: "Paraguay", value: "PY" },
                                            { label: "Peru", value: "PE" },
                                            {
                                              label: "Philippines",
                                              value: "PH",
                                            },
                                            { label: "Pitcairn", value: "PN" },
                                            { label: "Poland", value: "PL" },
                                            { label: "Portugal", value: "PT" },
                                            {
                                              label: "Puerto Rico",
                                              value: "PR",
                                            },
                                            { label: "Qatar", value: "QA" },
                                            { label: "Reunion", value: "RE" },
                                            { label: "Romania", value: "RO" },
                                            {
                                              label: "Russian Federation",
                                              value: "RU",
                                            },
                                            { label: "RWANDA", value: "RW" },
                                            {
                                              label: "Saint Helena",
                                              value: "SH",
                                            },
                                            {
                                              label: "Saint Kitts and Nevis",
                                              value: "KN",
                                            },
                                            {
                                              label: "Saint Lucia",
                                              value: "LC",
                                            },
                                            {
                                              label:
                                                "Saint Pierre and Miquelon",
                                              value: "PM",
                                            },
                                            {
                                              label:
                                                "Saint Vincent and the Grenadines",
                                              value: "VC",
                                            },
                                            { label: "Samoa", value: "WS" },
                                            {
                                              label: "San Marino",
                                              value: "SM",
                                            },
                                            {
                                              label: "Sao Tome and Principe",
                                              value: "ST",
                                            },
                                            {
                                              label: "Saudi Arabia",
                                              value: "SA",
                                            },
                                            { label: "Senegal", value: "SN" },
                                            {
                                              label: "Serbia and Montenegro",
                                              value: "CS",
                                            },
                                            {
                                              label: "Seychelles",
                                              value: "SC",
                                            },
                                            {
                                              label: "Sierra Leone",
                                              value: "SL",
                                            },
                                            { label: "Singapore", value: "SG" },
                                            { label: "Slovakia", value: "SK" },
                                            { label: "Slovenia", value: "SI" },
                                            {
                                              label: "Solomon Islands",
                                              value: "SB",
                                            },
                                            { label: "Somalia", value: "SO" },
                                            {
                                              label: "South Africa",
                                              value: "ZA",
                                            },
                                            {
                                              label:
                                                "South Georgia and the South Sandwich Islands",
                                              value: "GS",
                                            },
                                            { label: "Spain", value: "ES" },
                                            { label: "Sri Lanka", value: "LK" },
                                            { label: "Sudan", value: "SD" },
                                            { label: "Surilabel", value: "SR" },
                                            {
                                              label: "Svalbard and Jan Mayen",
                                              value: "SJ",
                                            },
                                            { label: "Swaziland", value: "SZ" },
                                            { label: "Sweden", value: "SE" },
                                            {
                                              label: "Switzerland",
                                              value: "CH",
                                            },
                                            {
                                              label: "Syrian Arab Republic",
                                              value: "SY",
                                            },
                                            {
                                              label:
                                                "Taiwan, Province of China",
                                              value: "TW",
                                            },
                                            {
                                              label: "Tajikistan",
                                              value: "TJ",
                                            },
                                            {
                                              label:
                                                "Tanzania, United Republic of",
                                              value: "TZ",
                                            },
                                            { label: "Thailand", value: "TH" },
                                            {
                                              label: "Timor-Leste",
                                              value: "TL",
                                            },
                                            { label: "Togo", value: "TG" },
                                            { label: "Tokelau", value: "TK" },
                                            { label: "Tonga", value: "TO" },
                                            {
                                              label: "Trinidad and Tobago",
                                              value: "TT",
                                            },
                                            { label: "Tunisia", value: "TN" },
                                            { label: "Turkey", value: "TR" },
                                            {
                                              label: "Turkmenistan",
                                              value: "TM",
                                            },
                                            {
                                              label: "Turks and Caicos Islands",
                                              value: "TC",
                                            },
                                            { label: "Tuvalu", value: "TV" },
                                            { label: "Uganda", value: "UG" },
                                            { label: "Ukraine", value: "UA" },
                                            {
                                              label: "United Arab Emirates",
                                              v00010alue: "AE",
                                            },
                                            {
                                              label: "United Kingdom",
                                              value: "GB",
                                            },
                                            {
                                              label: "United States",
                                              value: "US",
                                            },
                                            {
                                              label:
                                                "United States Minor Outlying Islands",
                                              value: "UM",
                                            },
                                            { label: "Uruguay", value: "UY" },
                                            {
                                              label: "Uzbekistan",
                                              value: "UZ",
                                            },
                                            { label: "Vanuatu", value: "VU" },
                                            { label: "Venezuela", value: "VE" },
                                            { label: "Viet Nam", value: "VN" },
                                            {
                                              label: "Virgin Islands, British",
                                              value: "VG",
                                            },
                                            {
                                              label: "Virgin Islands, U.S.",
                                              value: "VI",
                                            },
                                            {
                                              label: "Wallis and Futuna",
                                              value: "WF",
                                            },
                                            {
                                              label: "Western Sahara",
                                              value: "EH",
                                            },
                                            { label: "Yemen", value: "YE" },
                                            { label: "Zambia", value: "ZM" },
                                            { label: "Zimbabwe", value: "ZW" },
                                          ]}
                                          id="countryCode"
                                          errors={errors}
                                          touched={touched}
                                          values={values}
                                          handleChange={(e) => {
                                            const value = e.target.value;
                                            setFieldValue("countryCode", value);
                                          }}
                                          handleBlur={handleBlur}
                                          disabled={false}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                  <div className="row">
                                    <div className="col-lg-6 col-md-12">
                                      <div className="form-group required">
                                        <Text
                                          title="Mobile Number"
                                          name="mobileNumber"
                                          placeholder="Mobile Number"
                                          // id="input_name"
                                          errors={errors}
                                          touched={touched}
                                          values={values}
                                          handleChange={handleChange}
                                          handleBlur={handleBlur}
                                          disabled={false}
                                          maxlength="20"
                                        />
                                      </div>
                                    </div>
                                    <div className="col-lg-6 col-md-12">
                                      <div className="form-group required">
                                        <Text
                                          title="Email ID"
                                          name="email"
                                          placeholder="Email ID"
                                          // id="input_name"
                                          errors={errors}
                                          touched={touched}
                                          values={values}
                                          handleChange={handleChange}
                                          handleBlur={handleBlur}
                                          disabled={false}
                                          maxlength="50"
                                        />
                                      </div>
                                    </div>
                                  </div>
                                  <div className="row">
                                    <div className="col-lg-6 col-md-12">
                                      <div className="form-group required">
                                        <label class="form-control-label">
                                          Date Of Birth
                                        </label>
                                        <DatePicker
                                          utcOffset={0}
                                          dateFormat="DD-MMM HH:mm"
                                          onChange={this.onChangeDob}
                                          value={this.state.dob}
                                          required={true}
                                          maxDate={new Date()}
                                        />
                                      </div>
                                    </div>

                                    <div className="col-lg-6 col-md-6">
                                      <div className="form-group required">
                                        <Select
                                          title="Gender"
                                          name="gender"
                                          placeholder="Gender"
                                          options={[
                                            {
                                              value: "Male",
                                              label: "Male",
                                            },
                                            {
                                              value: "Female",
                                              label: "Female",
                                            },
                                            {
                                              value: "Other",
                                              label: "Other",
                                            },
                                          ]}
                                          id="gender"
                                          errors={errors}
                                          touched={touched}
                                          values={values}
                                          handleChange={(e) => {
                                            const value = e.target.value;
                                            // this.selectedGender(value);
                                            setFieldValue("gender", value);
                                          }}
                                          handleBlur={handleBlur}
                                          disabled={false}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                  <h4 className="modal-title mt-2">
                                    Payment Details
                                  </h4>
                                  <div class="row">
                                    <div className="col-lg-12 col-md-12">
                                      <div className="form-group required">
                                        <Select
                                          title="Mode Of Payment"
                                          name="paymentMethod"
                                          placeholder="Select payment methode"
                                          options={[
                                            {
                                              value: "Card",
                                              label: "Card",
                                            },
                                            {
                                              value: "Bank",
                                              label: "Bank",
                                            },
                                          ]}
                                          id="paymentMethod"
                                          errors={errors}
                                          touched={touched}
                                          values={values}
                                          handleChange={(e) => {
                                            const value = e.target.value;
                                            this.setState({
                                              paymentType: e.target.value,
                                            });
                                            setFieldValue(
                                              "paymentMethod",
                                              value
                                            );
                                          }}
                                          handleBlur={handleBlur}
                                          disabled={false}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                  {this.state.paymentType == "Bank" ? (
                                    <Fragment>
                                      <div className="row">
                                        <div className="col-lg-6 col-md-12">
                                          <div className="form-group required">
                                            <Text
                                              title="Beneficiary Name"
                                              name="beneficiaryName"
                                              placeholder="Beneficiary Name"
                                              // id="input_name"
                                              errors={errors}
                                              touched={touched}
                                              values={values}
                                              handleChange={handleChange}
                                              handleBlur={handleBlur}
                                              disabled={false}
                                              maxlength="50"
                                            />
                                          </div>
                                        </div>

                                        <div className="col-lg-6 col-md-12">
                                          <div className="form-group required">
                                            <Text
                                              title="Bank Name"
                                              name="bankName"
                                              placeholder="Bank Name"
                                              // id="input_name"
                                              errors={errors}
                                              touched={touched}
                                              values={values}
                                              handleChange={handleChange}
                                              handleBlur={handleBlur}
                                              disabled={false}
                                              maxlength="50"
                                            />
                                          </div>
                                        </div>
                                      </div>

                                      <div className="row">
                                        <div className="col-lg-6 col-md-12">
                                          <div className="form-group required">
                                            <Text
                                              title="Business Identifier Code (BIC)/Swift Code"
                                              name="swiftCode"
                                              placeholder="Business Identifier Code (BIC)/Swift Code"
                                              // id="input_name"
                                              errors={errors}
                                              touched={touched}
                                              values={values}
                                              handleChange={handleChange}
                                              handleBlur={handleBlur}
                                              disabled={false}
                                              maxlength="50"
                                            />
                                          </div>
                                        </div>

                                        <div className="col-lg-6 col-md-12">
                                          <div className="form-group required">
                                            <Text
                                              title="Bank Account Number or IBAN"
                                              name="ibanNumber"
                                              placeholder="Bank Account Number or IBAN"
                                              // id="input_name"
                                              errors={errors}
                                              touched={touched}
                                              values={values}
                                              handleChange={handleChange}
                                              handleBlur={handleBlur}
                                              disabled={false}
                                              maxlength="35"
                                            />
                                          </div>
                                        </div>
                                      </div>
                                    </Fragment>
                                  ) : null}
                                  <h4 className="modal-title">
                                    Subject Status
                                  </h4>
                                  <div class="row">
                                    <div className="col-lg-6 col-md-6">
                                      <div className="form-group required">
                                        <Select
                                          title="Subject Status"
                                          name="subjectStatus"
                                          placeholder="Subject Status"
                                          options={[
                                            {
                                              value: "Screened",
                                              label: "Screened",
                                            },
                                            {
                                              value: "Randomized",
                                              label: "Randomized",
                                            },
                                            {
                                              value: "Discontinued",
                                              label: "Discontinued",
                                            },
                                          ]}
                                          id="subjectStatus"
                                          errors={errors}
                                          touched={touched}
                                          values={values}
                                          handleChange={(e) => {
                                            const value = e.target.value;
                                            // this.selectedGender(value);
                                            setFieldValue(
                                              "subjectStatus",
                                              value
                                            );
                                          }}
                                          handleBlur={handleBlur}
                                          disabled={false}
                                        />
                                      </div>
                                    </div>
                                    <div className="col-lg-6 col-md-12">
                                      <div className="form-group required">
                                        <label class="form-control-label">
                                          Subject Status Effective Date
                                        </label>
                                        <DatePicker
                                          utcOffset={0}
                                          dateFormat="DD-MMM HH:mm"
                                          onChange={this.onChange}
                                          value={this.state.date}
                                          required={true}
                                        />
                                      </div>
                                    </div>
                                  </div>

                                  <h4 className="modal-title">
                                    Joik Consent Form
                                  </h4>

                                  <div className="row rowheight">
                                    <div className="col-md-12">
                                      {this.state.version.map((item, index) => (
                                        <div className="row">
                                          <div className="col-lg-5 col-md-12">
                                            <div className="form-group">
                                              <label class="form-control-label">
                                                Version
                                              </label>
                                              <Input
                                                className="form-control"
                                                value={item.version}
                                                name={index}
                                                onChange={
                                                  this.changeVersionText
                                                }
                                              />
                                            </div>
                                          </div>
                                          <div className="col-lg-5 col-md-12">
                                            <div className="form-group">
                                              <label class="form-control-label">
                                                Consent Date
                                              </label>
                                              <DatePicker
                                                utcOffset={0}
                                                dateFormat="DD-MMM HH:mm"
                                                onChange={(data) =>
                                                  this.onChangeConcentDate(
                                                    data,
                                                    index
                                                  )
                                                }
                                                value={
                                                  this.state.version[index]
                                                    .consentDate
                                                }
                                                required={true}
                                              />
                                            </div>
                                          </div>
                                          {index >=
                                          this.state.version.length - 1 ? (
                                            <div className="col-lg-2 plusiconadd">
                                              {this.state.version.length !=
                                              1 ? (
                                                <a
                                                  onClick={() =>
                                                    this.removeVersionField(
                                                      index
                                                    )
                                                  }
                                                >
                                                  <i class="fa fa-minus pointer"></i>
                                                </a>
                                              ) : (
                                                ""
                                              )}

                                              <a onClick={this.addVersionField}>
                                                <i class="fa fa-plus pointer"></i>
                                              </a>
                                            </div>
                                          ) : (
                                            <div className="col-lg-2 plusiconadd">
                                              <a
                                                onClick={() =>
                                                  this.removeVersionField(index)
                                                }
                                              >
                                                <i class="fa fa-minus pointer"></i>
                                              </a>
                                            </div>
                                          )}
                                        </div>
                                      ))}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            {/* </form> */}
                          </div>
                        </div>
                        <div className="col-lg-12  float-left mt-3 mb-3 text-center d-flex justify-content-center">
                          <a
                            onClick={this.props.toggleModal}
                            className="btn btn-default DefaultButton"
                          >
                            Cancel
                          </a>
                          <button
                            type="submit"
                            className="btn btn-default DefaultButton AquaButton"
                            style={{ marginLeft: "5px" }}
                          >
                            Save
                          </button>
                        </div>
                      </div>
                    </form>
                  )}
                </Formik>
              )}
            </div>
          </div>
          <Loader loading={this.state.loading} />
        </Modal>
      </>
    );
  };
}


const mapStateToProps = state => ({
  // errorMessage: state.auth.errorMessage
});

export default compose(
  connect(mapStateToProps, actions)
)(Edit);