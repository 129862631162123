import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import * as actions from '../../actions';
import jwt_decode from "jwt-decode";
import { Auth } from 'aws-amplify';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
const hide = <FontAwesomeIcon icon={faEyeSlash} />;
const show = <FontAwesomeIcon icon={faEye} />;

class ConfirmForgotPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      errorMessage: '',
      passwordShown: false,
      confirmPasswordShown: false
    }

  }
  togglePasswordVisiblity = () => {
    this.setState({ passwordShown: ! this.state.passwordShown });
  };
  toggleConfirmPasswordVisiblity = () => {
    this.setState({ confirmPasswordShown: ! this.state.confirmPasswordShown });
  };
  handleSubmit = (field) => {
    this.setState({
      loading: true, errorMessage: ''
    });
    const { code, password } = field;
    const email = localStorage.getItem("username");
    Auth.forgotPasswordSubmit(
      email,
      code,
      password)
      .then((data) => {
        Auth.signIn({
          username: email,
          password: password
        })
          .then(user => {

            if (user.signInUserSession.idToken) {
              let id_token = user.signInUserSession.idToken.jwtToken
              localStorage.setItem("id_token", id_token);
              var decoded = jwt_decode(id_token);
              localStorage.setItem("user", JSON.stringify(decoded));
              localStorage.setItem("userEmail", decoded.email);
              localStorage.removeItem("username");
              window.location = process.env.PUBLIC_URL + "/dashboard";
            } else {
              if (localStorage.getItem("user") != null) {
                window.location = process.env.PUBLIC_URL + "/dashboard";
              }
            }
          })
          .catch(err => {
            const message = String(err).split(':')[1];
            this.setState({
              loading: false,
              errorMessage: message
            });
          });
      })
      .catch(err => {
        const message = String(err).split(':')[1];
        this.setState({
          loading: false,
          errorMessage: message
        });
      });
  }

  componentDidMount = () => {
    if (!localStorage.getItem("username")) {
      window.location = process.env.PUBLIC_URL + "/login";
    }
  }

  render() {
    return (
      <div class="page-content loginPage">
        <div class="content-wrapper">
          <div class="content d-flex justify-content-end align-items-center lgn_pge p-0">
            <Formik
              initialValues={{
                code: '',
                password: '',
                confirmpassword: ''
              }}
              validationSchema={Yup.object().shape({
                code: Yup.string().matches(/^[0-9]{6}$/, 'Code is invalid')
                  .required('Code is required'),
                password: Yup.string()
                  .min(8, 'Password must be at least 8 characters')
                  .matches(
                    /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
                    "Password must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character")
                  .required('Password is required'),
                confirmpassword: Yup.string()
                  .min(8, 'Password must be at least 8 characters')
                  .when("password", {
                    is: (val) => (val && val.length > 0 ? true : false),
                    then: Yup.string().oneOf(
                      [Yup.ref("password")],
                      "Both password need to be the same"
                    ),
                  })
                  .required("Confirm Password Required"),

              })}
              onSubmit={this.handleSubmit}
              render={({ errors, status, touched }) => (
                <Form class="login-form LoginAdmin mr-5"                    >
                  <div class="card b-0 mb-0">
                    <div class="card-body">
                      <div class="text-center mb-3">
                        <a>
                          <img
                            src={`${process.env.PUBLIC_URL}/assets/images/logo.png`}
                          />
                        </a>
                        <span class="d-block text-muted"></span>
                      </div>
                      <div class="text-center">
                        <h5 class="mb-1">We have sent a password reset code by email. Enter it below to reset your password.</h5>
                        <span class="d-block text-muted"></span>
                      </div>
                      <div className="form-group text-left">
                        <label htmlFor="email">Code</label>
                        <Field name="code" type="text" className={'form-control' + (errors.code && touched.code ? ' is-invalid' : '')} />
                        <ErrorMessage name="code" component="div" className="invalid-feedback" />
                      </div>
                      <div className="form-group text-left">
                        <label htmlFor="password">New Password</label>
                        <Field name="password">
                          {({
                            field, // { name, value, onChange, onBlur }
                            form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
                            meta,
                          }) => (
                            <div className={'form-control form-password' + (errors.password && touched.password ? ' is-invalid' : '')}>
                              <input type={this.state.passwordShown ? 'text' : 'password'} {...field} />
                              <i onClick={this.togglePasswordVisiblity}>{this.state.passwordShown? show : hide}</i>
                            </div>
                          )}
                        </Field>
                        <ErrorMessage name="password" component="div" className="invalid-feedback" />
                      </div>
                      <div className="form-group text-left">
                        <label htmlFor="password">Enter New Password Again</label>
                        <Field name="confirmpassword">
                          {({
                            field, // { name, value, onChange, onBlur }
                            form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
                            meta,
                          }) => (
                            <div className={'form-control form-password' + (errors.confirmpassword && touched.confirmpassword ? ' is-invalid' : '')}>
                              <input type={this.state.confirmPasswordShown ? 'text' : 'password'} {...field} />
                              <i onClick={this.toggleConfirmPasswordVisiblity}>{this.state.confirmPasswordShown? show : hide}</i>
                            </div>
                          )}
                        </Field>
                        <ErrorMessage name="confirmpassword" component="div" className="invalid-feedback" />
                      </div>
                      {this.state.errorMessage &&
                        <div className="form-group text-center error-msg">
                          {this.state.errorMessage}
                        </div>}
                      <div className="form-group">
                        <button type="submit" disabled={this.state.loading} className="btn btn-primary mr-2">Change Password</button>
                        <div class="contact-us-text mt-2">
                          <p>Contact us: </p>
                          <div><span><i class="fa fa-envelope"></i> jiban.gogoi@joikhealth.com </span><span><i class="fa fa-envelope"></i>admin@joikhealth.com</span></div></div>
                      </div>

                    </div>
                  </div>
                  <p class="copyright-text text-center">
                    © 2021 Joik Health Limited. All rights reserved.
                  </p>
                </Form>
              )}
            />
          </div>
        </div>
      </div>
    );
  };

}

const mapStateToProps = state => ({
});

export default compose(
  connect(mapStateToProps, actions)
)(ConfirmForgotPassword);