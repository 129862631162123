import React from 'react';
import { Provider } from 'react-redux';
import configureStore from '../store/configureStore';
import 'react-toastify/dist/ReactToastify.css';
import "font-awesome/css/font-awesome.min.css";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import AppRouter from '../router/AppRouter';


const App = () => {
    const store = configureStore();
    return (
        <Provider store={store}>
            <AppRouter />            
        </Provider>
    );
}
export { App as default };

