import React, { Component, Fragment,useState, useMemo  } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import * as actions from '../../../actions';
import { Formik } from "formik";
import { Link } from 'react-router-dom';

import { toast } from 'react-toastify';
import Modal from "react-bootstrap/Modal";
import ContactData from '../../Common/ContactData'
import SiteContactList from '../../Common/SiteContactList'
import Loader from '../../../components/Loader';
import Select from "../../../components/form/Select";
import Text from "../../../components/form/Text";
import { Form } from 'react-bootstrap';
import DatePicker from "react-date-picker";

const MODULE_BASE_ROUTE = 'accounts';
const MODULE_NAME = 'Site Contact';

const INITIAL_VALUES = {
  accountType: "",
  joikId: "",
  name: "",
  fname:"",
  lname:"",
  addressLine1: "",
  addressLine2: "",
  city: "",
  state: "",
  postcode: "",
  countryCode: "",
  email: "",
  landlineNumber: "",
  fax: "",
  effectiveDate:"",
  siteStatus:"",
  
 
};

class EditSiteContact extends Component {
  constructor(props) {
    super(props);

    this.state = {
      detail: INITIAL_VALUES,
      responseFetched: false,
      loading: false,
      accountSelectType: "",
      accountListModel: false,
      SponsorListModel: false,
      selectedAccountName: "",
      selectedAccountId: "",
      shippingContactSelectedId: "",
      selectedAccountObj: {
        id: "",
        joikId: "",
        accountType: "",
        name: "",
        address: {
          addressLine1: "",
          addressLine2: "",
          city: "",
          state: "",
          postcode: "",
          countryCode: "",
        },
        contactInfo: { email: "", landlineNumber: "" },
      },
      shippingAccountObj: {
        id: "",
        joikId: "",
        accountType: "",
        account: {},
        firstName: "",
        lastName: "",
        address: {
          addressLine1: "",
          addressLine2: "",
          city: "",
          state: "",
          postcode: "",
          countryCode: "",
        },
        contactInfo: { email: "", landlineNumber: "" },
      },
      date: new Date(),
    };

    this.getDetails();
  }

  validate = (values) => {
    let errors = {};

    if (!values.contactStatus) {
      errors.contactStatus = "Site Contact status is required.";
    }

    // if (!values.effectiveDate) {
    //   errors.date = "Site Contact Status Effective Date  is required.";
    // }

    return errors;
  };

  toggleState = (key) => {
    this.setState((prevState, props) => ({
      [key]: !prevState[key],
    }));
  };

  handleSubmit = async (values, { setSubmitting, resetForm }) => {
    this.setState(() => ({
      loading: true,
    }));

    const modifiedValues = {
      contactStatus: values.contactStatus,
      study: {
        id: String(window.location).split("Study-detail/")[1],
      },

      contactType: this.state.shippingAccountObj.contactType,

      contact: {
        id: this.state.shippingAccountObj.id,
      },

      effectiveDate: this.state.date,
    };

    await this.props.apiJSON(
      {
        url: `study-contact/${this.state.detail.id}`,
        method: "PUT",
        data: modifiedValues,
      },
      () => {
        setTimeout(() => {
          setSubmitting(false);
          toast(`${MODULE_NAME} Updated successfully.`, { type: "success" });
          this.props.setParentState(() => ({
            isAddModal: false,
            loading: false,
          }));
          this.props.rerouteToList();
        }, 1500);
        //console.log(this.props);
      }
    );

    setTimeout(() => {
      this.setState(() => ({
        loading: false,
      }));
    }, 2000);
  };

  onChange = (val) => {
    var value = "";

    if (val != null) {
      value = new Date(val.getTime() - val.getTimezoneOffset() * 60000);
    }

    this.setState({
      date: value,
    });
  };

  openAssignAccountList = (type) => {
    console.log(type);
    this.setState(() => ({
      accountSelectType: type,
      accountListModel: true,
    }));
  };

  openSponsorList = (Type) => {
    this.setState(() => ({
      SponsorListModel: true,
    }));
  };

  getDetails = () => {
    this.setState(() => ({
      loading: true,
    }));

    this.props.get(
      {
        url: `study-contact/${this.props.id}`,
        errorUrl: `/${MODULE_BASE_ROUTE}`,
        history: this.props.history,
      },
      async (response) => {
        console.log(response, "======>now get it");

        this.setState(() => ({
          detail: response,
          date: new Date(response.effectiveDate),
          shippingAccountObj: response.contact,
          responseFetched: true,
          loading: false,
        }));
      }
    );
  };

  // componentDidMount() {
  //   this.getDetails();
  // }

  closeAssignModel = () => {
    this.setState(() => ({
      accountListModel: false,
    }));
  };

  closeSponsorModel = () => {
    this.setState(() => ({
      SponsorListModel: false,
    }));
  };

  componentDidUpdate() {}

  selectedAccount = (account, type) => {
    this.setState(() => ({
      selectedAccountObj: account,
      selectedAccountId: account.id,
      accountListModel: false,
    }));
  };

  selectSponser = (account, type) => {
    console.log(account);

    this.setState({
      shippingAccountObj: account,
      shippingContactSelectedId: account.id,
      SponsorListModel: false,
    });
  };

  onEscapeKeyDown = (e) => {
    e.preventDefault();
  };

  render = () => {
    let { detail } = this.state;

    return (
      <>
        <Modal
          show={this.props.modalIsOpen}
          onHide={this.props.toggleModal}
          onShow={this.getDetails}
          animation={false}
          className={"CustomModal width900"}
          backdrop={false}
          onEscapeKeyDown={this.onEscapeKeyDown}
        >
          <div className="content">
            <div className="row">
              {Object.keys(detail).length > 0 && (
                <Formik
                  initialValues={this.state.detail}
                  validate={this.validate}
                  onSubmit={this.handleSubmit}
                  enableReinitialize
                >
                  {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    setFieldValue,
                    isSubmitting,
                  }) => (
                    <form
                      onSubmit={handleSubmit}
                      autoComplete="off"
                      className="float-left FormEditDesign"
                    >
                      <div className="modal-header">
                        <h4 className="modal-title">Edit Site Contact</h4>
                        <button
                          type="button"
                          onClick={this.props.toggleModal}
                          className="close"
                        >
                          &times;
                        </button>
                      </div>
                      <div className="col-md-12">
                        <div className="col-lg-12  float-left">
                          <div className="modal-body modalpadding">
                            <div className="row"></div>
                            <div className="col-lg-12">
                              <div className="row">
                                {/* <!--First--> */}
                                <div className="col-md-12">
                                  <div className="row"></div>

                                  <div className="row">
                                    <div className="col-lg-12 mt-1">
                                      <h4>Assign Contact</h4>
                                    </div>
                                    <div className="col-lg-12 mt-1 mb-2">
                                      <a
                                        onClick={() => this.openSponsorList()}
                                        class="DefaultButton "
                                      >
                                        Assign Contact
                                      </a>
                                    </div>

                                    <div className="row">
                                      <div className="col-lg-12  float-left">
                                        <ContactData
                                          deatils={
                                            this.state.shippingAccountObj
                                          }
                                        ></ContactData>
                                      </div>
                                    </div>
                                  </div>

                                  <div className="row mt-2">
                                    <div className="col-lg-6 col-md-12">
                                      <div className="form-group required">
                                        <Select
                                          title="Site Contact Status"
                                          name="contactStatus"
                                          placeholder="Site Contact Status"
                                          options={[
                                            {
                                              value: "Active",
                                              label: "Active",
                                            },
                                            {
                                              value: "Inactive",
                                              label: "Inactive",
                                            },
                                          ]}
                                          id="contactStatus"
                                          errors={errors}
                                          touched={touched}
                                          values={values}
                                          handleChange={(e) => {
                                            const value = e.target.value;
                                            setFieldValue(
                                              "contactStatus",
                                              value
                                            );
                                          }}
                                          handleBlur={handleBlur}
                                          disabled={false}
                                        />
                                      </div>
                                    </div>
                                    <div className="col-lg-6 col-md-12">
                                      <div className="form-group required">
                                        {/* <Text
                                          title="Site Contact Status Effective Date"
                                          name="effectiveDate"
                                          placeholder="Date"
                                          errors={errors}
                                          touched={touched}
                                          values={values}
                                          handleChange={handleChange}
                                          handleBlur={handleBlur}
                                          disabled={false}
                                          type="Date"
                                        /> */}
                                      </div>

                                      <label class="form-control-label">
                                        Contact Status Effective Date
                                      </label>

                                      <DatePicker
                                        utcOffset={0}
                                        dateFormat="DD-MMM HH:mm"
                                        onChange={this.onChange}
                                        value={this.state.date}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            {/* </form> */}
                          </div>
                        </div>
                        <div className="col-lg-12  float-left mt-3 mb-3 text-center d-flex justify-content-center">
                          <a
                            onClick={this.props.toggleModal}
                            className="btn btn-default DefaultButton"
                          >
                            Cancel
                          </a>
                          <button
                            type="submit"
                            className="btn btn-default DefaultButton AquaButton"
                            style={{ marginLeft: "5px" }}
                          >
                            Save
                          </button>
                        </div>
                      </div>
                    </form>
                  )}
                </Formik>
              )}
            </div>
          </div>

          <SiteContactList
            type={this.state.accountSelectType}
            modalIsOpen={this.state.SponsorListModel}
            closeAssignModel={this.closeSponsorModel}
            selectedAccountInfo={this.selectSponser}
          ></SiteContactList>

          <Loader loading={this.state.loading} />
        </Modal>
      </>
    );
  };
}


const mapStateToProps = state => ({
  // errorMessage: state.auth.errorMessage
});

export default compose(
  connect(mapStateToProps, actions)
)(EditSiteContact);