import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import * as actions from '../../actions';
import jwt_decode from "jwt-decode";
import { Auth } from 'aws-amplify';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
const hide = <FontAwesomeIcon icon={faEyeSlash} />;
const show = <FontAwesomeIcon icon={faEye} />;

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      errorMessage: '',
      passwordShown: false,
    }
  }
  togglePasswordVisiblity = () => {
    this.setState({ passwordShown: ! this.state.passwordShown });
  };
  handleSubmit = (field) => {
    // e.preventDefault();
    this.setState({ loading: true, errorMessage: '' });
    const { email, password } = field;
    Auth.signIn({
      username: email,
      password: password
    })
      .then(user => {
        if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
          localStorage.setItem("username", email);
          localStorage.setItem("tempPassword", password);
          window.location = process.env.PUBLIC_URL + "/changePassword";
        } else {
          if (user.signInUserSession.idToken) {
            let id_token = user.signInUserSession.idToken.jwtToken
            localStorage.setItem("id_token", id_token);
            var decoded = jwt_decode(id_token);
            localStorage.setItem("user", JSON.stringify(decoded));
            localStorage.setItem("userEmail", decoded.email);
            window.location = process.env.PUBLIC_URL + "/dashboard";
          } else {
            if (localStorage.getItem("user") != null) {
              window.location = process.env.PUBLIC_URL + "/dashboard";
            }
          }
        }
      })
      .catch(err => {

        const message = String(err).split(':')[1];
        this.setState({
          loading: false,
          errorMessage: message
        });
      });
  }

  componentDidMount = () => {
    if (localStorage.getItem("user") != null) {
      window.location = process.env.PUBLIC_URL + "/dashboard";
    }
  }

  render() {
    return (
      <div class="page-content loginPage">
        <div class="content-wrapper">
          <div class="content d-flex justify-content-end align-items-center lgn_pge p-0">
            <Formik
              initialValues={{
                email: '',
                password: '',
              }}
              validationSchema={Yup.object().shape({
                email: Yup.string()
                  .email('Email is invalid')
                  .required('Email is required'),
                password: Yup.string()
                  .required('Password is required')
              })}
              onSubmit={this.handleSubmit}
              render={({ errors, status, touched }) => (
                <Form class="login-form LoginAdmin mr-5"                    >
                  <div class="card b-0 mb-0">
                    <div class="card-body">
                      <div class="text-center mb-3">
                        <a>
                          <img
                            src={`${process.env.PUBLIC_URL}/assets/images/logo.png`}
                          />
                        </a>
                        <span class="d-block text-muted"></span>
                      </div>
                      <div class="text-center">
                        <h5 class="mb-1">Sign in with your email and password</h5>
                        <span class="d-block text-muted"></span>
                      </div>
                      <div className="form-group text-left">
                        <label htmlFor="email">Email</label>
                        <Field name="email" type="text" className={'form-control' + (errors.email && touched.email ? ' is-invalid' : '')} />
                        <ErrorMessage name="email" component="div" className="invalid-feedback" />
                      </div>
                      <div className="form-group text-left">
                        <label htmlFor="password">Password</label>
                        <Field name="password">
                          {({
                            field, // { name, value, onChange, onBlur }
                            form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
                            meta,
                          }) => (
                            <div className={'form-control form-password' + (errors.password && touched.password ? ' is-invalid' : '')}>
                              <input type={this.state.passwordShown ? 'text' : 'password'} {...field} />
                              <i onClick={this.togglePasswordVisiblity}>{this.state.passwordShown? show : hide}</i>
                            </div>
                          )}
                        </Field>
                        <ErrorMessage name="password" component="div" className="invalid-feedback" />
                      </div>
                      {this.state.errorMessage &&
                        <div className="form-group text-center error-msg">
                          {this.state.errorMessage}
                        </div>}
                      <div className="form-group">
                        <button type="submit" disabled={this.state.loading} className="btn btn-primary mr-2">Sign in</button>
                        <a href="/forgotPassword">Forgot your password?</a>
                        <div class="contact-us-text mt-2">
                          <p>Contact us: </p>
                          <div><span><i class="fa fa-envelope"></i> jiban.gogoi@joikhealth.com </span><span><i class="fa fa-envelope"></i>admin@joikhealth.com</span></div></div>
                      </div>

                    </div>
                  </div>
                  <p class="copyright-text text-center">
                    © 2021 Joik Health Limited. All rights reserved.
                  </p>
                </Form>
              )}
            />
          </div>
        </div>
      </div>
    );
  };

}

const mapStateToProps = state => ({
});

export default compose(
  connect(mapStateToProps, actions)
)(Login);