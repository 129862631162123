import { countries } from 'country-data';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { compose } from 'redux';
import * as actions from '../../../actions';
import DeleteModal from '../../../components/DeleteModal';
import Loader from '../../../components/Loader';
import EditMileStonePayment from './EditMileStonePayment';
import SwitchButton from '../../../components/form/SwitchButton';

const MODULE_BASE_ROUTE = "Milestone"
const ITEM = 'Milestone Payment';

const DELETE_API = 'milestone-payment-country/deactivate';

class MileStonePaymentDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showDetails: false,
      countryCode: "",
      details: {},
      isEditModal: false
    };
  }

  getDetails = () => {

    this.setState(() => ({
      loading: true,
    }));

    this.props.get({ url: `milestone-payment-country/${this.props.siteData.id}`, errorUrl: `/${MODULE_BASE_ROUTE}`, history: this.props.history }, async (response) => {


      this.setState(() => ({
        details: response,
        responseFetched: true,
        countryCode: response.countryCode,
        loading: false,
      }))
    });
  }


  componentDidMount = () => {
    this.getDetails()
  }

  rerouteToList = () => {
    this.getDetails()
  }



  hideDetails = () => {
    this.props.hideDetails()
  }



  handleConfirmDelete = (e, id) => {
    e.preventDefault();
    this.setState((prevState, props) => ({
      deleteId: id,
      confirmDelete: true,
      isRowMenu: false,
    }));

  }

  toggleState = (key) => {
    this.setState((prevState, props) => ({
      [key]: !prevState[key],
    }));
  }

  render() {

    let {
      details
    } = this.state;


    return (
      <div class="RowDesign">
        <div class="col-lg-12 float-left mb-3 text-left">
          <h3 class="mb-0 HeadingData">Milestone Payments Details</h3>
        </div>

        <ul class="ClientDetails mb-0">
          <li class="FullWidthLi"><strong>Country</strong>{countries[this.state.countryCode].name}</li>
          <li class="FullWidthLi"><strong>Currency</strong>{details.currency}</li>
          <li class="FullWidthLi"><strong>Reimbursement Limit</strong>{Number(details.paymentLimit).toFixed(2)}</li>
          <li class="FullWidthLi"><strong>Milestone Payments Allowed</strong>
            <SwitchButton
              checked={details.milestonePaymentsAllowed}
              isDisabled={true}
            />
          </li>
        </ul>
        { details.milestonePaymentsAllowed &&
        <div class="MilestoneData">
          <table class="table">
            <thead>
              <tr>
                <th><strong>Milestone</strong></th>
                <th><strong>Milestone Payment</strong></th>
              </tr>
            </thead>
            <tbody>

              {
                Object.keys(details).length > 0 && (

                  details.milestonePayments.map((value, index) => {
                    return (
                      <Fragment>
                        <tr>
                          <td>{value.milestone.name}</td>
                          <td>
                            {value.payment > 0 ? Number(value.payment).toFixed(2) : ''}
                          </td>
                        </tr>
                      </Fragment>
                    );
                  })
                )
              }

            </tbody>
          </table>
        </div>
        }
        <div class="col-lg-12  float-left mt-3 mb-3 AllFooterButton text-center d-flex justify-content-center">
          <a onClick={e => this.props.hideDetails()} class="DefaultButton">Back</a>
          <a onClick={() => this.toggleState('isEditModal')} class="DefaultButton AquaButton" data-toggle="modal" data-target="#EditSiteContact">Edit</a>
          <Link onClick={e => this.handleConfirmDelete(e, this.state.details.id)} className="DefaultButton">Delete</Link>
        </div>

        <DeleteModal
          item={ITEM}
          modalIsOpen={this.state.confirmDelete}
          setParentState={(newState) => this.setState(newState)}
          deleteId={this.state.deleteId} r
          rerouteToList={this.hideDetails}
          api={this.props.deleteMethod}
          apiName={DELETE_API}
        />
        <EditMileStonePayment
          id={this.props.siteData.id}
          details={this.state.details}
          item={ITEM}
          reloadList={this.rerouteToList}
          editData={this.state.editData}
          setParentState={(newState) => this.setState(newState)}
          modalIsOpen={this.state.isEditModal}
          toggleModal={() => this.toggleState('isEditModal')}

        >


        </EditMileStonePayment>
        <Loader
          loading={this.state.loading}
        />


      </div>
    );
  }
}


const mapStateToProps = state => ({
  // errorMessage: state.auth.errorMessage
});

export default compose(
  connect(mapStateToProps, actions)
)(MileStonePaymentDetails);