import { countries } from 'country-data';
import React, { Component } from 'react';
import { Form } from 'react-bootstrap';
import ReactPaginate from 'react-paginate';
import { connect } from 'react-redux';
import SearchField from "react-search-field";
import { toast } from 'react-toastify';
import { compose } from 'redux';
import * as actions from '../../actions';
import Loader from '../../components/Loader';
import { BASE_URL } from '../../utils/constants';

const MODULE_NAME = 'Joik Card';

class JoikCardList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      list: [],
      totalRecords: 0,
      itemPerPage: 10,
      responseFetched: false,
      curentPage: 1,
      pageCount: 1,
      searchValue: "",
      loading: false,
    };
  }

  setPageSize = (num) => {
    if (num !== "Item per page") {
      this.setState(
        {
          itemPerPage: num,
        },
        () => {
          this.getListing(this.state.searchValue, 1);
        }
      );
    }
  };

  handlePageClick = (val) => {
    let pagNum = val.selected + 1;
    this.getListing(this.state.searchValue, pagNum);
  };

  getListing = (sarchStr, pageNum) => {
    this.setState(() => ({
      loading: true,
    }));

    this.props.get(
      {
        url: `payment-card?pageNum=${pageNum}&pageSize=${this.state.itemPerPage}&sortField=id&sortOrder=Desc&word=${sarchStr}`,
      },
      async (response) => {
        this.setState(() => ({
          list: response.entryList,
          responseFetched: true,
          loading: false,
          pageCount: response.totalEntries / this.state.itemPerPage,
        }));
      }
    );
  };

  onSearchClick = (value) => {
    this.setState({
      searchValue: value,
    });
    this.setState(
      {
        name: "value",
      },
      () => {
        this.getListing(this.state.searchValue, this.state.curentPage);
      }
    );
  };

  componentDidMount = () => {
    this.getListing(this.state.searchValue, 1);
  };

  changeCardStatus = async (item) => {
    console.log(item);
    var newStatus = "Activated";
    if (item.cardStatus == "Allocated" || item.cardStatus == "Deactivated") {
      newStatus = "Activated";
    } else {
      newStatus = "Deactivated";
    }

    const modifiedValues = [
      {
        op: "replace",
        path: "/cardStatus",
        value: newStatus,
      },
    ];

    this.setState(() => ({
      loading: true,
    }));

    await this.props.patch(
      { url: `payment-card/${item.id}`, method: "PATCH", data: modifiedValues },
      (response) => {
        console.log(response.status, "test error");

        if (response.status) {
          setTimeout(() => {
            toast(`${MODULE_NAME} updated successfully.`, { type: "success" });
            this.setState({
              loading: false,
            });
            this.getListing(this.state.searchValue, this.state.curentPage);
          }, 1500);
        } else {
          // setTimeout(() => {
          toast(response.data, { type: "error" });
          this.setState({
            loading: false,
          });

          // }, 1500);
        }
      }
    );
  };

  downloadItem = (item, type) => {
    let filename =
      "Joik Cards " +
      String(new Date().toISOString().slice(0, 10)) +
      " " +
      new Date().toLocaleTimeString("it-IT").slice(0, 10) +
      type;

    this.props.getFile(
      {
        url: item,
      },
      async (response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", filename);

        // Append to html link element page
        document.body.appendChild(link);

        // Start download
        link.click();

        // Clean up and remove the link
        link.parentNode.removeChild(link);
      }
    );
  };

  render() {
    let { list } = this.state;
    return (
      <>
        {/* <!-- Content area --> */}
        <div className="content">
          {/* <!-- Dashboard content --> */}
          <div className="row PageHeading mb-3">
            <div className="col-lg-12">
              <h3 className="mb-0">Joik Cards</h3>
            </div>
          </div>

          <div className="row PageHeading">
            <div className="col-lg-12">
              <div className="TableData">
                <div className="TableTopText">
                  <h3>View All Joik Cards</h3>
                  <div class="HedRgtData">
                    <Form.Control
                      as="select"
                      size="lg"
                      onChange={(e) => {
                        this.setPageSize(e.target.value);
                      }}
                    >
                      <option selected>Item per page</option>
                      <option>5</option>
                      <option selected>10</option>
                      <option>15</option>
                      <option>20</option>
                    </Form.Control>

                    <SearchField
                      placeholder=""
                      searchText=""
                      onChange={this.onSearchClick}
                      classNames="AllSearchTabledata form-control"
                    />
                    <div class="tooltipDiv">
                      <a
                        onClick={() =>
                          this.downloadItem(
                            `${BASE_URL}/payment-card/report/excel?&word=${this.state.searchValue}`,
                            ".xlsx"
                          )
                        }
                        className="DefaultButton ExcelButton"
                      ></a>
                      <p className="showTooltip">Download Excel Report</p>
                    </div>
                    <div class="tooltipDiv">
                      <a
                        onClick={() =>
                          this.downloadItem(
                            `${BASE_URL}/payment-card/report/csv?&word=${this.state.searchValue}`,
                            ".csv"
                          )
                        }
                        // href={`${BASE_URL}/payment-card/report/csv?&word=${this.state.searchValue}`}
                        className="DefaultButton CsvButton"
                      ></a>
                      <p className="showTooltip">Download CSV Report</p>
                    </div>
                  </div>
                </div>

                <div class="TableScroll">
                  <table className="table datatable-basic">
                    <thead>
                      <tr>
                        <th>Joik Card Id</th>
                        <th>Project Number</th>
                        <th>Site Number</th>
                        <th>Account Name</th>
                        <th>Principal Investigator</th>
                        <th>Subject Number</th>
                        <th>First Name</th>
                        <th>Last Name</th>
                        <th>Country</th>
                        <th>Request Type</th>
                        <th>Joik Card No</th>
                        <th>Allocation Date</th>
                        <th>Card Status</th>
                        <th class="text-left">Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {list && list.length === 0 && (
                        <tr>
                          <td colSpan="8">
                            <center>
                              {!this.state.responseFetched
                                ? "Loading..."
                                : "No data found"}
                            </center>
                          </td>
                        </tr>
                      )}
                      {list &&
                        list.length > 0 &&
                        list.map((item, index) => {
                          return (
                            <tr key={`account_${index}`}>
                              <td>{item.joikId}</td>
                              <td>{item.subject.site.study.studyNumber}</td>
                              <td>{item.subject.site.siteId}</td>
                              <td>{item.subject.site.account.name}</td>
                              <td>
                                {item.subject.site.principalInvestigator != null
                                  ? item.subject.site.principalInvestigator.firstName +
                                    " " +
                                    item.subject.site.principalInvestigator.lastName
                                  : ""}
                              </td>
                              <td>{item.subject.subjectNumber}</td>
                              <td>{item.subject.firstName}</td>
                              <td>{item.subject.lastName}</td>
                              {/* <td>{item.subject.address.countryCode}</td> */}
                              <td>
                                {
                                  countries[item.subject.site.account.address.countryCode]
                                    .name
                                }
                              </td>
                              <td>
                                {item.cardRequestType != null
                                  ? item.cardRequestType
                                  : ""}
                              </td>
                              <td>{item.identificationNumber}</td>
                              <td>{item.allocationDate}</td>
                              <td>{item.cardStatus}</td>
                              <td class="Td_A_Dsgn">
                                {
                                  <a
                                    onClick={() => this.changeCardStatus(item)}
                                    class="DefaultButton AquaButton"
                                    aria-expanded="false"
                                  >
                                    {item.cardStatus == "Allocated"
                                      ? "Activate"
                                      : item.cardStatus == "Replaced" ||
                                        item.cardStatus == "Activated" ||
                                        item.cardStatus == "Completed"
                                      ? "Deactivate"
                                      : "Reactivate"}
                                  </a>
                                }
                              </td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div className="col-lg-12">
              {this.state.pageCount <= 1 ? null : (
                <ReactPaginate
                  previousLabel={"<"}
                  nextLabel={">"}
                  breakLabel={"..."}
                  breakClassName={"break-me"}
                  pageCount={this.state.pageCount}
                  marginPagesDisplayed={5}
                  pageRangeDisplayed={1}
                  onPageChange={this.handlePageClick}
                  containerClassName={"pagination"}
                  subContainerClassName={"pages pagination"}
                  activeClassName={"active"}
                />
              )}
            </div>
          </div>
          <Loader loading={this.state.loading} />
        </div>
      </>
    );
  }
}
 

const mapStateToProps = state => ({
});

export default compose(
  connect(mapStateToProps, actions)
)(JoikCardList);