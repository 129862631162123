import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import * as actions from '../../actions';
import jwt_decode from "jwt-decode";
import { Auth } from 'aws-amplify';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';

class ForgotPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      errorMessage: ''
    }

  }

  handleSubmit = (field) => {
    this.setState({ loading: true, errorMessage: '' });
    const { email } = field;
    // Send confirmation code to user's email
    Auth.forgotPassword(email)
      .then((data) => {
        localStorage.setItem("username", email);
        window.location = process.env.PUBLIC_URL + "/confirmForgotPassword";
      })
      .catch(err => {
        const message = String(err).split(':')[1];
        this.setState({
          loading: false,
          errorMessage: message
        });
      });
  }

  componentDidMount = () => {

  }

  render() {

    return (
      <div class="page-content loginPage">
        <div class="content-wrapper">
          <div class="content d-flex justify-content-end align-items-center lgn_pge p-0">
            <Formik
              initialValues={{
                email: '',
              }}
              validationSchema={Yup.object().shape({
                email: Yup.string()
                  .email('Email is invalid')
                  .required('Email is required')
              })}
              onSubmit={this.handleSubmit}
              render={({ errors, status, touched }) => (
                <Form class="login-form LoginAdmin mr-5"                    >
                  <div class="card b-0 mb-0">
                    <div class="card-body">
                      <div class="text-center mb-3">
                        <a>
                          <img
                            src={`${process.env.PUBLIC_URL}/assets/images/logo.png`}
                          />
                        </a>
                        <span class="d-block text-muted"></span>
                      </div>
                      <div class="text-center">
                        <h4 class="mb-1">Forgot your password?</h4>
                        <span class="d-block text-muted"></span>
                      </div>
                      <div class="text-center">
                        <h5 class="mb-1">Enter your Email below and we will send a message to reset your password</h5>
                        <span class="d-block text-muted"></span>
                      </div>
                      <div className="form-group text-left">
                        <label htmlFor="email">Email</label>
                        <Field name="email" type="text" className={'form-control' + (errors.email && touched.email ? ' is-invalid' : '')} />
                        <ErrorMessage name="email" component="div" className="invalid-feedback" />
                      </div>
                      {this.state.errorMessage &&
                        <div className="form-group text-center error-msg">
                          {this.state.errorMessage}
                        </div>}
                      <div className="form-group">
                        <button type="submit" className="btn btn-primary mr-2">Reset my password</button>
                        <div class="contact-us-text mt-2">
                          <p>Contact us: </p>
                          <div><span><i class="fa fa-envelope"></i> jiban.gogoi@joikhealth.com </span><span><i class="fa fa-envelope"></i>admin@joikhealth.com</span></div></div>
                      </div>

                    </div>
                  </div>
                  <p class="copyright-text text-center">
                    © 2021 Joik Health Limited. All rights reserved.
                  </p>
                </Form>
              )}
            />
          </div>
        </div>
      </div>
    );
  };

}

const mapStateToProps = state => ({
});

export default compose(
  connect(mapStateToProps, actions)
)(ForgotPassword);