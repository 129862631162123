import React, { Component, Fragment } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import * as actions from '../../actions';
import {countries} from 'country-data';



class AccountData extends Component {


    constructor(props) {
        super(props);
    
      }
   
    render() { 

      const {deatils} = this.props;
                           return ( <Fragment>

                                     <ul className="ClientDetails">
                                     <li><strong>Joik Account ID</strong>{deatils.joikId}</li>
                                      <li><strong>Account Type</strong>{deatils.accountType}</li>
                                     <li><strong>Account Name</strong>{deatils.name}</li>
                                      <li><strong>Address Line 1</strong>{deatils.address.addressLine1}</li>
                                      <li><strong>Address Line 2</strong>{deatils.address.addressLine2}</li>
                                      <li><strong>City</strong>{deatils.address.city}</li>
                                      <li><strong>State</strong>{deatils.address.state}</li>
                                      <li><strong>Postcode</strong>{deatils.address.postcode}</li>
                                      <li><strong>Country</strong>{deatils.address.countryCode!=""?countries[deatils.address.countryCode].name:''}</li>
                                      <li><strong>Email ID</strong>{deatils.contactInfo.email}</li>
                                      <li><strong>Phone</strong>{deatils.contactInfo.landlineNumber}</li>                                    
                                    </ul>
            
          
            
            </Fragment> );
    }
}
 


const mapStateToProps = state => ({
    // errorMessage: state.auth.errorMessage
  });
  
  export default compose(
    connect(mapStateToProps, actions)
  )(AccountData);