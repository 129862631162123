export const countryMobileCodeList =
[{
  value: "CA",
  label: "+1"
},
{
  value: "US",
  label: "+1"
},
{
  value: "KZ",
  label: "+7"
},
{
  value: "RU",
  label: "+7"
},
{
  value: "EG",
  label: "+20"
},
{
  value: "ZA",
  label: "+27"
},
{
  value: "GR",
  label: "+30"
},
{
  value: "NL",
  label: "+31"
},
{
  value: "BE",
  label: "+32"
},
{
  value: "FR",
  label: "+33"
},
{
  value: "ES",
  label: "+34"
},
{
  value: "HU",
  label: "+36"
},
{
  value: "IT",
  label: "+39"
},
{
  value: "RO",
  label: "+40"
},
{
  value: "CH",
  label: "+41"
},
{
  value: "AT",
  label: "+43"
},
{
  value: "UK",
  label: "+44"
},
{
  value: "DK",
  label: "+45"
},
{
  value: "SE",
  label: "+46"
},
{
  value: "NO",
  label: "+47"
},
{
  value: "SJ",
  label: "+47"
},
{
  value: "PL",
  label: "+48"
},
{
  value: "DE",
  label: "+49"
},
{
  value: "PE",
  label: "+51"
},
{
  value: "MX",
  label: "+52"
},
{
  value: "CU",
  label: "+53"
},
{
  value: "AR",
  label: "+54"
},
{
  value: "BR",
  label: "+55"
},
{
  value: "CL",
  label: "+56"
},
{
  value: "CO",
  label: "+57"
},
{
  value: "VE",
  label: "+58"
},
{
  value: "MY",
  label: "+60"
},
{
  value: "AU",
  label: "+61"
},
{
  value: "CX",
  label: "+61"
},
{
  value: "CC",
  label: "+61"
},
{
  value: "ID",
  label: "+62"
},
{
  value: "PH",
  label: "+63"
},
{
  value: "NZ",
  label: "+64"
},
{
  value: "PN",
  label: "+64"
},
{
  value: "SG",
  label: "+65"
},
{
  value: "TH",
  label: "+66"
},
{
  value: "JP",
  label: "+81"
},
{
  value: "KR",
  label: "+82"
},
{
  value: "VN",
  label: "+84"
},
{
  value: "CN",
  label: "+86"
},
{
  value: "TR",
  label: "+90"
},
{
  value: "IN",
  label: "+91"
},
{
  value: "PK",
  label: "+92"
},
{
  value: "AF",
  label: "+93"
},
{
  value: "LK",
  label: "+94"
},
{
  value: "MM",
  label: "+95"
},
{
  value: "IR",
  label: "+98"
},
{
  value: "SS",
  label: "+211"
},
{
  value: "MA",
  label: "+212"
},
{
  value: "EH",
  label: "+212"
},
{
  value: "DZ",
  label: "+213"
},
{
  value: "TN",
  label: "+216"
},
{
  value: "LY",
  label: "+218"
},
{
  value: "GM",
  label: "+220"
},
{
  value: "SN",
  label: "+221"
},
{
  value: "MR",
  label: "+222"
},
{
  value: "ML",
  label: "+223"
},
{
  value: "GN",
  label: "+224"
},
{
  value: "CI",
  label: "+225"
},
{
  value: "BF",
  label: "+226"
},
{
  value: "NE",
  label: "+227"
},
{
  value: "TG",
  label: "+228"
},
{
  value: "BJ",
  label: "+229"
},
{
  value: "MU",
  label: "+230"
},
{
  value: "LR",
  label: "+231"
},
{
  value: "SL",
  label: "+232"
},
{
  value: "GH",
  label: "+233"
},
{
  value: "NG",
  label: "+234"
},
{
  value: "TD",
  label: "+235"
},
{
  value: "CF",
  label: "+236"
},
{
  value: "CM",
  label: "+237"
},
{
  value: "CV",
  label: "+238"
},
{
  value: "ST",
  label: "+239"
},
{
  value: "GQ",
  label: "+240"
},
{
  value: "GA",
  label: "+241"
},
{
  value: "CG",
  label: "+242"
},
{
  value: "CD",
  label: "+243"
},
{
  value: "AO",
  label: "+244"
},
{
  value: "GW",
  label: "+245"
},
{
  value: "IO",
  label: "+246"
},
{
  value: "SC",
  label: "+248"
},
{
  value: "SD",
  label: "+249"
},
{
  value: "RW",
  label: "+250"
},
{
  value: "ET",
  label: "+251"
},
{
  value: "SO",
  label: "+252"
},
{
  value: "DJ",
  label: "+253"
},
{
  value: "KE",
  label: "+254"
},
{
  value: "TZ",
  label: "+255"
},
{
  value: "UG",
  label: "+256"
},
{
  value: "BI",
  label: "+257"
},
{
  value: "MZ",
  label: "+258"
},
{
  value: "ZM",
  label: "+260"
},
{
  value: "MG",
  label: "+261"
},
{
  value: "YT",
  label: "+262"
},
{
  value: "RE",
  label: "+262"
},
{
  value: "ZW",
  label: "+263"
},
{
  value: "NA",
  label: "+264"
},
{
  value: "MW",
  label: "+265"
},
{
  value: "LS",
  label: "+266"
},
{
  value: "BW",
  label: "+267"
},
{
  value: "SZ",
  label: "+268"
},
{
  value: "KM",
  label: "+269"
},
{
  value: "SH",
  label: "+290"
},
{
  value: "ER",
  label: "+291"
},
{
  value: "AW",
  label: "+297"
},
{
  value: "FO",
  label: "+298"
},
{
  value: "GL",
  label: "+299"
},
{
  value: "GI",
  label: "+350"
},
{
  value: "PT",
  label: "+351"
},
{
  value: "LU",
  label: "+352"
},
{
  value: "IE",
  label: "+353"
},
{
  value: "IS",
  label: "+354"
},
{
  value: "AL",
  label: "+355"
},
{
  value: "MT",
  label: "+356"
},
{
  value: "CY",
  label: "+357"
},
{
  value: "FI",
  label: "+358"
},
{
  value: "BG",
  label: "+359"
},
{
  value: "LT",
  label: "+370"
},
{
  value: "LV",
  label: "+371"
},
{
  value: "EE",
  label: "+372"
},
{
  value: "MD",
  label: "+373"
},
{
  value: "AM",
  label: "+374"
},
{
  value: "BY",
  label: "+375"
},
{
  value: "AD",
  label: "+376"
},
{
  value: "MC",
  label: "+377"
},
{
  value: "SM",
  label: "+378"
},
{
  value: "VA",
  label: "+379"
},
{
  value: "UA",
  label: "+380"
},
{
  value: "RS",
  label: "+381"
},
{
  value: "ME",
  label: "+382"
},
{
  value: "XK",
  label: "+383"
},
{
  value: "HR",
  label: "+385"
},
{
  value: "SI",
  label: "+386"
},
{
  value: "BA",
  label: "+387"
},
{
  value: "MK",
  label: "+389"
},
{
  value: "CZ",
  label: "+420"
},
{
  value: "SK",
  label: "+421"
},
{
  value: "LI",
  label: "+423"
},
{
  value: "FK",
  label: "+500"
},
{
  value: "BZ",
  label: "+501"
},
{
  value: "GT",
  label: "+502"
},
{
  value: "SV",
  label: "+503"
},
{
  value: "HN",
  label: "+504"
},
{
  value: "NI",
  label: "+505"
},
{
  value: "CR",
  label: "+506"
},
{
  value: "PA",
  label: "+507"
},
{
  value: "PM",
  label: "+508"
},
{
  value: "HT",
  label: "+509"
},
{
  value: "BL",
  label: "+590"
},
{
  value: "MF",
  label: "+590"
},
{
  value: "BO",
  label: "+591"
},
{
  value: "GY",
  label: "+592"
},
{
  value: "EC",
  label: "+593"
},
{
  value: "PY",
  label: "+595"
},
{
  value: "SR",
  label: "+597"
},
{
  value: "UY",
  label: "+598"
},
{
  value: "CW",
  label: "+599"
},
{
  value: "AN",
  label: "+599"
},
{
  value: "TL",
  label: "+670"
},
{
  value: "AQ",
  label: "+672"
},
{
  value: "BN",
  label: "+673"
},
{
  value: "NR",
  label: "+674"
},
{
  value: "PG",
  label: "+675"
},
{
  value: "TO",
  label: "+676"
},
{
  value: "SB",
  label: "+677"
},
{
  value: "VU",
  label: "+678"
},
{
  value: "FJ",
  label: "+679"
},
{
  value: "PW",
  label: "+680"
},
{
  value: "WF",
  label: "+681"
},
{
  value: "CK",
  label: "+682"
},
{
  value: "NU",
  label: "+683"
},
{
  value: "WS",
  label: "+685"
},
{
  value: "KI",
  label: "+686"
},
{
  value: "NC",
  label: "+687"
},
{
  value: "TV",
  label: "+688"
},
{
  value: "PF",
  label: "+689"
},
{
  value: "TK",
  label: "+690"
},
{
  value: "FM",
  label: "+691"
},
{
  value: "MH",
  label: "+692"
},
{
  value: "KP",
  label: "+850"
},
{
  value: "HK",
  label: "+852"
},
{
  value: "MO",
  label: "+853"
},
{
  value: "KH",
  label: "+855"
},
{
  value: "LA",
  label: "+856"
},
{
  value: "BD",
  label: "+880"
},
{
  value: "TW",
  label: "+886"
},
{
  value: "MV",
  label: "+960"
},
{
  value: "LB",
  label: "+961"
},
{
  value: "JO",
  label: "+962"
},
{
  value: "SY",
  label: "+963"
},
{
  value: "IQ",
  label: "+964"
},
{
  value: "KW",
  label: "+965"
},
{
  value: "SA",
  label: "+966"
},
{
  value: "YE",
  label: "+967"
},
{
  value: "OM",
  label: "+968"
},
{
  value: "PS",
  label: "+970"
},
{
  value: "AE",
  label: "+971"
},
{
  value: "IL",
  label: "+972"
},
{
  value: "BH",
  label: "+973"
},
{
  value: "QA",
  label: "+974"
},
{
  value: "BT",
  label: "+975"
},
{
  value: "MN",
  label: "+976"
},
{
  value: "NP",
  label: "+977"
},
{
  value: "TJ",
  label: "+992"
},
{
  value: "TM",
  label: "+993"
},
{
  value: "AZ",
  label: "+994"
},
{
  value: "GE",
  label: "+995"
},
{
  value: "KG",
  label: "+996"
},
{
  value: "UZ",
  label: "+998"
},
{
  value: "BS",
  label: "+1242"
},
{
  value: "BB",
  label: "+1246"
},
{
  value: "AI",
  label: "+1264"
},
{
  value: "AG",
  label: "+1268"
},
{
  value: "VG",
  label: "+1284"
},
{
  value: "VI",
  label: "+1340"
},
{
  value: "KY",
  label: "+1345"
},
{
  value: "BM",
  label: "+1441"
},
{
  value: "GD",
  label: "+1473"
},
{
  value: "TC",
  label: "+1649"
},
{
  value: "MS",
  label: "+1664"
},
{
  value: "MP",
  label: "+1670"
},
{
  value: "GU",
  label: "+1671"
},
{
  value: "AS",
  label: "+1684"
},
{
  value: "SX",
  label: "+1721"
},
{
  value: "LC",
  label: "+1758"
},
{
  value: "DM",
  label: "+1767"
},
{
  value: "VC",
  label: "+1784"
},
{
  value: "PR",
  label: "+1787"
},
{
  value: "DO",
  label: "+1809"
},
{
  value: "TT",
  label: "+1868"
},
{
  value: "KN",
  label: "+1869"
},
{
  value: "JM",
  label: "+1876"
},
{
  value: "GG",
  label: "+441481"
},
{
  value: "JE",
  label: "+441534"
},
{
  value: "IM",
  label: "+441624"
},
]