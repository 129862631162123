import { countries } from 'country-data';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { compose } from 'redux';
import * as actions from '../../../actions';
import DeleteModal from '../../../components/DeleteModal';
import Loader from '../../../components/Loader';
import EditSponsorContact from './EditSponsorContact';
const MODULE_BASE_ROUTE="";
const ITEM = 'Sponsor Contact';
const DELETE_API = 'study-contact/deactivate';  

class SponsorContacDetails extends Component {       
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            account:this.props,
             details:{contact:{address:{countryCode:""},contactInfo:{}},address:{}}
        }    
    }   

    toggleState = (key) => {
      this.setState((prevState, props) => ({
        [key]: !prevState[key],
      }));
      }

   getDetails=()=>{

    this.setState(() => ({
      loading: true,
    }));

    this.props.get({ url: `study-contact/${this.props.siteData.id}`, errorUrl: `/${MODULE_BASE_ROUTE}`, history: this.props.history }, async (response) => {
      console.log(response, '======>response');
      this.setState(() => ({
        details: response,
        responseFetched: true,
        loading: false,
      }))
    });

   }

   handleConfirmDelete = (e, id) => {	
	e.preventDefault();
    this.setState((prevState, props) => ({
      deleteId: id,
      confirmDelete: true,
      isRowMenu: false,
	}));
	   
  }

  rerouteToList=()=>{
    this.props.hideDetails();
  }


   componentDidMount=()=>{
       this.getDetails()
   }

   reloadData=()=>{
    this.toggleState('isEditModal')
    this.getDetails()


   }

    render() { 

        return ( 
        
            <div class="RowDesign">
            <div class="col-lg-12 float-left mb-3 text-left">
                <h3 class="mb-0 HeadingData">Sponsor Details</h3>
            </div>
            <div class="col-lg-12  float-left">
                <ul class="ClientDetails">
                    <li><strong>Joik Site Contact ID</strong>{this.state.details.joikId}</li>
                    <li><strong>First Name</strong>{this.state.details.contact.firstName}</li>
                    <li><strong>Last Name</strong>{this.state.details.contact.lastName}</li>									
                    <li><strong>Address Line 1</strong>{this.state.details.contact.address.addressLine1}</li>
                    <li><strong>Address Line 2</strong>{this.state.details.contact.address.addressLine2}</li>
                    <li><strong>City</strong>{this.state.details.contact.address.city}</li>	
                    <li><strong>Country</strong>{countries[this.state.details.contact.address.countryCode].name}</li>						
                    <li><strong>Phone</strong>{this.state.details.contact.contactInfo.mobileNumberCountryCode + this.state.details.contact.contactInfo.mobileNumber}</li>
                    <li><strong>Email</strong>{this.state.details.contact.contactInfo.email}</li>					
                    <li><strong>Sponsor Contact Status</strong>{this.state.details.contactStatus}</li>	
                    <li><strong>Site Contact Status Effective Date</strong>{this.state.details.effectiveDate}</li>						
                </ul>
            </div>
            <div class="col-lg-12  float-left mt-3 mb-3 AllFooterButton text-center d-flex justify-content-center">
                <a onClick={this.props.hideDetails} class="DefaultButton">Back</a>
                <button onClick={() => this.toggleState('isEditModal')} className="DefaultButton AquaButton">Edit</button>
                <Link onClick={e => this.handleConfirmDelete(e, this.state.details.id)} className="DefaultButton">Delete</Link>
            </div>

            <DeleteModal
                        item={ITEM}
                        modalIsOpen={this.state.confirmDelete}
                        setParentState={(newState) => this.setState(newState)}
                        deleteId={this.state.deleteId}r
                        rerouteToList={this.rerouteToList}
                        api={this.props.deleteMethod}
                        apiName={DELETE_API}
          />

          <EditSponsorContact  
                  id={this.props.siteData.id}          
                  details={this.state.details}
                  setParentState={(newState) => this.setState(newState)}
                  modalIsOpen={this.state.isEditModal}
                  reloadData={this.reloadData}   
          />
< Loader
          loading={this.state.loading}
        />
        </div>
        
        
        );
    }
}


const mapStateToProps = state => ({
    // errorMessage: state.auth.errorMessage
});

export default compose(
  connect(mapStateToProps, actions)
)(SponsorContacDetails);