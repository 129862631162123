/*!

=========================================================
* Footer
=========================================================

*/
import React from "react";
import { Link } from "react-router-dom";
// reactstrap components
// import { Card, CardBody, CardTitle, Container, Row, Col } from "reactstrap";
// import { Footer_BACKGROUND_CLASS } from "../../utils/constants.js";

class Footer extends React.Component {
  render() {
    return (
        <>
        {/* <!-- Footer --> */}
        <footer className="footer">
          <div className="row">
            <div className="col-lg-12 text-center">
              <Link>
              © 2021 Joik Health Limited. All rights reserved.
              </Link>
            </div>
          </div>
        </footer>
        {/* <!-- /footer --> */}
      </>
    );
  }
}

export default Footer;
