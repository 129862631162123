import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import * as actions from '../../actions';



class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      animations: [],
      totalAccounts: 0,
      totalStudy: 0,
      totalContact: 0,
      totalSponsor: 0,
      images: [],
      responseFetched: false,
      alertData: {
        "tickets-responded": 0,
        "payments-require-payment": 0,
        "payments-require-approval": 0,
        "payment-cards-require-activation": 0,
        "payment-cards-require-deactivation": 0,
        "tickets-require-action": 0,
      },
    };
  }



  componentDidMount() {
    this.getRequiredData();
  }

  getRequiredData = () => {

    // this.props.get({ url: `account?pageNum=1&pageSize=5&sortField=id&sortOrder=Desc&word=`}, async (response) => {
    //    this.setState(() => ({
    //     totalAccounts: response.totalEntries,
    //     }));
    // });

    // this.props.get(
    //   { url: `contact?pageNum=1&pageSize=5&sortField=id&sortOrder=Desc&word=` },
    //   async (response) => {
    //     this.setState(() => ({
    //       totalContact: response.totalEntries,
    //     }));
    //   }
    // );

    // this.props.get(
    //   { url: `study?pageNum=1&pageSize=5&sortField=id&sortOrder=Desc&word=` },
    //   async (response) => {
    //     this.setState(() => ({
    //       totalStudy: response.totalEntries,
    //     }));
    //   }
    // );

    // this.props.get(
    //   { url: `account/account-type/Sponsor%20Account` },
    //   async (response) => {
    //     this.setState(() => ({
    //       totalSponsor: response.totalEntries,
    //     }));
    //   }
    // );


    this.props.get({ url: `dashboard` }, async (response) => {
      this.setState(() => ({
        alertData: response.alerts,
        totalSponsor: response.statistics.sponsors,
        totalStudy: response.statistics.studies,
        totalContact: response.statistics.contacts,
        totalAccounts: response.statistics.accounts,
      }));
    });

  }


  render() {
    return (
      <>
        <div className="content">
          <div className="row PageHeading mb-3">
            <div className="col-lg-12">
              <h3 className="mb-0">Dashboard</h3>
            </div>
          </div>

          <div className="row DashboardBox mb-3">
            <div className="col-lg-12">
              <div className="">
                <div className="col-lg-3 float-left no-padding">
                  <div className="DashboardBoxDeatils">
                    <div className="DashboardBoxIcon">
                      <i className="icon-stats-bars2"></i>
                    </div>
                    <div className="DashboardBoxtext">
                      <h3>
                        <a href={`${process.env.PUBLIC_URL}/Studies`}>
                          {this.state.totalStudy}
                        </a>
                      </h3>
                      <p className="AquaText">Total Studies</p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 float-left no-padding">
                  <div className="DashboardBoxDeatils">
                    <div className="DashboardBoxIcon">
                      <i className="icon-stats-bars2"></i>
                    </div>
                    <div className="DashboardBoxtext">
                      <h3>
                        <a href={`${process.env.PUBLIC_URL}/accounts`}>
                          {this.state.totalAccounts}
                        </a>
                      </h3>
                      <p className="AquaText">Total Accounts</p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 float-left no-padding">
                  <div className="DashboardBoxDeatils">
                    <div className="DashboardBoxIcon">
                      <i className="icon-stats-bars2"></i>
                    </div>
                    <div className="DashboardBoxtext">
                      <h3>
                        <a href={`${process.env.PUBLIC_URL}/contact`}>
                          {this.state.totalContact}
                        </a>
                      </h3>
                      <p className="AquaText">Total Contacts</p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 float-left no-padding">
                  <div className="DashboardBoxDeatils">
                    <div className="DashboardBoxIcon">
                      <i className="icon-stats-bars2"></i>
                    </div>
                    <div className="DashboardBoxtext">
                      <h3>
                        <a href={`${process.env.PUBLIC_URL}/accounts`}>
                          {this.state.totalSponsor}
                        </a>
                      </h3>
                      <p className="AquaText">Total Sponsors</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row DashboardBox mt-3">
              <div className="col-lg-12">
                <div class="Bellsection">
                  <a class="Bellicon">
                    <img
                      src={`${process.env.PUBLIC_URL}/assets/images/alarm.png`}
                    />
                  </a>

                  {this.state.alertData["tickets-require-action"] > 0 ||
                    this.state.alertData["tickets-responded"] > 0 ||
                    this.state.alertData["payments-require-payment"] > 0 ||
                    this.state.alertData["payment-cards-require-activation"] > 0 ||
                    this.state.alertData["payment-cards-require-deactivation"] > 0 ||
                    this.state.alertData["payments-require-approval"] > 0 ? (
                    <div class="rightSectionLeft mt-2">
                      {this.state.alertData["tickets-require-action"] > 0 ? (
                        <a href={`${process.env.PUBLIC_URL}/ticket`}>
                          {this.state.alertData["tickets-require-action"] > 1 ?
                            `There are ${this.state.alertData["tickets-require-action"]} Tickets that require action`
                            : `There is ${this.state.alertData["tickets-require-action"]} Ticket that require action`
                          }
                        </a>
                      ) : null}

                      {this.state.alertData["tickets-responded"] > 0 ? (
                        <a href={`${process.env.PUBLIC_URL}/ticket`}>
                          {this.state.alertData["tickets-responded"] > 1 ?
                            `There are ${this.state.alertData["tickets-responded"]} Tickets that has been responded`
                            : `There is ${this.state.alertData["tickets-responded"]} Ticket that has been responded`
                          }
                        </a>
                      ) : null}

                      {this.state.alertData["payments-require-payment"] > 0 ? (
                        <a href={`${process.env.PUBLIC_URL}/payment`}>
                          {this.state.alertData["payments-require-payment"] > 1 ?
                            `There are ${this.state.alertData["payments-require-payment"]} payments required to be paid`
                            : `There is ${this.state.alertData["payments-require-payment"]} payment required to be paid`
                          }
                        </a>
                      ) : null}

                      {this.state.alertData["payments-require-approval"] > 0 ? (
                        <a href={`${process.env.PUBLIC_URL}/payment`}>
                          {this.state.alertData["payments-require-approval"] > 1 ?
                            `There are ${this.state.alertData["payments-require-approval"]} payments required to be approved`
                            : `There is ${this.state.alertData["payments-require-approval"]} payment required to be approved`
                          }
                        </a>
                      ) : null}

                      {this.state.alertData["payment-cards-require-activation"] >
                        0 ? (
                        <a href={`${process.env.PUBLIC_URL}/joikcard`}>
                          {this.state.alertData["payment-cards-require-activation"] > 1 ?
                            `There are ${this.state.alertData["payment-cards-require-activation"]} Joik Card(s) require to be activated`
                            : `There is ${this.state.alertData["payment-cards-require-activation"]} Joik Card require to be activated`
                          }
                        </a>
                      ) : null}
                      {this.state.alertData["payment-cards-require-deactivation"] >
                        0 ? (
                        <a href={`${process.env.PUBLIC_URL}/joikcard`}>
                          {this.state.alertData["payment-cards-require-deactivation"] > 1 ?
                            `There are ${this.state.alertData["payment-cards-require-deactivation"]} Joik Card(s) require to be deactivated`
                            : `There is ${this.state.alertData["payment-cards-require-deactivation"]} Joik Card require to be deactivated`
                          }                        </a>
                      ) : null}
                    </div>
                  ) : (
                    <div class="rightSectionLeft mt-2 noalert">
                      <a>{`No alerts. You are all caught up.`}</a>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>

          <div className="row PageHeading">
            <div className="col-lg-12 text-center"></div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = state => ({
  // errorMessage: state.auth.errorMessage
});

export default compose(
  connect(mapStateToProps, actions)
)(Dashboard);