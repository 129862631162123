import React, { Fragment } from "react";
import { Route, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import Header from "../components/headers/Header";
import Footer from "../components/footers/Footer";
import Sidebar from "../components/sidebars/Sidebar";

const PrivateRoute = ({ component: Component, authenticated, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) => {
        console.log(authenticated, "check private route");
        return localStorage.getItem("id_token") != null ? (
          <Fragment>
            <Header {...props} />
            <div className="page-content pt-0 mt-4">
              <Sidebar {...props} />
              <div className="content-wrapper">
                <Component {...props} />
              </div>
            </div>
            <Footer />
          </Fragment>
        ) : (
          <Fragment>
            <Redirect to="/login" />
          </Fragment>
        );
      }}
    />
  );
};

const mapStateToProps = (state) => ({
  authenticated: state.auth.authenticated,
});

export default connect(mapStateToProps)(PrivateRoute);
