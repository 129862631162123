import React from 'react';
import ReactDOM from 'react-dom';
import './styles/index.css';
import App from './components/App';
import Amplify from 'aws-amplify';
import {
    admin_user_pool_id,
    admin_client_id,
  } from "./utils/constants";
/** Configure amplify */
Amplify.configure( {
    "aws_user_pools_id": admin_user_pool_id,
    "aws_user_pools_web_client_id": admin_client_id,
});
ReactDOM.render(<App />, document.getElementById('root'));
