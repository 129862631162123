import React, { Component, Fragment,useState, useMemo  } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import * as actions from '../actions/index';
import countryCode from '../utils/currencycode.json'

class CountryOperation extends Component {

    constructor(props) {
      super(props);
      this.state = { countryFullName:"UK"}
     }

     getCountryName =()=>{
     var  index =  countryCode.countries.country.find(item =>item.countryCode===this.props.countryCodeName);

     if(index!=undefined){
     setTimeout(
         () => this.setState({ countryFullName: index.countryName}), 
         3000
       );

     } 


     
    // setTimeout(
    //   () => this.setState({ position: 1 }), 
    //   3000
    // );


    //  if(index!=-1){
    //   this.setState({
    //     countryFullName : countryCode.countries.country[index].countryName
    //  })
    //  }else{
    //   this.setState({
    //     countryFullName : this.props.countryCodeName
    //  })

    //  }

    }

    componentDidMount=()=>{

      this.getCountryName();
    }

    render=()=>{
      return (<Fragment>{this.state.countryFullName}</Fragment>)
    } 

  
 }  
 
const mapStateToProps = state => ({
    // errorMessage: state.auth.errorMessage
  });
  
  export default compose(
    connect(mapStateToProps, actions)
  )(CountryOperation);