import React, { Component, Fragment } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import * as actions from '../../actions';
import DeleteModal from '../../components/DeleteModal';
import { Link } from 'react-router-dom';// import OutsideAlerter from '../../components/OutsideAlerter';
import Loader from '../../components/Loader';
import AccountEdit from './AccountEdit';
import {countries} from 'country-data';
const ITEM = 'Account';

const DELETE_API = 'account/deactivate';
const MODULE_BASE_ROUTE = 'accounts';

class AccountDetail extends Component {
  constructor(props) {
    super(props);
    const id = this.props.match.params.id;
    if (!id) {
      this.props.history.push(`/${MODULE_BASE_ROUTE}`)
    }

    this.state = {
      id,
      detail: {},
      responseFetched: false,
      loading: false,
      isEditModal: false,
    };
  }
  getDetail = () => {
    const { id } = this.state;
    this.setState(() => ({
      loading: true,
    }));

    this.props.get({ url: `account/${id}`, errorUrl: `/${MODULE_BASE_ROUTE}`, history: this.props.history }, async (response) => {
      console.log(response, '======>response');
      this.setState(() => ({
        detail: response,
        responseFetched: true,
        loading: false,
      }))
    });
  }
  
  toggleState = (key) => {
    this.setState((prevState, props) => ({
      [key]: !prevState[key],
    }));
  }
  
  rerouteToAccountList=()=>{

    this.props.history.push(`/accounts`)

   }


  componentDidMount() {
    console.log(this.props, '======>this.props');
    this.getDetail();
    console.log(this.props, '======>this.props');
  }

  componentDidUpdate() {
    console.log(this.state, '================>state in component did update');
  }


  handleConfirmDelete = (e, id) => {
    e.preventDefault();
    this.setState((prevState, props) => ({
      deleteId: id,
      confirmDelete: true,
      isRowMenu: false,
    }));
   
  }
  
  render() {
    let {
      detail
    } = this.state;
    const { id } = this.state;

    // console.log(this.state, '========>this.state');
    // console.log(accounts, '====================>accounts');

    return (
      <>
        {/* <!-- Content area --> */}
        <div className="content">
          {/* <!-- Dashboard content --> */}
          <div className="row PageHeading mb-3">
            <div className="col-lg-12">
              <h3 className="mb-0">Account Details</h3>
            </div>
          </div>

          <div className="row">
            <div className="col-lg-12">
              {Object.keys(detail).length > 0 && (
                <div className="RowDesign">
                  {/* <div className="col-lg-12 float-left mb-3 text-left">
                      <h3 className="mb-0 HeadingData">Joik Profile ID : <span className="AquaText">{detail.joikId}</span></h3>
                    </div> */}

                  <div className="col-lg-12  float-left">
                    <ul className="ClientDetails">
                      <li>
                        <strong>Joik Account ID</strong>
                        {detail.joikId}
                      </li>
                      <li>
                        <strong>Account Type</strong>
                        {detail.accountType}
                      </li>
                      <li>
                        <strong>Account Name</strong>
                        {detail.name}
                      </li>
                      <li>
                        <strong>Address Line 1</strong>
                        {detail.address.addressLine1}
                      </li>
                      <li>
                        <strong>Address Line 2</strong>
                        {detail.address.addressLine2}
                      </li>
                      <li>
                        <strong>City</strong>
                        {detail.address.city}
                      </li>
                      <li>
                        <strong>State</strong>
                        {detail.address.state}
                      </li>
                      <li>
                        <strong>Postcode</strong>
                        {detail.address.postcode}
                      </li>
                      <li>
                        <strong>Country</strong>
                        {countries[detail.address.countryCode].name}
                      </li>
                      <li>
                        <strong>Email ID</strong>
                        {detail.contactInfo.email}
                      </li>
                      <li>
                        <strong>Phone</strong>
                        {detail.contactInfo.landlineNumber}
                      </li>
                      {/* <li><strong>Fax</strong>{detail.contactInfo.fax}</li> */}
                    </ul>
                  </div>
                  <div className="col-lg-12  float-left mt-3 mb-3 AllFooterButton text-center d-flex justify-content-center">
                    <Link
                      to={`${process.env.PUBLIC_URL}/accounts`}
                      className="DefaultButton"
                    >
                      Back
                    </Link>
                    <button
                      onClick={() => this.toggleState("isEditModal")}
                      className="DefaultButton AquaButton"
                    >
                      Edit
                    </button>
                    <Link
                      onClick={(e) => this.handleConfirmDelete(e, detail.id)}
                      className="DefaultButton"
                    >
                      Delete
                    </Link>
                  </div>
                </div>
              )}
            </div>
          </div>
          {/* <!-- /dashboard content --> */}
        </div>
        <DeleteModal
          item={ITEM}
          modalIsOpen={this.state.confirmDelete}
          setParentState={(newState) => this.setState(newState)}
          deleteId={this.state.deleteId}
          rerouteToList={this.rerouteToAccountList}
          api={this.props.deleteMethod}
          apiName={DELETE_API}
        />

        <AccountEdit
          id={id}
          item={ITEM}
          editDone={this.getDetail}
          editData={this.state.editData}
          setParentState={(newState) => this.setState(newState)}
          modalIsOpen={this.state.isEditModal}
          toggleModal={() => this.toggleState("isEditModal")}
        />
        <Loader loading={this.state.loading} />
      </>
    );
  }
}


const mapStateToProps = state => ({
  // errorMessage: state.auth.errorMessage
});

export default compose(
  connect(mapStateToProps, actions)
)(AccountDetail);