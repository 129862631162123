import React, { useEffect } from 'react';
import Loader from "react-loader-spinner";
import styled from 'styled-components';


const Loading = (props) => {
    useEffect(() => {
        if (props.loading) {
            // document.getElementById('root').classList.add('background_blur');
        } else {
            // document.getElementById('root').classList.remove('background_blur');
        }
    }, [props.loading])
    
    return (
        <FallbackContainer>
            {/* <Container> */}
            <Loader
                type="TailSpin"
                color="#1ca79e"
                height={100}
                width={100}
                visible={props.loading}
                // style={{
                //     'zIndex': 99999
                // }}
            />
            {/* </Container> */}
        </FallbackContainer>
    );
}

export default Loading;

const FallbackContainer = styled.div`
    

    position: absolute;
    // display: flex;
    margin-top: -50px;
    margin-left: -50px;
    // height: 100%;
    // width: 100%;
    // z-index: 9999;
    // background-color: black;
    backdrop-filter: none !important;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50);

`;
// const FallbackContainer = styled.div`
//   position: static; 
//   height: 100%;
//   width: 100%;
//   backdrop-filter: blur(2px);
// //   transform: translate(-50%, -50%);
//   z-index: 9999;
// `;