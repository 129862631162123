import React, { Fragment } from "react";
import { Route } from "react-router-dom";
import { connect } from "react-redux";

const PublicRoute = ({ component: Component, authenticated, ...rest }) => (
  <Route
    {...rest}
    component={(props) => (
      <Fragment>
      
        <div className="">
           <div className="content-wrapper">
            <Component {...props} />
          </div>
        </div>
     
      </Fragment>
    )}
  />
);


const mapStateToProps = (state) => ({
  authenticated: state.auth.authenticated,
});

export default connect(mapStateToProps)(PublicRoute);
