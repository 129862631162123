import React, { Component, Fragment } from 'react';

import { compose } from 'redux';
import { connect } from 'react-redux';
import * as actions from '../../actions';
import Modal from "react-bootstrap/Modal";
import { Formik } from "formik";
import { toast } from 'react-toastify';
import Select from "../../components/form/Select";
import Text from "../../components/form/Text";
import { Form } from 'react-bootstrap';
import AccountList from "./AccountList"
import DatePicker from "react-date-picker";
import { countryMobileCodeList } from '../../utils/countryMobileCodeList';
import { countryandMobileCodeList } from '../../utils/countryandMobileCodeList';
import { mobileandCountryCodeList } from '../../utils/mobileandCountryCodeList';

const INITIAL_VALUES = {
  accountType: "",
  fname: "",
  lname: "",
  joikId: "",
  name: "",
  addressLine1: "",
  addressLine2: "",
  city: "",
  state: "",
  postcode: "",
  countryCode: "",
  email: "",
  landlineNumber: 0,
  contactStatus: "",
  countryMobileCode: "",
};

const MODULE_BASE_ROUTE = 'accounts';
const MODULE_NAME = 'Contact';

class ContactEdit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      detail: INITIAL_VALUES,
      intialDataWithIds: {},
      responseFetched: false,
      isRowMenu: false,
      isRowMenuIndex: null,
      editData: null,
      accountListModel: false,
      selectedAccountName: "",
      statusEffectiveDate: "",
    };
  }

  validate = (values) => {
    let errors = {};

    if (!values.accountType || values.accountType.trim().length === 0) {
      errors.accountType = "Contact Type is required.";
    }

    if (!values.fname && values.fname.trim().length === 0) {
      errors.fname = "First Name required";
    }

    if (values.fname && values.fname.trim().length < 2) {
      errors.fname = "First Name should contain atleast 2-50 characters";
    }

    if (!values.lname && values.lname.trim().length === 0) {
      errors.lname = "Last Name required";
    }

    if (values.lname && values.lname.trim().length < 2) {
      errors.lname = "Last Name should contain atleast 2-50 characters";
    }

    if (!values.addressLine1 || values.addressLine1.trim().length === 0) {
      errors.addressLine1 = "Address Line 1 is required.";
    }

    if (values.addressLine1 && values.addressLine1.trim().length < 5) {
      errors.addressLine1 =
        "Address Line 1 should contain atleast 5-100 characters";
    }

    if (!values.city || values.city.trim().length === 0) {
      errors.city = "City is required.";
    }

    if (values.city && values.city.trim().length < 2) {
      errors.city = "City should contain atleast 2-50 characters";
    }

    if (!values.postcode || values.postcode.trim().length === 0) {
      errors.postcode = "Postcode is required.";
    }

    if (values.postcode && values.postcode.trim().length < 3) {
      errors.postcode = "Postcode should contain atleast 3-10 characters";
    }
    if (!values.countryMobileCode) {
      errors.countryMobileCode = "Mobile country code is required.";
    }
    if (!values.landlineNumber) {
      errors.landlineNumber = "Mobile number is required.";
    }
    if (values.landlineNumber.startsWith("0")) {
      errors.landlineNumber = "Mobile number can not start with 0.";
    }
    if (!values.countryCode || values.countryCode.trim().length === 0) {
      errors.countryCode = "Country is required.";

    } else if (values.countryCode.length > 2) {
      errors.countryCode = "Country muste be 2 characters long.";
    }

    if (
      values.email !== "" &&
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
    ) {
      errors.email = "Invalid Email ID.";
    }

    if (!values.contactStatus) {
      errors.contactStatus = "ContactStatus is required.";
    }
    // if (!values.statusEffectiveDate) {
    //   errors.statusEffectiveDate = "Status effective date is required.";
    // }

    console.log(errors, "this is in error");
    return errors;
  };

  handleSubmit = async (values, { setSubmitting, resetForm }) => {
    console.log("handle submit called");

    this.props.setParentState(() => ({
      loading: true,
    }));
    const modifiedValues = {
      id: this.props.id,
      joikId: values.joikId,
      firstName: values.fname,
      lastName: values.lname,
      status: "active",
      contactInfo: {
        status: "active",
        id: this.state.intialDataWithIds.contactInfo.id,
        email: values.email,
        // landlineNumber: "",
        mobileNumber: values.landlineNumber,
        mobileNumberCountryCode: countryandMobileCodeList[values.countryMobileCode],
        fax: values.fax,
      },
      address: {
        status: "active",
        id: this.state.intialDataWithIds.address.id,
        addressLine1: values.addressLine1,
        addressLine2: values.addressLine2,
        state: values.state,
        city: values.city,
        postcode: values.postcode,
        countryCode: values.countryCode,
      },
      account: {
        id: this.state.selectedAccountId,
      },
      contactStatus: values.contactStatus,
      statusEffectiveDate: this.state.statusEffectiveDate,
      contactType: values.accountType,
    };


    await this.props.apiJSON(
      {
        url: `contact/${this.props.id}`,
        method: "PUT",
        data: modifiedValues,
      },
      () => {
        setTimeout(() => {
          setSubmitting(false);
          toast(`${MODULE_NAME} updated successfully.`, { type: "success" });
          this.props.setParentState(() => ({
            isEditModal: false,
            loading: false,
          }));
          this.props.editDone();
        }, 1500);
      }
    );

    // Loader stop if no response comes
    setTimeout(() => {
      this.setState(() => ({
        loading: false,
      }));
    }, 2000);
  };

  getDetail = () => {
    let id = this.props.id;
    this.setState(() => ({
      loading: true,
    }));

    this.props.get(
      {
        url: `contact/${id}`,
        errorUrl: `/${MODULE_BASE_ROUTE}`,
        history: this.props.history,
      },
      async (response) => {
        this.setState(() => ({
          intialDataWithIds: response,
          detail: {
            accountType: response.contactType,
            joikId: response.joikId,
            fname: response.firstName,
            lname: response.lastName,
            addressLine1: response.address.addressLine1,
            addressLine2: response.address.addressLine2,
            city: response.address.city,
            state: response.address.state,
            postcode: response.address.postcode,
            countryCode: response.address.countryCode,
            email: response.contactInfo.email,
            landlineNumber: response.contactInfo.mobileNumber,
            countryMobileCode: mobileandCountryCodeList[response.contactInfo.mobileNumberCountryCode],
            fax: response.contactInfo.fax,
            selectedAccountName: response.account.name,
            contactStatus: response.contactStatus,
          },
          statusEffectiveDate: new Date(response.statusEffectiveDate),
          responseFetched: true,
          loading: false,
          selectedAccountName: response.account.name,
          selectedAccountId: response.account.id,
        }));
      }
    );
  };

  selectedAccount = (account, type) => {
    console.log(account);

    this.setState(() => ({
      selectedAccountName: account.name,
      selectedAccountId: account.id,
      accountListModel: false,
    }));
  };

  onChange = (val) => {
    var value = "";

    if (val != null) {
      value = new Date(val.getTime() - val.getTimezoneOffset() * 60000);
    }

    this.setState({
      date: value,
    });
  };

  closeAssignModel = () => {
    this.setState({
      accountListModel: false,
    });
  };

  openAssignAccountList = () => {
    this.setState(() => ({
      accountListModel: true,
    }));
  };


  getAllNewData = () => {

    this.getDetail();

  }

  componentDidUpdate() {
    console.log(
      this.state,
      "================>state in component did update edit account  "
    );
  }

  render() {
    let {
      // detail
    } = this.state;

    // console.log(this.state, '========>this.state');
    // console.log(accounts, '====================>accounts');

    return (
      <>
        <Modal
          show={this.props.modalIsOpen}
          onHide={this.props.toggleModal}
          onShow={this.getAllNewData}
          // animation={false}
          className={"CustomModal width900"}
        >
          <div className="modal-content">
            <Formik
              initialValues={this.state.detail}
              validate={this.validate}
              onSubmit={this.handleSubmit}
              enableReinitialize
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                setFieldValue,
                isSubmitting,
                /* and other goodies */
              }) => (
                <form
                  onSubmit={handleSubmit}
                  autoComplete="off"
                  className="float-left FormEditDesign"
                >
                  <div className="modal-header">
                    <h4 className="modal-title">Edit Contact</h4>
                    <button
                      type="button"
                      onClick={this.props.toggleModal}
                      className="close"
                    >
                      &times;
                    </button>
                  </div>
                  <div className="col-md-12">
                    <div className="col-lg-12  float-left">
                      <div className="modal-body modalpadding">
                        <div className="row">
                          {/* <div className="col-lg-12">
                              <h4>Enter Account details</h4>
                            </div> */}
                        </div>
                        {/* <form className="float-left FormEditDesign"> */}
                        <div className="col-lg-12">
                          <div className="row">
                            {/* <!--First--> */}
                            <div className="col-md-12">
                              <div className="row"></div>
                              <div className="row">
                                <div className="col-lg-12 col-md-12">
                                  <div className="form-group required">
                                    <Select
                                      title="Contact Type"
                                      name="accountType"
                                      placeholder="Select Contact Type"
                                      options={[
                                        {
                                          value: "Site Contact",
                                          label: "Site Contact",
                                        },
                                        {
                                          value: "Sponsor Contact",
                                          label: "Sponsor Contact",
                                        },
                                        {
                                          value: "Joik Contact",
                                          label: "Joik Contact",
                                        },
                                      ]}
                                      id="input_account_type"
                                      errors={errors}
                                      touched={touched}
                                      values={values}
                                      handleChange={(e) => {
                                        const value = e.target.value;
                                        // this.selectedGender(value);
                                        setFieldValue("accountType", value);
                                      }}
                                      handleBlur={handleBlur}
                                      disabled={false}
                                    />
                                  </div>
                                </div>
                              </div>

                              <div className="row">
                                <div className="col-lg-6 col-md-12">
                                  <div className="form-group required">
                                    <Text
                                      title="First Name"
                                      name="fname"
                                      placeholder="First Name"
                                      // id="input_name"
                                      errors={errors}
                                      touched={touched}
                                      values={values}
                                      handleChange={handleChange}
                                      handleBlur={handleBlur}
                                      disabled={false}
                                    />
                                  </div>
                                </div>

                                <div className="col-lg-6 col-md-12">
                                  <div className="form-group required">
                                    <Text
                                      title="Last Name"
                                      name="lname"
                                      placeholder="Last Name"
                                      // id="input_name"
                                      errors={errors}
                                      touched={touched}
                                      values={values}
                                      handleChange={handleChange}
                                      handleBlur={handleBlur}
                                      disabled={false}
                                    />
                                  </div>
                                </div>
                              </div>

                              <div className="row">
                                <div className="col-lg-6 col-md-12">
                                  <div className="form-group required">
                                    <Text
                                      title="Address Line 1"
                                      name="addressLine1"
                                      placeholder="Address Line 1"
                                      // id="input_name"
                                      errors={errors}
                                      touched={touched}
                                      values={values}
                                      handleChange={handleChange}
                                      handleBlur={handleBlur}
                                      disabled={false}
                                    />
                                  </div>
                                </div>

                                <div className="col-lg-6 col-md-12">
                                  <div className="form-group">
                                    <Text
                                      title="Address Line 2"
                                      name="addressLine2"
                                      placeholder="Address Line 2"
                                      // id="input_name"
                                      errors={errors}
                                      touched={touched}
                                      values={values}
                                      handleChange={handleChange}
                                      handleBlur={handleBlur}
                                      disabled={false}
                                    />
                                  </div>
                                </div>
                              </div>

                              <div className="row">
                                <div className="col-lg-6 col-md-12">
                                  <div className="form-group required">
                                    <Text
                                      title="City"
                                      name="city"
                                      placeholder="City"
                                      // id="input_name"
                                      errors={errors}
                                      touched={touched}
                                      values={values}
                                      handleChange={handleChange}
                                      handleBlur={handleBlur}
                                      disabled={false}
                                    />
                                  </div>
                                </div>

                                <div className="col-lg-6 col-md-12">
                                  <div className="form-group">
                                    <Text
                                      title="State"
                                      name="state"
                                      placeholder="State"
                                      // id="input_name"
                                      errors={errors}
                                      touched={touched}
                                      values={values}
                                      handleChange={handleChange}
                                      handleBlur={handleBlur}
                                      disabled={false}
                                    />
                                  </div>
                                </div>
                              </div>

                              <div className="row">
                                <div className="col-lg-6 col-md-12">
                                  <div className="form-group required">
                                    <Text
                                      title="Postcode"
                                      name="postcode"
                                      placeholder="Postcode"
                                      // id="input_name"
                                      errors={errors}
                                      touched={touched}
                                      values={values}
                                      handleChange={handleChange}
                                      handleBlur={handleBlur}
                                      disabled={false}
                                    />
                                  </div>
                                </div>

                                <div className="col-lg-6 col-md-12">
                                  <div className="form-group required">
                                    <Select
                                      title="Country"
                                      name="countryCode"
                                      placeholder="Select Country"
                                      options={[
                                        { label: "Afghanistan", value: "AF" },
                                        { label: "Åland Islands", value: "AX" },
                                        { label: "Albania", value: "AL" },
                                        { label: "Algeria", value: "DZ" },
                                        {
                                          label: "American Samoa",
                                          value: "AS",
                                        },
                                        { label: "AndorrA", value: "AD" },
                                        { label: "Angola", value: "AO" },
                                        { label: "Anguilla", value: "AI" },
                                        { label: "Antarctica", value: "AQ" },
                                        {
                                          label: "Antigua and Barbuda",
                                          value: "AG",
                                        },
                                        { label: "Argentina", value: "AR" },
                                        { label: "Armenia", value: "AM" },
                                        { label: "Aruba", value: "AW" },
                                        { label: "Australia", value: "AU" },
                                        { label: "Austria", value: "AT" },
                                        { label: "Azerbaijan", value: "AZ" },
                                        { label: "Bahamas", value: "BS" },
                                        { label: "Bahrain", value: "BH" },
                                        { label: "Bangladesh", value: "BD" },
                                        { label: "Barbados", value: "BB" },
                                        { label: "Belarus", value: "BY" },
                                        { label: "Belgium", value: "BE" },
                                        { label: "Belize", value: "BZ" },
                                        { label: "Benin", value: "BJ" },
                                        { label: "Bermuda", value: "BM" },
                                        { label: "Bhutan", value: "BT" },
                                        { label: "Bolivia", value: "BO" },
                                        {
                                          label: "Bosnia and Herzegovina",
                                          value: "BA",
                                        },
                                        { label: "Botswana", value: "BW" },
                                        { label: "Bouvet Island", value: "BV" },
                                        { label: "Brazil", value: "BR" },
                                        {
                                          label:
                                            "British Indian Ocean Territory",
                                          value: "IO",
                                        },
                                        {
                                          label: "Brunei Darussalam",
                                          value: "BN",
                                        },
                                        { label: "Bulgaria", value: "BG" },
                                        { label: "Burkina Faso", value: "BF" },
                                        { label: "Burundi", value: "BI" },
                                        { label: "Cambodia", value: "KH" },
                                        { label: "Cameroon", value: "CM" },
                                        { label: "Canada", value: "CA" },
                                        { label: "Cape Verde", value: "CV" },
                                        {
                                          label: "Cayman Islands",
                                          value: "KY",
                                        },
                                        {
                                          label: "Central African Republic",
                                          value: "CF",
                                        },
                                        { label: "Chad", value: "TD" },
                                        { label: "Chile", value: "CL" },
                                        { label: "China", value: "CN" },
                                        {
                                          label: "Christmas Island",
                                          value: "CX",
                                        },
                                        {
                                          label: "Cocos (Keeling) Islands",
                                          value: "CC",
                                        },
                                        { label: "Colombia", value: "CO" },
                                        { label: "Comoros", value: "KM" },
                                        { label: "Congo", value: "CG" },
                                        {
                                          label:
                                            "Congo, The Democratic Republic of the",
                                          value: "CD",
                                        },
                                        { label: "Cook Islands", value: "CK" },
                                        { label: "Costa Rica", value: "CR" },
                                        { label: "Cote D Ivoire", value: "CI" },
                                        { label: "Croatia", value: "HR" },
                                        { label: "Cuba", value: "CU" },
                                        { label: "Cyprus", value: "CY" },
                                        {
                                          label: "Czech Republic",
                                          value: "CZ",
                                        },
                                        { label: "Denmark", value: "DK" },
                                        { label: "Djibouti", value: "DJ" },
                                        { label: "Dominica", value: "DM" },
                                        {
                                          label: "Dominican Republic",
                                          value: "DO",
                                        },
                                        { label: "Ecuador", value: "EC" },
                                        { label: "Egypt", value: "EG" },
                                        { label: "El Salvador", value: "SV" },
                                        {
                                          label: "Equatorial Guinea",
                                          value: "GQ",
                                        },
                                        { label: "Eritrea", value: "ER" },
                                        { label: "Estonia", value: "EE" },
                                        { label: "Ethiopia", value: "ET" },
                                        {
                                          label: "Falkland Islands (Malvinas)",
                                          value: "FK",
                                        },
                                        { label: "Faroe Islands", value: "FO" },
                                        { label: "Fiji", value: "FJ" },
                                        { label: "Finland", value: "FI" },
                                        { label: "France", value: "FR" },
                                        { label: "French Guiana", value: "GF" },
                                        {
                                          label: "French Polynesia",
                                          value: "PF",
                                        },
                                        {
                                          label: "French Southern Territories",
                                          value: "TF",
                                        },
                                        { label: "Gabon", value: "GA" },
                                        { label: "Gambia", value: "GM" },
                                        { label: "Georgia", value: "GE" },
                                        { label: "Germany", value: "DE" },
                                        { label: "Ghana", value: "GH" },
                                        { label: "Gibraltar", value: "GI" },
                                        { label: "Greece", value: "GR" },
                                        { label: "Greenland", value: "GL" },
                                        { label: "Grenada", value: "GD" },
                                        { label: "Guadeloupe", value: "GP" },
                                        { label: "Guam", value: "GU" },
                                        { label: "Guatemala", value: "GT" },
                                        { label: "Guernsey", value: "GG" },
                                        { label: "Guinea", value: "GN" },
                                        { label: "Guinea-Bissau", value: "GW" },
                                        { label: "Guyana", value: "GY" },
                                        { label: "Haiti", value: "HT" },
                                        {
                                          label:
                                            "Heard Island and Mcdonald Islands",
                                          value: "HM",
                                        },
                                        {
                                          label:
                                            "Holy See (Vatican City State)",
                                          value: "VA",
                                        },
                                        { label: "Honduras", value: "HN" },
                                        { label: "Hong Kong", value: "HK" },
                                        { label: "Hungary", value: "HU" },
                                        { label: "Iceland", value: "IS" },
                                        { label: "India", value: "IN" },
                                        { label: "Indonesia", value: "ID" },
                                        {
                                          label: "Iran, Islamic Republic Of",
                                          value: "IR",
                                        },
                                        { label: "Iraq", value: "IQ" },
                                        { label: "Ireland", value: "IE" },
                                        { label: "Isle of Man", value: "IM" },
                                        { label: "Israel", value: "IL" },
                                        { label: "Italy", value: "IT" },
                                        { label: "Jamaica", value: "JM" },
                                        { label: "Japan", value: "JP" },
                                        { label: "Jersey", value: "JE" },
                                        { label: "Jordan", value: "JO" },
                                        { label: "Kazakhstan", value: "KZ" },
                                        { label: "Kenya", value: "KE" },
                                        { label: "Kiribati", value: "KI" },
                                        {
                                          label:
                                            "Korea, Democratic People S Republic of",
                                          value: "KP",
                                        },
                                        {
                                          label: "Korea, Republic of",
                                          value: "KR",
                                        },
                                        { label: "Kuwait", value: "KW" },
                                        { label: "Kyrgyzstan", value: "KG" },
                                        {
                                          label:
                                            "Lao People S Democratic Republic",
                                          value: "LA",
                                        },
                                        { label: "Latvia", value: "LV" },
                                        { label: "Lebanon", value: "LB" },
                                        { label: "Lesotho", value: "LS" },
                                        { label: "Liberia", value: "LR" },
                                        {
                                          label: "Libyan Arab Jamahiriya",
                                          value: "LY",
                                        },
                                        { label: "Liechtenstein", value: "LI" },
                                        { label: "Lithuania", value: "LT" },
                                        { label: "Luxembourg", value: "LU" },
                                        { label: "Macao", value: "MO" },
                                        {
                                          label:
                                            "Macedonia, The Former Yugoslav Republic of",
                                          value: "MK",
                                        },
                                        { label: "Madagascar", value: "MG" },
                                        { label: "Malawi", value: "MW" },
                                        { label: "Malaysia", value: "MY" },
                                        { label: "Maldives", value: "MV" },
                                        { label: "Mali", value: "ML" },
                                        { label: "Malta", value: "MT" },
                                        {
                                          label: "Marshall Islands",
                                          value: "MH",
                                        },
                                        { label: "Martinique", value: "MQ" },
                                        { label: "Mauritania", value: "MR" },
                                        { label: "Mauritius", value: "MU" },
                                        { label: "Mayotte", value: "YT" },
                                        { label: "Mexico", value: "MX" },
                                        {
                                          label:
                                            "Micronesia, Federated States of",
                                          value: "FM",
                                        },
                                        {
                                          label: "Moldova, Republic of",
                                          value: "MD",
                                        },
                                        { label: "Monaco", value: "MC" },
                                        { label: "Mongolia", value: "MN" },
                                        { label: "Montserrat", value: "MS" },
                                        { label: "Morocco", value: "MA" },
                                        { label: "Mozambique", value: "MZ" },
                                        { label: "Myanmar", value: "MM" },
                                        { label: "Namibia", value: "NA" },
                                        { label: "Nauru", value: "NR" },
                                        { label: "Nepal", value: "NP" },
                                        { label: "Netherlands", value: "NL" },
                                        {
                                          label: "Netherlands Antilles",
                                          value: "AN",
                                        },
                                        { label: "New Caledonia", value: "NC" },
                                        { label: "New Zealand", value: "NZ" },
                                        { label: "Nicaragua", value: "NI" },
                                        { label: "Niger", value: "NE" },
                                        { label: "Nigeria", value: "NG" },
                                        { label: "Niue", value: "NU" },
                                        {
                                          label: "Norfolk Island",
                                          value: "NF",
                                        },
                                        {
                                          label: "Northern Mariana Islands",
                                          value: "MP",
                                        },
                                        { label: "Norway", value: "NO" },
                                        { label: "Oman", value: "OM" },
                                        { label: "Pakistan", value: "PK" },
                                        { label: "Palau", value: "PW" },
                                        {
                                          label:
                                            "Palestinian Territory, Occupied",
                                          value: "PS",
                                        },
                                        { label: "Panama", value: "PA" },
                                        {
                                          label: "Papua New Guinea",
                                          value: "PG",
                                        },
                                        { label: "Paraguay", value: "PY" },
                                        { label: "Peru", value: "PE" },
                                        { label: "Philippines", value: "PH" },
                                        { label: "Pitcairn", value: "PN" },
                                        { label: "Poland", value: "PL" },
                                        { label: "Portugal", value: "PT" },
                                        { label: "Puerto Rico", value: "PR" },
                                        { label: "Qatar", value: "QA" },
                                        { label: "Reunion", value: "RE" },
                                        { label: "Romania", value: "RO" },
                                        {
                                          label: "Russian Federation",
                                          value: "RU",
                                        },
                                        { label: "RWANDA", value: "RW" },
                                        { label: "Saint Helena", value: "SH" },
                                        {
                                          label: "Saint Kitts and Nevis",
                                          value: "KN",
                                        },
                                        { label: "Saint Lucia", value: "LC" },
                                        {
                                          label: "Saint Pierre and Miquelon",
                                          value: "PM",
                                        },
                                        {
                                          label:
                                            "Saint Vincent and the Grenadines",
                                          value: "VC",
                                        },
                                        { label: "Samoa", value: "WS" },
                                        { label: "San Marino", value: "SM" },
                                        {
                                          label: "Sao Tome and Principe",
                                          value: "ST",
                                        },
                                        { label: "Saudi Arabia", value: "SA" },
                                        { label: "Senegal", value: "SN" },
                                        {
                                          label: "Serbia and Montenegro",
                                          value: "CS",
                                        },
                                        { label: "Seychelles", value: "SC" },
                                        { label: "Sierra Leone", value: "SL" },
                                        { label: "Singapore", value: "SG" },
                                        { label: "Slovakia", value: "SK" },
                                        { label: "Slovenia", value: "SI" },
                                        {
                                          label: "Solomon Islands",
                                          value: "SB",
                                        },
                                        { label: "Somalia", value: "SO" },
                                        { label: "South Africa", value: "ZA" },
                                        {
                                          label:
                                            "South Georgia and the South Sandwich Islands",
                                          value: "GS",
                                        },
                                        { label: "Spain", value: "ES" },
                                        { label: "Sri Lanka", value: "LK" },
                                        { label: "Sudan", value: "SD" },
                                        { label: "Surilabel", value: "SR" },
                                        {
                                          label: "Svalbard and Jan Mayen",
                                          value: "SJ",
                                        },
                                        { label: "Swaziland", value: "SZ" },
                                        { label: "Sweden", value: "SE" },
                                        { label: "Switzerland", value: "CH" },
                                        {
                                          label: "Syrian Arab Republic",
                                          value: "SY",
                                        },
                                        {
                                          label: "Taiwan, Province of China",
                                          value: "TW",
                                        },
                                        { label: "Tajikistan", value: "TJ" },
                                        {
                                          label: "Tanzania, United Republic of",
                                          value: "TZ",
                                        },
                                        { label: "Thailand", value: "TH" },
                                        { label: "Timor-Leste", value: "TL" },
                                        { label: "Togo", value: "TG" },
                                        { label: "Tokelau", value: "TK" },
                                        { label: "Tonga", value: "TO" },
                                        {
                                          label: "Trinidad and Tobago",
                                          value: "TT",
                                        },
                                        { label: "Tunisia", value: "TN" },
                                        { label: "Turkey", value: "TR" },
                                        { label: "Turkmenistan", value: "TM" },
                                        {
                                          label: "Turks and Caicos Islands",
                                          value: "TC",
                                        },
                                        { label: "Tuvalu", value: "TV" },
                                        { label: "Uganda", value: "UG" },
                                        { label: "Ukraine", value: "UA" },
                                        {
                                          label: "United Arab Emirates",
                                          value: "AE",
                                        },
                                        {
                                          label: "United Kingdom",
                                          value: "UK",
                                        },
                                        { label: "United States", value: "US" },
                                        {
                                          label:
                                            "United States Minor Outlying Islands",
                                          value: "UM",
                                        },
                                        { label: "Uruguay", value: "UY" },
                                        { label: "Uzbekistan", value: "UZ" },
                                        { label: "Vanuatu", value: "VU" },
                                        { label: "Venezuela", value: "VE" },
                                        { label: "Viet Nam", value: "VN" },
                                        {
                                          label: "Virgin Islands, British",
                                          value: "VG",
                                        },
                                        {
                                          label: "Virgin Islands, U.S.",
                                          value: "VI",
                                        },
                                        {
                                          label: "Wallis and Futuna",
                                          value: "WF",
                                        },
                                        {
                                          label: "Western Sahara",
                                          value: "EH",
                                        },
                                        { label: "Yemen", value: "YE" },
                                        { label: "Zambia", value: "ZM" },
                                        { label: "Zimbabwe", value: "ZW" },
                                      ]}
                                      id="countryCode"
                                      errors={errors}
                                      touched={touched}
                                      values={values}
                                      handleChange={(e) => {
                                        const value = e.target.value;
                                        console.log("good", e.target.value);
                                        setFieldValue("countryCode", value);
                                      }}
                                      handleBlur={handleBlur}
                                      disabled={false}
                                    />
                                  </div>
                                </div>
                              </div>

                              <div className="row">
                                <div className="col-lg-6 col-md-12">
                                  <div className="form-group">
                                    <Text
                                      title="Email ID"
                                      name="email"
                                      placeholder="Email ID"
                                      // id="input_name"
                                      errors={errors}
                                      touched={touched}
                                      values={values}
                                      handleChange={handleChange}
                                      handleBlur={handleBlur}
                                      disabled={false}
                                    />
                                  </div>
                                </div>

                                <div className="col-lg-2 col-md-4">
                                  <div className="form-group required">
                                    <Select
                                      title="Mobile Code"
                                      name="countryMobileCode"
                                      placeholder="Select Mobile Code"
                                      options={countryMobileCodeList}
                                      id="countryMobileCode"
                                      errors={errors}
                                      touched={touched}
                                      values={values}
                                      handleChange={(e) => {
                                        const value = e.target.value;
                                        setFieldValue("countryMobileCode", value);
                                      }}
                                      handleBlur={handleBlur}
                                      disabled={false}
                                    />
                                  </div>

                                </div>
                                <div className="col-lg-4 col-md-8">
                                  <div className="form-group">
                                    <Text
                                      title="Phone"
                                      name="landlineNumber"
                                      placeholder="Phone"
                                      // id="input_name"
                                      errors={errors}
                                      touched={touched}
                                      values={values}
                                      handleChange={handleChange}
                                      handleBlur={handleBlur}
                                      disabled={false}
                                    />
                                  </div>
                                </div>

                                {/* <div className="col-lg-6 col-md-12">
                                        <div className="form-group">
                                          <Text
                                            title="Fax"
                                            name="fax"
                                            placeholder="Fax"
                                            // id="input_name"
                                            errors={errors}
                                            touched={touched}
                                            values={values}
                                            handleChange={handleChange}
                                            handleBlur={handleBlur}
                                            disabled={false}
                                          />
                                        </div>
                                      </div> */}
                              </div>

                              <div className="row">
                                <div className="col-lg-12 mt-1">
                                  <h4>Assign Account</h4>
                                </div>
                              </div>

                              <div className="row">
                                <div className="col-lg-12 mt-1 mb-2">
                                  <a
                                    onClick={this.openAssignAccountList}
                                    class="DefaultButton "
                                  >
                                    Assign Account
                                  </a>
                                </div>
                              </div>

                              <div class="row">
                                <div className="col-lg-12 mt-1 float-left">
                                  <ul className="ClientDetails">
                                    <li>
                                      <strong>Account Name</strong>
                                      {this.state.selectedAccountName}
                                    </li>
                                  </ul>
                                </div>
                              </div>

                              <div className="row mt-2">
                                <div className="col-lg-6 col-md-12">
                                  <div className="form-group required">
                                    <Select
                                      title="Contact Status"
                                      name="contactStatus"
                                      placeholder="Contact Status"
                                      options={[
                                        {
                                          value: "Active",
                                          label: "Active",
                                        },
                                        {
                                          value: "Inactive",
                                          label: "Inactive",
                                        },
                                      ]}
                                      id="contactStatus"
                                      errors={errors}
                                      touched={touched}
                                      values={values}
                                      handleChange={(e) => {
                                        const value = e.target.value;
                                        setFieldValue("contactStatus", value);
                                      }}
                                      handleBlur={handleBlur}
                                      disabled={false}
                                    />
                                    {/* <select className="form-control">
												<option>Active</option>
												<option>Inactive</option>
											</select> */}
                                  </div>
                                </div>
                                <div className="col-lg-6 col-md-12">
                                  <div className="form-group required">
                                    <label class="form-control-label">
                                      Contact Status Effective Date
                                    </label>

                                    <DatePicker
                                      onChange={this.onChange}
                                      value={this.state.statusEffectiveDate}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        {/* </form> */}
                      </div>
                    </div>
                    <div className="col-lg-12  float-left mt-3 mb-3 text-center d-flex justify-content-center">
                      <button
                        type="button"
                        className="btn btn-default DefaultButton"
                        onClick={this.props.toggleModal}
                      >
                        Cancel
                      </button>
                      <button
                        type="submit"
                        className="btn btn-default DefaultButton AquaButton"
                        style={{ marginLeft: "5px" }}
                      >
                        Save
                      </button>
                    </div>
                  </div>
                </form>
              )}
            </Formik>
          </div>
        </Modal>
        <AccountList
          modalIsOpen={this.state.accountListModel}
          closeAssignModel={this.closeAssignModel}
          selectedAccountInfo={this.selectedAccount}
        />
      </>
    );
  }
}

const mapStateToProps = state => ({
  // errorMessage: state.auth.errorMessage
});

export default compose(
  connect(mapStateToProps, actions)
)(ContactEdit);
