export const BASE_URL = "https://backend-dev.joikhealth.com/joik";
export const admin_cognito_uri = "https://joikhealth.auth.us-east-1.amazoncognito.com";
export const site_contact_cognito_uri = "https://joikhealth-doctor.auth.us-east-1.amazoncognito.com/";
export const admin_client_id = "6hcmuo6iom3too0ut3rh510afr";
export const admin_user_pool_id = "us-east-1_EQUhwRGLw";
export const response_type = "token";
export const scope = "email+openid+profile";
export const token_name = "authorization-token";
export const admin_redirect_uri = "https://admindev.joikhealth.com/login";
export const site_contact_redirect_uri = "https://doctordev.joikhealth.com/login";
export const admin_logout_uri = "https://admindev.joikhealth.com/login";
export const site_contact_logout_uri = "https://doctordev.joikhealth.com/login";
export const time_out = 15