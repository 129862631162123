import React, { Component, Fragment } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import * as actions from '../../../actions';
import { Link } from 'react-router-dom';
import ReactPaginate from 'react-paginate';
import { toast } from 'react-toastify';
import Loader from '../../../components/Loader';

import AddMileStonePayment from './AddMileStonePayment'
import {countries} from 'country-data';
import {Form} from 'react-bootstrap'
import SearchField from "react-search-field";

class MileStoneList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            account:{contactInfo:{},address:{}},
            site:{},
            showDetails:false,
            totalRecords:0,
            itemPerPage:5,
            curentPage:1,
            pageCount:1, 
            milestoneList:[],    
            searchValue:'' 
        }    
    }    

    toggleState = (key) => {
      this.setState((prevState, props) => ({
        [key]: !prevState[key],
      }));
    }


   checkMilestoneLIst=()=>{

    this.props.get({ url: `milestone/study/${String(window.location).split("Study-detail/")[1]}?pageNum=1&pageSize=10&sortField=id&sortOrder=Desc&word=`}, async (response) => {
      this.setState({
        milestoneList: response.entryList,
        });    
    });
   }


    componentDidMount=()=>{
       this.getDetails("",1);
       this.checkMilestoneLIst();
    }

    reloadList=()=>{

      this.getDetails("",1);
    }

    getDetails=(sarchStr,pageNum)=>{
        this.setState(() => ({
            loading: true,
          }));  
                
          this.props.get({ url: `milestone-payment-country/study/${String(window.location).split("Study-detail/")[1]}?pageNum=${pageNum}&pageSize=${this.state.itemPerPage}&sortField=id&sortOrder=Desc&word=${sarchStr}`}, async (response) => {
            this.setState(() => ({
                responseData: response,
                accounts: response.entryList,
                responseFetched: true,
                loading: false,
                pageCount:response.totalPages,
            
              }));    
          });
    }

    onSearchClick=(value)=>{

      this.setState({
        searchValue:value
      })
      this.setState({
      name:'value' 
      },() => {
        this.getDetails(this.state.searchValue, this.state.curentPage)
      });
  
         
    }


    setPageSize=(num)=>{
      if(num!=="Item per page"){
        this.setState({
          itemPerPage: num
      }, () => {
          this.getDetails(this.state.searchValue,1);
      });
      }
  
    }




    handlePageClick = (val)=>{
      let pagNum = val.selected+1
      this.getDetails(this.state.searchValue, pagNum)
    }
    addMilestoneInit=()=>{
     if(this.state.milestoneList.length >0){
      this.toggleState('isAddModal')
     }else{
       toast(`Please add atleast one milestone inorder to set milestone payments`);
      }
    }
    render() { 

        let {
            accounts
          } = this.state;
      
         return ( <div className="col-lg-12">
                        <div className="TableData">
                        <div class="TableTopText">
                    <h3>View All Milestones Payments</h3>

                    <div class="HedRgtData">

<Form.Control as="select" className="form-control" size="lg" onChange={(e)=>{this.setPageSize(e.target.value)}}>
<option>5</option>
<option selected>10</option>
<option>15</option>
<option>20</option>
</Form.Control>

<SearchField
  placeholder=""
  searchText=""
  onChange={this.onSearchClick}
  classNames="AllSearchTabledata form-control"
/>
<a  onClick={() => this.addMilestoneInit()} class="DefaultButton AquaButton" data-toggle="modal" data-target="#AddSite">Add Milestone Payments</a>
                    

</div>
                  </div>
                        <table className="table datatable-basic">
                  <thead>
                    <tr>
                      <th>Country</th>
                      <th>Currency</th>
                       </tr>
                  </thead>
                  <tbody>

                    {
                      accounts && accounts.length === 0 && (
                        <tr>
                          <td colSpan="8" >
                            <center>{!this.state.responseFetched ? 'Loading...' : 'No data found'}</center>
                          </td>
                        </tr>
                      )
                    }

                    {/* {console.log(accounts && accounts.length > 0, '======>accounts')} */}
                    {
                      accounts && accounts.length > 0 && accounts.map((account, accountIndex) => {
                        // console.log(account, '======+>account')
                        return (
                          <tr key={`account_${accountIndex}`}>
                           
                           <td>
                           <a onClick={()=>this.props.loadSiteDeatils(account)}>{countries[account.countryCode].name}</a></td>
                           <td><a onClick={()=>this.props.loadSiteDeatils(account)}>{account.currency}</a></td>
                            
                          
                          </tr>
                        );
                      })
                    }
                  </tbody>
                </table>
                <div className="col-lg-12">
              {this.state.pageCount<=1 ? null : 
          
            <ReactPaginate
                previousLabel={'<'}
                nextLabel={'>'}
                breakLabel={'...'}
                breakClassName={'break-me'}
                pageCount={this.state.pageCount}
                marginPagesDisplayed={5}
                pageRangeDisplayed={1}
                onPageChange={this.handlePageClick}
                containerClassName={'pagination'}
                subContainerClassName={'pages pagination'}
                activeClassName={'active'}

              />
             }
            </div>

              </div>

      



             <AddMileStonePayment 
                        studyId={this.props.id}
                       setParentState={(newState) => this.setState(newState)}
                       reloadList = {this.reloadList}
                       modalIsOpen={this.state.isAddModal}
                       reloadcontact = {(newState) => this.getListing("",1)}
                       toggleModal={() => this.toggleState('isAddModal')}
                
                ></AddMileStonePayment>
                            < Loader
          loading={this.state.loading}
        />

              </div> );
    }
 


  
}

const mapStateToProps = state => ({
	// errorMessage: state.auth.errorMessage
  });
  
  export default compose(
	connect(mapStateToProps, actions)
  )(MileStoneList);