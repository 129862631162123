/*!
=========================================================
* actions index
=========================================================
*/
import axios from 'axios';
import { toast } from 'react-toastify';
import { BASE_URL as SERVER_URL } from '../utils/constants';
const fileDownload = require("js-file-download");
const mime = require("mime");

// axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
axios.defaults.headers.common["authorization-token"] = localStorage.getItem(
  "id_token"
);

const BASE_URL = `${SERVER_URL}`





export const api = ({ url: urlEndpoint, method = 'GET', headers, ...rest }, callback) => async (dispatch) => {
    try {

        const response = await axios({
            method,
            url: `${BASE_URL}/${urlEndpoint}`,
            processData: false,
            cache: false,
            headers: {
                'content-type': 'multipart/form-data',
                ...headers,
            }
        });
        callback(response.data);
    } catch (error) {
       
        if (error && error.response) {
            toast(`${error.response.data.message}`,{type:"error"});
        }
    }
}

export const get = ({ url: urlEndpoint, headers = {}, errorUrl, history, ...rest }, callback) => async (dispatch) => {
    try {
        const response = await axios({
          method: "GET",
          url: `${BASE_URL}/${urlEndpoint}`,
          processData: false,
          cache: false,
          headers: {
            "content-type": "application/json",
            ...headers,
          },
          timeout: 15000,
        });
        callback(response.data);
    } catch (error) {
        if (error.message == "Network Error") {
         toast(`${error.message}`, { type: "error" });
       } else if (error.message.search("timeout") != -1) {
         toast(error.message, { type: "error" });
       }
        
     
        if (history && errorUrl && error.response.data.type === 'error') {
            history.push(errorUrl);
        }
             if (error && error.response) {
            toast(`${error.response.data.message}`,{type:'error'});
        }
            }
}

export const post = ({ url: urlEndpoint, headers = {}, ...data }, callback) => async (dispatch) => {
    try {
        console.log(data, '==============>formData');

        let formData = new FormData();
        for (let key in data) {
            formData.append(key, data[key]);
        }

        const response = await axios({
            method: 'POST',
            url: `${BASE_URL}/${urlEndpoint}`,
            processData: false,
            cache: false,
            data: formData,
            headers: {
                'content-type': 'multipart/form-data',
                ...headers
            }
        });
        callback(response.data);
    } catch (error) {
        console.log(error, '===========>error');
        if (error && error.response) {
            toast(`${error.response.data.message}`,{type:'error'});
        }
    }
}

export const put = ({ url: urlEndpoint, headers = {}, ...data }, callback) => async (dispatch) => {
    try {
       
        let formData = new FormData();
        for (let key in data) {
            formData.append(key, data[key]);
        }
        const response = await axios({
            method: 'PUT',
            url: `${BASE_URL}${urlEndpoint}`,
            processData: false,
            cache: false,
            data: formData,
            headers: {
                'content-type': 'multipart/form-data',
                ...headers
            }
        });
        callback(response.data);
    } catch (error) {
       
        if (error && error.response) {
            toast(`${error.response.data.message}`,{type:'error'});
        }
    }
}

export const patch = ({ url: urlEndpoint, headers = {}, data }, callback) => async (dispatch) => {
    try {
      
        const response = await axios({
            method: 'PATCH',
            url: `${BASE_URL}/${urlEndpoint}`,
            data: data,
            headers: {
                'content-type': 'application/json-patch+json',
            }
        });
        callback({status:true,data:response.data});
    } catch (error) {
      
         if(error.response.data.length>0){

            callback({ status: false, data: error.response.data[0].message });

         }

          
       
         if (error && error.response) {
           //  toast(`${error.response.data.message}`,{type:'error'});
             callback({ status: false, data: error.response.data.message });
            // error.response.data
         }
    }
}


export const deleteMethod = ({ url: urlEndpoint, headers = {}, ...data }, callback) => async (dispatch) => {
    try {
        let formData = new FormData();
        for (let key in data) {
            formData.append(key, data[key]);
        }
        const response = await axios({
            method: 'DELETE',
            url: `${BASE_URL}/${urlEndpoint}`,
            processData: false,
            cache: false,
            data: formData,
            headers: {
                'content-type': 'multipart/form-data',
                ...headers
            }
        });
        callback(response.data);
    } catch (error) {
       
        if(error.response){
                   if(error.response.status=="422"){
                        toast(error.response.data[0].message,{type:'error'})

                 }
        }  else{
            toast(error['message'],{type:'error'})
        }
    }
}

export const apiJSON = ({ url: urlEndpoint, method = 'POST', headers, ...rest }, callback) => async (dispatch) => {
    try {


        const response = await axios({
            method,
            url: `${BASE_URL}/${urlEndpoint}`,
            processData: false,
            cache: false,
            data: rest.data,
            headers: {
                'content-type': 'application/json',
                ...headers,
            }
        });
     
        callback(response.data);
    } catch (error) {

        console.log(error.response, "data checking");
     
        if (error.response.status == "400") {
         
            if (error.response.data.message != undefined) {
                 toast(`${error.response.data.message}`, { type: "error" });
                
            } else if (error.response.data[0].message != undefined) {
                 toast(`${error.response.data[0].message}`, { type: "error" });
            }
             
          return 
      }
        
          if (error.response.status == "404") {
            toast(`${error.response.data.message}`, { type: "error" });
            return;
          }

      if (error.response.data.error == "Internal Server Error") {
        toast(error.response.data.error, { type: "error" });
      }

     

      if (error && error.response && Array.isArray(error.response.data)) {
        toast(`${error.response.data[0].message}`, { type: "error" });
      }
    }
}

export const postJSON = ({ url: urlEndpoint, method = 'POST', headers, ...rest }, callback) => async (dispatch) => {
    try {
        const response = await axios({
            method,
            url: `${BASE_URL}/${urlEndpoint}`,
            processData: false,
            cache: false,
            data: rest.data,
            headers: {
                'content-type': 'application/json',
                ...headers,
            }
        });
        callback(response.data);
    } catch (error) {
        if (error && error.response && Array.isArray(error.response.data)) {
            error.response.data.forEach(errorObj => {
                toast(`${errorObj.message}`,{type:'error'});
            })
        }
    }
}




export const getFile =
  (
    {
      url: urlEndpoint,
      fileName: fileName,
      fileType: fileType,
      headers = {},
      errorUrl,
      history,
      ...rest
    },
    callback
  ) =>
  async (dispatch) => {
    try {
      const response = await axios({
        method: "GET",
        url: `${urlEndpoint}`,
        processData: false,
        cache: false,
        responseType: "blob", // Important
        headers: {
          "content-type": "multipart/form-data",
          ...headers,
        },
        timeout: 60000,
      });

      callback(response);
    } catch (error) {
      if (error.message == "Network Error") {
        toast(`${error.message}`, { type: "error" });
      } else if (error.message.search("timeout") != -1) {
        toast(error.message, { type: "error" });
      }

      if (history && errorUrl && error.response.data.type === "error") {
        history.push(errorUrl);
      }

      if (error && error.response) {
        toast(`${error.response.data.message}`, { type: "error" });
      }
    }
  };
