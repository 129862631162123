import { countries } from 'country-data';
import React, { Component } from 'react';
import { Form } from 'react-bootstrap';
import ReactPaginate from 'react-paginate';
import { connect } from 'react-redux';
import SearchField from "react-search-field";
import { compose } from 'redux';
import * as actions from '../../actions';
import Loader from '../../components/Loader';
import { BASE_URL } from '../../utils/constants';
import ContactAdd from './ContactAdd';

const ITEM = 'Contact';


class Contacts extends Component {
  constructor(props) {
    super(props);
    this.state = {
      responseData: {},
      contacts: [],
      confirmDelete: true,
      deleteId: null,
      responseFetched: false,
      isRowMenu: false,
      isRowMenuIndex: null,
      loading: false,
      isAddModal: false,
      isEditModal: false,
      editData: null,
      totalRecords: 0,
      itemPerPage: 10,
      curentPage: 1,
      pageCount: 1,
      searchValue: "",
    };
  }

  getListing = (search, pageNum) => {
    this.setState(() => ({
      loading: true,
    }));

    this.props.get(
      {
        url: `contact?pageNum=${pageNum}&pageSize=${this.state.itemPerPage}&sortField=id&sortOrder=Desc&word=${search}`,
      },
      async (response) => {
        console.log(response, "======>response");
        this.setState(() => ({
          responseData: response,
          contacts: response.entryList,
          responseFetched: true,
          loading: false,
          pageCount: response.totalPages,
        }));
      }
    );
  };

  setPageSize = (num) => {
    if (num != "Item per page") {
      this.setState(
        {
          itemPerPage: num,
        },
        () => {
          this.getListing(this.state.searchValue, 1);
        }
      );
    }
  };

  handlePageClick = (val) => {
    let pagNum = val.selected + 1;
    this.getListing(this.state.searchValue, pagNum);
  };

  onSearchClick = (value) => {
    this.setState({
      searchValue: value,
    });
    this.setState(
      {
        name: "value",
      },
      () => {
        this.getListing(this.state.searchValue, this.state.curentPage);
      }
    );
  };

  toggleState = (key) => {
    this.setState((prevState, props) => ({
      [key]: !prevState[key],
    }));
  };

  toggleRowMenu = (isRowMenuIndex) => {
    // e.preventDefault();

    this.setState((prevState, props) => ({
      isRowMenu: !prevState.isRowMenu,
      isRowMenuIndex: isRowMenuIndex,
    }));
  };

  handleConfirmDelete = (e, id) => {
    e.preventDefault();
    this.setState((prevState, props) => ({
      deleteId: id,
      confirmDelete: true,
      isRowMenu: false,
    }));
  };

  componentDidMount() {
    console.log(this.props, "======>this.props");
    this.getListing(this.state.searchValue, 1);
    console.log(this.props, "======>this.props");
  }

  componentDidUpdate() {
    console.log(this.state, "================>state in component did update");
  }

  downloadItem = (item, type) => {
    let filename =
      "Contacts " +
      String(new Date().toISOString().slice(0, 10)) +
      " " +
      new Date().toLocaleTimeString("it-IT").slice(0, 10) +
      type;

    this.props.getFile(
      {
        url: item,
      },
      async (response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", filename);

        // Append to html link element page
        document.body.appendChild(link);

        // Start download
        link.click();

        // Clean up and remove the link
        link.parentNode.removeChild(link);
      }
    );
  };

  render() {
    let { contacts } = this.state;

    // console.log(this.state, '========>this.state');
    // console.log(contacts, '====================>contacts');

    return (
      <>
        {/* <!-- Content area --> */}
        <div className="content">
          {/* <!-- Dashboard content --> */}
          <div className="row PageHeading mb-3">
            <div className="col-lg-12">
              <h3 className="mb-0">Contacts</h3>
            </div>
          </div>

          <div className="row PageHeading">
            <div className="col-lg-12">
              <div className="TableData">
                <div className="TableTopText">
                  <h3>View All Contacts</h3>

                  <div className="HedRgtData">
                    <Form.Control
                      as="select"
                      size="lg"
                      onChange={(e) => {
                        this.setPageSize(e.target.value);
                      }}
                    >
                      <option selected>Item per page</option>
                      <option>5</option>
                      <option selected>10</option>
                      <option>15</option>
                      <option>20</option>
                    </Form.Control>

                    <SearchField
                      placeholder=""
                      searchText=""
                      onChange={this.onSearchClick}
                      classNames="AllSearchTabledata form-control"
                    />

                    <div class="tooltipDiv">
                      <a
                        onClick={() =>
                          this.downloadItem(
                            `${BASE_URL}/contact/report/excel?&word=${this.state.searchValue}`,
                            ".xlsx"
                          )
                        }
                        className="DefaultButton ExcelButton"
                      ></a>
                      <p className="showTooltip">Download Excel Report</p>
                    </div>
                    <div class="tooltipDiv">
                      <a
                        onClick={() =>
                          this.downloadItem(
                            `${BASE_URL}/contact/report/csv?&word=${this.state.searchValue}`,
                            ".csv"
                          )
                        }
                        className="DefaultButton CsvButton"
                      ></a>
                      <p className="showTooltip">Download CSV Report</p>
                    </div>

                    <a
                      onClick={() => this.toggleState("isAddModal")}
                      className="DefaultButton AquaButton"
                    >
                      Add Contact
                    </a>
                  </div>
                </div>
                <table className="table datatable-basic">
                  <thead>
                    <tr>
                      <th>Joik contact ID</th>
                      <th>First Name</th>
                      <th>Last Name</th>
                      <th>Contact Type</th>
                      <th>Account Name</th>
                      <th>City</th>
                      <th>State</th>
                      <th>Country</th>
                    </tr>
                  </thead>
                  <tbody>
                    {contacts && contacts.length === 0 && (
                      <tr>
                        <td colSpan="8">
                          <center>
                            {!this.state.responseFetched
                              ? "Loading..."
                              : "No data found"}
                          </center>
                        </td>
                      </tr>
                    )}
                    {contacts &&
                      contacts.length > 0 &&
                      contacts.map((contact, contactIndex) => {
                        return (
                          <tr key={`contact_${contactIndex}`}>
                            <td>
                              <a
                                href={`${process.env.PUBLIC_URL}/contact-detail/${contact.id}`}
                              >
                                {contact.joikId}
                              </a>
                            </td>
                            <td>{contact.firstName}</td>
                            <td>{contact.lastName}</td>
                            <td>{contact.contactType}</td>
                            <td>{contact.account.name}</td>
                            <td>{contact.address.city}</td>
                            <td>{contact.address.state}</td>
                            <td>
                              {countries[contact.address.countryCode].name}
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              </div>
            </div>

            <div className="col-lg-12">
              {this.state.pageCount <= 1 ? null : (
                <ReactPaginate
                  previousLabel={"<"}
                  nextLabel={">"}
                  breakLabel={"..."}
                  breakClassName={"break-me"}
                  pageCount={this.state.pageCount}
                  marginPagesDisplayed={5}
                  pageRangeDisplayed={1}
                  onPageChange={this.handlePageClick}
                  containerClassName={"pagination"}
                  subContainerClassName={"pages pagination"}
                  activeClassName={"active"}
                />
              )}
            </div>
          </div>

          <Loader loading={this.state.loading} />
          <ContactAdd
            item={ITEM}
            setParentState={(newState) => this.setState(newState)}
            modalIsOpen={this.state.isAddModal}
            reloadcontact={(newState) =>
              this.getListing(this.state.searchValue, 1)
            }
            toggleModal={() => this.toggleState("isAddModal")}
          />
        </div>
        {/* <!-- /content area --> */}
      </>
    );
  }
}
// export default contacts;


const mapStateToProps = state => ({
  // errorMessage: state.auth.errorMessage
});

export default compose(
  connect(mapStateToProps, actions)
)(Contacts);