import React, { Component, Fragment } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import * as actions from '../../../actions';
import MileStoneList from './MileStoneList'
import MileStoneDetails from './MileStoneDetails'

class MileStone  extends Component {
  constructor(props) {

    super(props);


    this.state = {            
      showDetails:false,
      details:{}
    };


  
  }   

  hideDetails = ()=>{

    this.setState({
      showDetails:false,
    
     })

        
    }
   
    loadSiteDeatils=(site)=>{
      this.setState({
        showDetails:true,
        details:site,
     })

    }

    render() { 
        return (
          <div className="col-lg-12 mt-3">
          { this.state.showDetails== false ? <MileStoneList 
           studyId={this.props.id} 
           loadSiteDeatils={this.loadSiteDeatils}
           ></MileStoneList>:<MileStoneDetails 
          siteData={this.state.details}
          hideDetails = {this.hideDetails}
           >
          </MileStoneDetails>}
       </div> );
          
         
    }
}
 
const mapStateToProps = state => ({
	// errorMessage: state.auth.errorMessage
  });
  
  export default compose(
	connect(mapStateToProps, actions)
  )(MileStone);