import React, { Component, Fragment } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import * as actions from '../../actions';
import Modal from "react-bootstrap/Modal";
import Select from "../../components/form/Select";
import { Formik } from "formik";
import Text from "../../components/form/Text";
import Loader from '../../components/Loader';
import { toast } from 'react-toastify';
const MODULE_NAME = 'Payment';

const INITIAL_VALUES = {
  paymentType: "",
  amount: "",
  name: "",
  joikCardNumber: "",
  beneficiaryName: '',
  swiftCode: '',
  iBanNumber: '',
  paymentStatus: 'Paid',
  paymentUpdatedStatus: '',
  comments: '',
  bankName: '',



};

class Edit extends Component {
  constructor(props) {
    super(props)
    this.state = {
      detail: INITIAL_VALUES,
      message: "",
      options: [{
        value: "Approved",
        label: "Approve",
      },
      {
        value: "Paid",
        label: "Paid",
      },
      {
        value: "On Hold",
        label: "On Hold",
      },
      {
        value: "Rejected",
        label: "Reject",
      }],
      hideEditPayment: false,
      isSubmitDisable: true
    }


  }




  validate = (values) => {
    console.log('values', values);
    let errors = {};
    if (this.state.detail.paymentUpdatedStatus === "On Hold" || this.state.detail.paymentUpdatedStatus === "Rejected") {
      if (!values.comments.trim()) {
        errors.comments = "Comment is required if action selected is On Hold or Reject";
      }
    }

    if (!values.paymentUpdatedStatus) {
      errors.paymentUpdatedStatus = "Action is required";
    }
    return errors;
  }


  handleSubmit = async (values, { setSubmitting, resetForm }) => {

    var modifiedValues = [{
      'op': 'replace',
      'path': '/paymentStatus',
      'value': this.state.detail.paymentUpdatedStatus
    }]

    if (values.comments) {
      let object = {}
      object.op = "replace"
      object.path = "/paymentStatusComment"
      object.value = values.comments.trim()
      modifiedValues.push(object);
    }

    this.setState(() => ({
      loading: true,
    }));

    await this.props.patch({ url: `payment/${this.props.selectedPayment.id}`, method: 'PATCH', data: modifiedValues }, (response) => {

      if (response.status) {
        setTimeout(() => {
          toast(`${MODULE_NAME} updated successfully.`, { type: "success" });
          this.setState({
            loading: false,
          });
          this.props.toggleModal();
          this.props.reloadList();
        }, 1500);
      } else {

        if (response.data.response.status == 400) {
          toast(`${response.data.response.data.message}`, { type: "error" });
        }
      }
    });
    this.setState(() => ({
      loading: false,
      isSubmitDisable: false
    }));
  }
  loadDetails = () => {
    this.setState(() => ({
      loading: false,
      message: '',
      detail: {
        paymentType: ""
      },
      isSubmitDisable: true
    }));
    //  console.log(this.state.detail,'===================state');
    //  this.props.get(
    //    {
    //      url: `payment/payment-info/${this.props.selectedPayment.id}`,
    //    },
    //    async (response) => {
    //      this.setState(() => ({
    //        message: response.message,
    //      }));
    //    }
    //  );


    this.props.get(
      { url: `payment/payment-info/${this.props.selectedPayment.id}` },
      async (response) => {
        // console.log(response,'======================================='); return
        let updatedOptions = [
          {
            value: "Approved",
            label: "Approve",
          },
          {
            value: "Paid",
            label: "Paid",
          },
          {
            value: "On Hold",
            label: "On Hold",
          },
          {
            value: "Rejected",
            label: "Reject",
          }
        ];
        let hideEditPayment = false;
        if (response.paymentStatus === 'Submitted') {
          updatedOptions = [
            {
              value: "Approved",
              label: "Approve",
            },
            {
              value: "On Hold",
              label: "On Hold",
            },
            {
              value: "Rejected",
              label: "Reject",
            }
          ]
        }
        else if (response.paymentStatus === 'Approved') {
          updatedOptions = [
            {
              value: "Paid",
              label: "Paid",
            },
            {
              value: "On Hold",
              label: "On Hold",
            },
            {
              value: "Rejected",
              label: "Reject",
            }
          ];
        }
        else if (response.paymentStatus === 'On Hold') {
          updatedOptions = [
            {
              value: "Approved",
              label: "Approve",
            },
            {
              value: "Rejected",
              label: "Reject",
            }
          ];
        }
        else if (response.paymentStatus === 'Paid' || response.paymentStatus === 'Rejected') {
          updatedOptions = [{
            value: "Paid",
            label: "Paid",
          },
          {
            value: "Rejected",
            label: "Rejected",
          }
          ];
          hideEditPayment = true;
        }
        this.setState(() => ({
          message: response.message,
          detail: {
            amount: Number(response.amount).toFixed(2),
            paymentType:
              response.paymentMethod === "Card"
                ? "Joik Card"
                : "Bank Transfer",
            name: "",
            beneficiaryName:
              response.bankDetails != null
                ? response.bankDetails.beneficiaryName
                : "",
            bankName:
              response.bankDetails != null
                ? response.bankDetails.bankName
                : "",
            swiftCode:
              response.bankDetails != null
                ? response.bankDetails.swiftCode
                : "",
            iBanNumber:
              response.bankDetails != null
                ? response.bankDetails.ibanNumber
                : "",
            comments: response.paymentStatusComment != null
              ? response.paymentStatusComment
              : "",
            paymentStatus: response.paymentStatus,
            paymentUpdatedStatus: '',
            joikCardNumber: response.identificationNumber,
          },
          loading: false,
          options: updatedOptions,
          hideEditPayment,
          isSubmitDisable: false
        }));

      }
    );



  }


  state = {}
  render() {
    return (
      <>
        <Modal
          show={this.props.isEditModel}
          animation={false}
          className={"CustomModal width900"}
          onShow={this.loadDetails}
        >
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title">Payment status</h4>
              <button
                type="button"
                onClick={this.props.toggleModal}
                className="close"
              >
                &times;
              </button>
            </div>
            <Formik
              initialValues={this.state.detail}
              validate={this.validate}
              onSubmit={this.handleSubmit}
              enableReinitialize
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                setFieldValue,
                isSubmitting,
                /* and other goodies */
              }) => (
                <form
                  onSubmit={handleSubmit}
                  autoComplete="off"
                  className="float-left FormEditDesign"
                >
                  <div className="modal-body modalpadding">
                    <div className="row">
                      <div className="col-md-12">
                        {this.state.detail.paymentType !== "" ? (
                          <div className="row">
                            <div className="col-lg-12">
                              <h5 className="text-danger">{this.state.message}</h5>
                            </div>
                            <div className="col-lg-6 col-md-6">
                              <div className="form-group">
                                <Text
                                  title="Payment Method"
                                  name="paymentType"
                                  id="paymentType"
                                  errors={errors}
                                  touched={touched}
                                  values={values}
                                  handleChange={(e) => {
                                    const value = e.target.value;
                                    setFieldValue("paymentType", value);
                                    let detail = { ...this.state.detail };
                                    detail.paymentType = value;
                                    this.setState({ detail });
                                  }}
                                  handleBlur={handleBlur}
                                  disabled={true}
                                />
                              </div>
                            </div>

                            <div className="col-lg-6 col-md-12">
                              <div className="form-group">
                                <Text
                                  title="Amount"
                                  name="amount"
                                  placeholder=""
                                  errors={errors}
                                  touched={touched}
                                  values={values}
                                  handleChange={handleChange}
                                  handleBlur={handleBlur}
                                  disabled={true}
                                />
                              </div>
                            </div>
                          </div>
                        ) : null}
                        {this.state.detail.paymentType === "Joik Card" ? (
                          <Fragment>
                            <div className="row">
                              <div className="col-lg-6 col-md-6">
                                <div className="form-group">
                                  <Text
                                    title="Payment Status"
                                    name="currentPaymentStatus"
                                    placeholder={this.state.detail.paymentStatus}
                                    errors={errors}
                                    touched={touched}
                                    values={values}
                                    handleChange={handleChange}
                                    handleBlur={handleBlur}
                                    disabled={true}
                                  />
                                </div>
                              </div>
                              <div className="col-lg-6 col-md-12">
                                <div className="form-group">
                                  <Text
                                    title="9 Digit Joik Card Number"
                                    name="joikCardNumber"
                                    placeholder=""
                                    errors={errors}
                                    touched={touched}
                                    values={values}
                                    handleChange={handleChange}
                                    handleBlur={handleBlur}
                                    disabled={true}
                                  />
                                </div>
                              </div>

                            </div>
                          </Fragment>
                        ) : null}

                        {(this.state.detail.paymentType !== "Joik Card" && this.state.detail.paymentType !== "") ? (
                          <Fragment>
                            <div className="row">
                              <div className="col-lg-6 col-md-12">
                                <div className="form-group">
                                  <Text
                                    title="Beneficiary Name"
                                    name="beneficiaryName"
                                    placeholder=""
                                    errors={errors}
                                    touched={touched}
                                    values={values}
                                    handleChange={handleChange}
                                    handleBlur={handleBlur}
                                    disabled={true}
                                  />
                                </div>
                              </div>

                              <div className="col-lg-6 col-md-12">
                                <div className="form-group">
                                  <Text
                                    title="Bank Name"
                                    name="bankName"
                                    placeholder=""
                                    errors={errors}
                                    touched={touched}
                                    values={values}
                                    handleChange={handleChange}
                                    handleBlur={handleBlur}
                                    disabled={true}
                                  />
                                </div>
                              </div>
                            </div>

                            <div className="row">
                              <div className="col-lg-6 col-md-12">
                                <div className="form-group">
                                  <Text
                                    title="Business Identifier Code(BIC)/Swift Code"
                                    name="swiftCode"
                                    placeholder=""
                                    errors={errors}
                                    touched={touched}
                                    values={values}
                                    handleChange={handleChange}
                                    handleBlur={handleBlur}
                                    disabled={true}
                                  />
                                </div>
                              </div>

                              <div className="col-lg-6 col-md-12">
                                <div className="form-group">
                                  <Text
                                    title="Bank Account Number or IBAN"
                                    name="iBanNumber"
                                    placeholder=""
                                    errors={errors}
                                    touched={touched}
                                    values={values}
                                    handleChange={handleChange}
                                    handleBlur={handleBlur}
                                    disabled={true}
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-lg-12 col-md-12">
                                <div className="form-group">
                                  <Text
                                    title="Payment Status"
                                    name="currentPaymentStatus"
                                    placeholder={this.state.detail.paymentStatus}
                                    errors={errors}
                                    touched={touched}
                                    values={values}
                                    handleChange={handleChange}
                                    handleBlur={handleBlur}
                                    disabled={true}
                                  />
                                </div>
                              </div>
                            </div>
                          </Fragment>
                        ) : null}
                        <div className="row">
                          <div className="col-lg-6 col-md-12">
                            <div className={this.state.hideEditPayment ? "form-group" : "form-group required"}>
                              <Select
                                title="Action"
                                name="paymentUpdatedStatus"
                                options={this.state.options}
                                id="paymentUpdatedStatus"
                                errors={errors}
                                touched={touched}
                                values={values}
                                placeholder="Select an action"
                                handleChange={(e) => {
                                  const value = e.target.value;
                                  setFieldValue("paymentUpdatedStatus", value);
                                  let detail = { ...this.state.detail };
                                  detail.paymentUpdatedStatus = value;
                                  this.setState({ detail });
                                }}
                                handleBlur={handleBlur}
                                disabled={this.state.hideEditPayment}
                              />
                            </div>
                          </div>
                          {/* {this.state.detail.paymentStatus !== "Paid" ? ( */}
                          <div className="col-lg-6 col-md-12">
                            <div className={(this.state.detail.paymentUpdatedStatus === "On Hold" || this.state.detail.paymentUpdatedStatus === "Rejected") ? "form-group required" : "form-group"}>
                              <Text
                                title="Comment"
                                name="comments"
                                placeholder=""
                                errors={errors}
                                touched={touched}
                                values={values}
                                handleChange={handleChange}
                                handleBlur={handleBlur}
                                disabled={this.state.hideEditPayment}
                              />
                            </div>
                          </div>
                          {/* // ) : null} */}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="modal-footer text-center">
                    <button
                      type="button"
                      className="btn btn-default DefaultButton"
                      onClick={this.props.toggleModal}
                    >
                      Cancel
                    </button>
                    <button
                      type="submit"
                      className="btn btn-default DefaultButton AquaButton"
                      disabled={this.state.hideEditPayment || this.state.isSubmitDisable}
                    >
                      Save
                    </button>
                  </div>
                </form>
              )}
            </Formik>
          </div>

          <Loader loading={this.state.loading} />
        </Modal>
      </>
    );
  }
}

const mapStateToProps = state => ({
});

export default compose(
  connect(mapStateToProps, actions)
)(Edit);