/*!

=========================================================
* TextField
=========================================================
*/
import React from "react";
// reactstrap components
import {
  FormGroup,
  Input,
} from "reactstrap";
import Switch from '@mui/material/Switch';
import { alpha, styled } from '@mui/material/styles';
import { pink } from '@mui/material/colors';

const GreenSwitch = styled(Switch)(({ theme }) => ({
  '& .MuiSwitch-switchBase.Mui-checked': {
    color: "#1ca79e",
    '&:hover': {
      backgroundColor: alpha("#1ca79e", theme.palette.action.hoverOpacity),
    },
  },
  '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
    backgroundColor: '#1ca79e',
  },
}));

class SwitchButton extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { title, id, name, checked, isDisabled, handleChange } = this.props;
    return (
      <>
        <label
          className="form-control-label"
          htmlFor={id}
        >
          {title}
        </label>
        <br />
        <GreenSwitch
          checked={checked}
          onChange={handleChange}
          disabled={isDisabled ? isDisabled : false}
        />
      </>
    );
  }
}

export default SwitchButton;
