import { countries } from 'country-data';
import React, { Component } from 'react';
import { Form } from 'react-bootstrap';
import ReactPaginate from 'react-paginate';
import { connect } from 'react-redux';
import SearchField from "react-search-field";
import { compose } from 'redux';
import * as actions from '../../actions';
import Loader from '../../components/Loader';
import { BASE_URL } from '../../utils/constants';
import AddSite from '../Studies/AddSite';
const ITEM = 'Site';

class SiteList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      account: { contactInfo: {}, address: {} },
      site: {},
      showDetails: false,
      totalRecords: 0,
      itemPerPage: 10,
      curentPage: 1,
      pageCount: 1,
      searchValue: "",
    };
  }

  handlePageClick = (val) => {
    // console.log("page clicked",val);
    let pagNum = val.selected + 1;
    this.getDetails(this.state.searchValue, pagNum);
  };

  componentDidMount = () => {
    this.getDetails(this.state.searchValue, 1);
  };

  setPageSize = (num) => {
    if (num !== "Item per page") {
      this.setState(
        {
          itemPerPage: num,
        },
        () => {
          this.getDetails(this.state.searchValue, 1);
        }
      );
    }
  };

  getDetails = (sarchStr, pageNum) => {
    this.setState(() => ({
      loading: true,
    }));

    this.props.get(
      {
        url: `site/study/${this.props.studyId}?pageNum=${pageNum}&pageSize=${this.state.itemPerPage}&sortField=id&sortOrder=Desc&word=${sarchStr}`,
      },
      async (response) => {
        console.log(response.study);

        this.setState(() => ({
          responseData: response,
          accounts: response.entryList,
          responseFetched: true,
          loading: false,
          pageCount: response.totalPages,
        }));
      }
    );
  };

  toggleState = (key) => {
    this.setState((prevState, props) => ({
      [key]: !prevState[key],
    }));
  };

  reloadList = () => {
    this.getDetails(this.state.searchValue, 1);
    this.setState({
      modalIsOpen: false,
    });
  };

  onSearchClick = (value) => {
    this.setState({
      searchValue: value,
    });
    this.setState(
      {
        name: "value",
      },
      () => {
        this.getDetails(this.state.searchValue, this.state.curentPage);
      }
    );
  };

  downloadItem = (item, type) => {
    let filename =
      "Sites " +
      String(new Date().toISOString().slice(0, 10)) +
      " " +
      new Date().toLocaleTimeString("it-IT").slice(0, 10) +
      type;

    this.props.getFile(
      {
        url: item,
      },
      async (response) => {
        console.log(response, "this is from the api");

        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", filename);

        // Append to html link element page
        document.body.appendChild(link);

        // Start download
        link.click();

        // Clean up and remove the link
        link.parentNode.removeChild(link);
      }
    );
  };

  render() {
    let { accounts } = this.state;

    return (
      <div className="col-lg-12">
        <AddSite
          studyId={this.props.id}
          item={ITEM}
          setParentState={(newState) => this.setState(newState)}
          reloadList={this.reloadList}
          modalIsOpen={this.state.isAddModal}
          reloadcontact={(newState) => this.getListing("", 1)}
          toggleModal={() => this.toggleState("isAddModal")}
        ></AddSite>
        <div>
          <div className="TableData">
            <div class="TableTopText">
              <h3>View All Sites</h3>

              <div class="HedRgtData">
                <Form.Control
                  as="select"
                  className="form-control"
                  size="lg"
                  onChange={(e) => {
                    this.setPageSize(e.target.value);
                  }}
                >
                  <option>5</option>
                  <option selected>10</option>
                  <option>15</option>
                  <option>20</option>
                </Form.Control>

                <SearchField
                  placeholder=""
                  searchText=""
                  onChange={this.onSearchClick}
                  classNames="AllSearchTabledata form-control"
                />

                <div class="tooltipDiv">
                  <a
                    onClick={() =>
                      this.downloadItem(
                        `${BASE_URL}/site/study/${
                          String(window.location).split("Study-detail/")[1]
                        }/report/excel?&word=${this.state.searchValue}`,
                        ".xlsx"
                      )
                    }
                    className="DefaultButton ExcelButton"
                  ></a>
                  <p className="showTooltip">Download Excel Report</p>
                </div>
                <div class="tooltipDiv">
                  <a
                    onClick={() =>
                      this.downloadItem(
                        `${BASE_URL}/site/study/${
                          String(window.location).split("Study-detail/")[1]
                        }/report/csv?&word=${this.state.searchValue}`,
                        ".csv"
                      )
                    }
                    className="DefaultButton CsvButton"
                  ></a>
                  <p className="showTooltip">Download CSV Report</p>
                </div>

                <a
                  onClick={() => this.toggleState("isAddModal")}
                  class="DefaultButton AquaButton"
                  data-toggle="modal"
                  data-target="#AddSite"
                >
                  Add Site
                </a>
              </div>
            </div>
            <table className="table datatable-basic">
              <thead>
                <tr>
                  <th>Joik Account ID</th>
                  <th>Site Number</th>
                  <th>Principal Investigator</th>
                  <th>Account Name</th>
                  <th>Status</th>
                  <th>City</th>
                  <th>State</th>
                  <th>Country</th>
                </tr>
              </thead>
              <tbody>
                {accounts && accounts.length === 0 && (
                  <tr>
                    <td colSpan="8">
                      <center>
                        {!this.state.responseFetched
                          ? "Loading..."
                          : "No data found"}
                      </center>
                    </td>
                  </tr>
                )}

                {/* {console.log(accounts && accounts.length > 0, '======>accounts')} */}
                {accounts &&
                  accounts.length > 0 &&
                  accounts.map((account, accountIndex) => {
                    // console.log(account, '======+>account')
                    return (
                      <tr key={`account_${accountIndex}`}>
                        <td>
                          <a
                            onClick={() => this.props.loadSiteDeatils(account)}
                          >
                            {account.joikId}
                          </a>
                        </td>
                        <td>{account.siteId}</td>

                        <td>
                          {account.principalInvestigator != null
                            ? account.principalInvestigator.firstName +
                              " " +
                              account.principalInvestigator.lastName
                            : ""}
                        </td>

                        <td>{account.account.name}</td>

                        <td>{account.shippingContact.contactStatus}</td>
                        <td>{account.account.address.city}</td>
                        <td>{account.account.address.state}</td>
                        <td>
                          {countries[account.account.address.countryCode].name}
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>

            <div className="col-lg-12">
              {this.state.pageCount <= 1 ? null : (
                <ReactPaginate
                  previousLabel={"<"}
                  nextLabel={">"}
                  breakLabel={"..."}
                  breakClassName={"break-me"}
                  pageCount={this.state.pageCount}
                  marginPagesDisplayed={5}
                  pageRangeDisplayed={1}
                  onPageChange={this.handlePageClick}
                  containerClassName={"pagination"}
                  subContainerClassName={"pages pagination"}
                  activeClassName={"active"}
                />
              )}
            </div>
          </div>
        </div>
        <Loader loading={this.state.loading} />
      </div>
    );
  }
}
 
const mapStateToProps = state => ({
});

export default compose(
  connect(mapStateToProps, actions)
)(SiteList);