import React, { Component, Fragment } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import * as actions from '../../actions';
import DeleteModal from '../../components/DeleteModal';
import Loader from '../../components/Loader';
import { Link } from 'react-router-dom';// 
import {countries} from 'country-data';
import ContactEdit from '../Contact/ContactEdit'
import CountryOperation from '../../components/CountryOperation'
const MODULE_BASE_ROUTE = 'contact';
const DELETE_API = 'contact/deactivate';

const ITEM = 'Contact';

class ContactDetails extends Component {
    constructor(props) {
        super(props);
        const id = this.props.match.params.id;
        if (!id) {
          this.props.history.push(`/${MODULE_BASE_ROUTE}`)
        }
    
        this.state = {
          id,
          detail: {},
          responseFetched: false,
          loading: false,
          isEditModal: false,
        };
      }


     
      getDetail = () => {
        const { id } = this.state;
    
        this.setState(() => ({
          loading: true,
        }));
    
        this.props.get({ url: `contact/${id}`, errorUrl: `/${MODULE_BASE_ROUTE}`, history: this.props.history }, async (response) => {
          console.log(response, '======>response');
          this.setState(() => ({
            detail: response,
            responseFetched: true,
            loading: false,
          }))
        });
      }

      
    toggleState = (key) => {
      this.setState((prevState, props) => ({
        [key]: !prevState[key],
      }));
    }
  
      rerouteToList=()=>{
        this.props.history.push(`/contact`)
    
       }

      componentDidMount() {
      
        this.getDetail();
      }
      componentDidUpdate() {
        console.log(this.state, '================>state in component did update');
      }

      handleConfirmDelete = (e, id) => {
        e.preventDefault();
        this.setState((prevState, props) => ({
          deleteId: id,
          confirmDelete: true,
          isRowMenu: false,
        }));
       
      }

      render() {
        let {
          detail
        } = this.state;
        const { id } = this.state;
        
        return (
          <>
            {/* <!-- Content area --> */}
            <div className="content">
              {/* <!-- Dashboard content --> */}
              <div className="row PageHeading mb-3">
                <div className="col-lg-12">
                  <h3 className="mb-0">Contact Details</h3>
                </div>
              </div>

              <div className="row">
                <div className="col-lg-12">
                  {Object.keys(detail).length > 0 && (
                    <div className="RowDesign">
                      <div className="col-lg-12  float-left">
                        <ul className="ClientDetails">
                          <li>
                            <strong>Joik Contact ID</strong>
                            {detail.joikId}
                          </li>
                          <li>
                            <strong>Contact Type</strong>
                            {detail.contactType}
                          </li>
                          <li>
                            <strong>First Name</strong>
                            {detail.firstName}
                          </li>
                          <li>
                            <strong>Last Name</strong>
                            {detail.lastName}
                          </li>
                          <li>
                            <strong>Address Line 1</strong>
                            {detail.address.addressLine1}
                          </li>
                          <li>
                            <strong>Address Line 2</strong>
                            {detail.address.addressLine2}
                          </li>
                          <li>
                            <strong>City</strong>
                            {detail.address.city}
                          </li>
                          <li>
                            <strong>State</strong>
                            {detail.address.state}
                          </li>
                          <li>
                            <strong>Postcode</strong>
                            {detail.address.postcode}
                          </li>
                          <li>
                            <strong>Country</strong>
                            {countries[detail.address.countryCode].name}
                          </li>
                          <li>
                            <strong>Email ID</strong>
                            {detail.contactInfo.email}
                          </li>
                          <li>
                            <strong>Phone</strong>
                            {detail.contactInfo.mobileNumberCountryCode}{detail.contactInfo.mobileNumber}
                          </li>
                          <li>
                            <strong>Account Name</strong>
                            {detail.account.name}
                          </li>
                          <li>
                            <strong>Contact Status</strong>
                            {detail.contactStatus}
                          </li>
                          <li>
                            <strong>Contact Status Effective Date</strong>
                            {detail.statusEffectiveDate}
                          </li>
                        </ul>
                      </div>
                      <div className="col-lg-12  float-left mt-3 mb-3 AllFooterButton text-center d-flex justify-content-center">
                        <button
                          onClick={() => this.rerouteToList()}
                          className="DefaultButton"
                        >
                          Back
                        </button>
                        <button
                          onClick={() => this.toggleState("isEditModal")}
                          className="DefaultButton AquaButton"
                        >
                          Edit
                        </button>
                        <Link
                          onClick={(e) =>
                            this.handleConfirmDelete(e, detail.id)
                          }
                          className="DefaultButton"
                        >
                          Delete
                        </Link>
                      </div>
                    </div>
                  )}
                </div>
              </div>
              {/* <!-- /dashboard content --> */}

              <DeleteModal
                item={ITEM}
                modalIsOpen={this.state.confirmDelete}
                setParentState={(newState) => this.setState(newState)}
                deleteId={this.state.deleteId}
                rerouteToList={this.rerouteToList}
                api={this.props.deleteMethod}
                apiName={DELETE_API}
              />

              <ContactEdit
                id={id}
                item={ITEM}
                editDone={this.getDetail}
                editData={this.state.editData}
                setParentState={(newState) => this.setState(newState)}
                modalIsOpen={this.state.isEditModal}
                toggleModal={() => this.toggleState("isEditModal")}
              />
            </div>

            {/* <!-- /content area --> */}
            <Loader loading={this.state.loading} />
          </>
        );

    }  

}      


const mapStateToProps = state => ({
    // errorMessage: state.auth.errorMessage
  });





export default compose(
    connect(mapStateToProps, actions)
  )(ContactDetails);

